import React, { useEffect } from 'react'
import {
  Divider,
  FlexColumn,
  FlexRow,
  PrimaryButton,
  TextInput,
} from 'components/lib'
import { InputGroupLabel } from 'components/app/ContactForm/ProfileForm'
import { APIRule, Condition } from '@super-software-inc/foundation'
import { useTheme } from 'styled-components'
import ConditionFormSection from 'components/app/Rules/ConditionFormSection'
import ActionFormSection from 'components/app/Rules/ActionFormSection'

export const permanentCondition = ({
  conditional, // e.g. "if"
  description, // e.g. "property is recognized"
}: {
  conditional: string
  description: string
}) => (
  <div className="mb-2 w-[452px] h-16 p-3 bg-white rounded-lg shadow border border-zinc-200 flex-col justify-center items-start gap-3 inline-flex">
    <div className="self-stretch justify-start items-center gap-2 inline-flex">
      <div className="w-10 h-10 p-3 bg-slate-50 rounded border border-violet-600 justify-center items-center flex">
        <div className="text-center text-violet-600 text-[9px] font-semibold font-['Inter'] uppercase leading-3 tracking-wide">
          {conditional}
        </div>
      </div>
      <div className="grow shrink basis-0 text-sky-950 text-sm font-normal font-['Inter'] leading-tight">
        {description}
      </div>
    </div>
  </div>
)

const RuleForm = ({
  rule,
  setRule,
  closeForm,
  updateOrCreateRule,
  formIsSubmitting,
}: {
  rule: APIRule
  setRule: Function
  closeForm: Function
  updateOrCreateRule: Function
  formIsSubmitting: boolean
}) => {
  const theme = useTheme()

  useEffect(() => {
    document.getElementById('topOfForm')?.scrollIntoView()
  }, [])

  const resetEditingRule = () => {
    setRule({
      id: '',
      title: '',
    })
  }

  return (
    <span>
      <div
        id="topOfForm"
        style={{
          position: 'absolute',
          top: 0,
        }}
      />
      <FlexColumn style={{ padding: 20 }} justify="space-between">
        <>
          <h2 style={{ marginBottom: 24 }}>
            {rule.id && rule.id.length > 0 ? 'Edit' : 'Add'} rule
          </h2>
          <InputGroupLabel>Rule title</InputGroupLabel>
          <TextInput
            disabled={rule.locked}
            placeholder="Enter rule title"
            style={{ marginBottom: 20 }}
            value={rule.title}
            onChange={e => {
              setRule({
                ...rule,
                title: e.target.value,
              })
            }}
          />
          <div
            style={{
              background: theme.colors.bg200,
              padding: 24,
              width: '100%',
            }}
          >
            <div style={{ marginBottom: 32 }}>
              <InputGroupLabel>Start when...</InputGroupLabel>
              {permanentCondition({
                conditional: 'when',
                description: 'New task is created from inbound communication', // TODO - this will eventually change, based off inputs.process
              })}
            </div>
            <ConditionFormSection
              rule={rule}
              updateRuleConditions={(conditions: Condition[]) =>
                setRule({
                  ...rule,
                  conditions,
                })
              }
            />
            <ActionFormSection rule={rule} setRule={setRule} />
          </div>
        </>
        <>
          <Divider style={{ marginBottom: 10, marginTop: 10, width: '100%' }} />
          <FlexRow justify="flex-end" style={{ width: '100%' }}>
            <PrimaryButton
              style={{ marginRight: 10 }}
              onClick={() => {
                resetEditingRule()
                closeForm()
              }}
              light
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              disabled={formIsSubmitting}
              onClick={() => {
                updateOrCreateRule()
              }}
            >
              Save
            </PrimaryButton>
          </FlexRow>
        </>
      </FlexColumn>
    </span>
  )
}

export default RuleForm

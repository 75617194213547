import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Contact } from '@super-software-inc/foundation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { TextUnderline } from 'components/lib'
import { authenticatedUserAtom, tempFilesAtom } from 'state/atoms'
import { toastError } from 'components/lib/Toast'
import { history } from 'prosemirror-history'
import { keymap } from 'prosemirror-keymap'
import { Node, Slice, Fragment } from 'prosemirror-model'
import { baseKeymap } from 'prosemirror-commands'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { EditorState } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import { inputRules } from 'prosemirror-inputrules'
import { mentionsPlugin } from './mentions'

import { buildKeymap, buildKeymapCheckbox } from './keymap'
import schema from './schema'
import parser from './parser'
import serializer from './serializer'
import StyledEditor from './Editor.styles'
import paste from './paste'
import click from './click'
import { buildInputRules } from './inputRules'
import { EditorRef } from './types'
import { SelectionToolbar } from './SelectionToolbar/SelectionToolbar'

export interface TemporaryFile {
  tempURL: string
  file: File
}

interface EditorProps {
  placeholder?: string
  value?: string
  isReadonly?: boolean
  onChange?(val: string): void
  updateEditorFocus?(val: boolean): void
  style?: React.CSSProperties
  contacts?: Contact[]
  associationId?: string
  disableMentions?: boolean
  disableFiles?: boolean
  condenseContent?: number
  referenceId?: string
  isTaskForm?: boolean
}

const Editor = forwardRef<EditorRef, EditorProps>(
  (
    {
      value,
      placeholder,
      isReadonly,
      onChange,
      updateEditorFocus,
      style,
      contacts,
      associationId,
      disableMentions,
      disableFiles,
      condenseContent,
      referenceId,
      isTaskForm,
    },
    ref,
  ) => {
    const viewRef = useRef<EditorView>()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editorRef = useRef<HTMLDivElement>(null!)
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)
    const [contentIsCondensed, setContentIsCondensed] = useState<boolean>(false)

    const [, setTempFiles] = useRecoilState(tempFilesAtom)

    useEffect(() => {
      if (condenseContent) {
        setContentIsCondensed(false)

        setTimeout(() => {
          if (
            editorRef.current.clientHeight &&
            editorRef.current.clientHeight > condenseContent
          ) {
            setContentIsCondensed(true)
          }
        }, 100)
      }
      return () => {
        setContentIsCondensed(false)
      }
    }, [referenceId, condenseContent])

    // Needed to reliably access this placholder state in the callback below.
    const currentPlaceholder = useRef(placeholder)
    useEffect(() => {
      currentPlaceholder.current = placeholder

      // update placeholder when it changes after the editor has been initialized
      if (viewRef.current && viewRef.current.state.tr) {
        viewRef.current.dispatch(
          viewRef.current.state.tr.setMeta('placeholder', placeholder),
        )
      }
    }, [placeholder])

    useEffect(() => {
      // prevents drag and drop images from opening in a new tab.
      if (editorRef && editorRef.current) {
        editorRef.current.addEventListener('dragover', e => {
          e.preventDefault()
        })

        editorRef.current.addEventListener('drop', e => {
          e.preventDefault()
        })
      }
    })

    const [, forceUpdate] = useState({})

    const isFirstRender = useRef(true)

    const createState = useCallback(
      newValue => {
        const plugins = [
          inputRules({ rules: buildInputRules(schema) }),
          history(),
          keymap(buildKeymap(schema)),
          keymap(buildKeymapCheckbox(schema)),
          keymap(baseKeymap),
          paste(schema),
          click(schema),
          dropCursor(),
          gapCursor(),
        ]

        if (!disableMentions) {
          plugins.unshift(
            mentionsPlugin({
              currentUserGroups:
                authenticatedUser.selectedContact.propertyInfo.find(
                  p => p.associationId === associationId,
                )?.groups || [],
            }),
          )
        }

        const currentText = isTaskForm
          ? viewRef.current?.state.doc?.textContent
          : ''

        return EditorState.create({
          doc: parser.parse(newValue || currentText || '') || undefined,
          schema,
          plugins,
        })
      },
      [authenticatedUser, disableMentions, associationId, isTaskForm],
    )

    const getValue = useCallback(
      () =>
        viewRef.current?.state.doc
          ? serializer.serialize(viewRef.current.state.doc)
          : '',
      [],
    )

    const handleFiles = (files: File[], event?: DragEvent | ClipboardEvent) => {
      if (disableFiles) {
        toastError('File uploads are not supported in this area.')
        return
      }

      if (!viewRef.current) {
        return
      }
      // store files before editor text is saved.
      const nodes: Node[] = []
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        const tempURL = URL.createObjectURL(file)
        // save the file for uploading later,
        // the tempUrl is the placeholder in the description
        // TODO: now there is always a setTempFiles function, so this check is
        // unnecessary. is this a problem?
        if (setTempFiles) {
          setTempFiles(prev => [...prev, { tempURL, file }])
        }

        if (viewRef.current) {
          if (
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
          ) {
            nodes.push(
              viewRef.current.state.schema.node('image', {
                src: tempURL,
              }),
            )
          } else {
            const link = viewRef.current.state.schema.marks.link.create({
              href: tempURL,
            })
            const fileLinkNode = viewRef.current.state.schema
              .text(files[i].name)
              .mark([link])
            nodes.push(fileLinkNode)
          }

          if (event && event instanceof DragEvent) {
            const insertPos = viewRef.current?.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            })
            viewRef.current?.dispatch(
              viewRef.current?.state.tr.insert(insertPos?.pos || 0, nodes),
            )
          } else {
            viewRef.current?.dispatch(
              viewRef.current?.state.tr.replaceSelection(
                new Slice(Fragment.from(nodes), 0, 0),
              ),
            )
          }
        }
      }
    }

    useEffect(() => {
      const view = new EditorView(editorRef.current, {
        state: createState(value),
        editable: () => !isReadonly,
        decorations: state => {
          const { doc } = state

          if (
            doc.childCount === 1 &&
            doc?.firstChild?.isTextblock &&
            doc?.firstChild.content.size === 0
          ) {
            const placeHolderWidget = document.createElement('span')
            placeHolderWidget.classList.add('placeholder')
            placeHolderWidget.textContent = currentPlaceholder.current || ''

            return DecorationSet.create(doc, [
              Decoration.widget(1, placeHolderWidget),
            ])
          }

          return null
        },
        dispatchTransaction: transaction => {
          if (viewRef.current) {
            const { state, transactions } =
              viewRef.current.state.applyTransaction(transaction)

            viewRef.current?.updateState(state)

            if (transactions.some(tr => tr.docChanged) && onChange) {
              onChange(getValue())
            }
          }

          forceUpdate({})
        },
        handleDOMEvents: {
          drop(editorView, event) {
            if (
              // !associationId ||
              !event.dataTransfer?.files ||
              !event.dataTransfer?.files.length
            ) {
              return false
            }

            // TODO: Handle errors based on file types, size, etc.
            const numFiles = event.dataTransfer.files.length
            const filesArray: File[] = []
            for (let i = 0; i < numFiles; i += 1) {
              const file = event.dataTransfer.files[i]
              filesArray.push(file)
            }

            handleFiles(filesArray, event)

            return true
          },
          paste(editorView, event) {
            if (
              // !associationId ||
              !event.clipboardData?.files ||
              !event.clipboardData?.files.length
            ) {
              return false
            }
            event.preventDefault()
            // TODO: Handle errors based on file types, size, etc.
            const numFiles = event.clipboardData.files.length
            const filesArray: File[] = []
            for (let i = 0; i < numFiles; i += 1) {
              const file = event.clipboardData.files[i]
              filesArray.push(file)
            }

            handleFiles(filesArray, event)

            return true
          },
        },
      })

      viewRef.current = view
      // Ensure that whenever a new state is created, the mentions plugin is updated with the latest contacts
      // TODO - this is probably why @renters mention isn't working in cross-hoa view
      if (associationId && contacts) {
        viewRef.current.dispatch(
          viewRef.current.state.tr.setMeta('mentions', {
            associationId,
            contacts,
          }),
        )
      }

      const handleEditorClick = ({ target }: MouseEvent) => {
        if (view.hasFocus() && updateEditorFocus) {
          updateEditorFocus(true)
        }

        if (target && target instanceof HTMLInputElement) {
          if (target.classList.contains('editor-checkbox')) {
            const { tr } = view.state
            const { top, left } = target.getBoundingClientRect()

            const result = view.posAtCoords({ top, left })

            if (result) {
              const transaction = tr.setNodeMarkup(result.inside, undefined, {
                checked: target.checked,
              })

              view.dispatch(transaction)

              if (!view.hasFocus()) {
                view.focus()
              }
            }
          }
        }
      }

      document.body.addEventListener('click', handleEditorClick, true)

      return () => {
        document.body.removeEventListener('click', handleEditorClick, true)
        view.destroy()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false
        return
      }

      viewRef.current?.update({
        ...viewRef.current?.props,
        editable: () => !isReadonly,
      })
    }, [isReadonly])

    useEffect(() => {
      viewRef.current?.updateState(createState(value || ''))
      // Ensure that whenever a new state is created, the mentions plugin is updated with the latest contacts
      if (associationId && contacts && viewRef.current) {
        viewRef.current.dispatch(
          viewRef.current.state.tr.setMeta('mentions', {
            associationId,
            contacts,
          }),
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, createState])

    useImperativeHandle(ref, () => ({
      get view() {
        return viewRef.current
      },

      get value() {
        return getValue()
      },

      set value(newValue: string) {
        viewRef.current?.updateState(createState(newValue))
        // Ensure that whenever a new state is created, the mentions plugin is updated with the latest contacts
        if (associationId && contacts && viewRef.current) {
          viewRef.current.dispatch(
            viewRef.current.state.tr.setMeta('mentions', {
              associationId,
              contacts,
            }),
          )
        }
      },

      handleFiles: (files: File[]) => {
        handleFiles(files)
      },
    }))

    // When the associationId or list of contacts changes, provide this as metadata for the mentions plugin.
    useEffect(() => {
      if (viewRef.current) {
        viewRef.current.dispatch(
          viewRef.current.state.tr.setMeta('mentions', {
            associationId,
            contacts,
          }),
        )
      }
    }, [associationId, contacts])

    return (
      <>
        {!isReadonly && <SelectionToolbar view={viewRef.current} />}

        <StyledEditor
          ref={editorRef}
          style={{
            ...style,
            ...(contentIsCondensed && {
              maxHeight: condenseContent ? condenseContent - 20 : 'unset',
              maskImage: 'linear-gradient(180deg, #000 60%, transparent)',
            }),
          }}
        />
        {contentIsCondensed && (
          <TextUnderline
            onClick={e => {
              e.preventDefault()
              setContentIsCondensed(!contentIsCondensed)
            }}
            style={{
              marginBottom: 20,
              textDecorationColor: '#C9CED6',
            }}
          >
            See more
          </TextUnderline>
        )}
      </>
    )
  },
)

export default Editor

import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'

// eslint-disable-next-line import/prefer-default-export
export const getContacts = async (
  companyId: string,
  associationIds: string[],
  groups?: ContactGroup[],
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getAssociationContacts: HttpsCallable<
    { companyId: string; associationIds: string[]; groups?: ContactGroup[] },
    APIContact[]
  > = httpsCallable(functions, 'getContacts')

  const { data } = await getAssociationContacts({
    companyId,
    associationIds,
    groups,
  })

  return data
}

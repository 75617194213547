import {
  AccessLevel,
  APIAssociation,
  APICompany,
  APIContact,
  APIDelivery,
  APIDeliveryDestination,
  APIDeliveryStatus,
  APIDeliveryType,
  APIHeartbeat,
  APIRule,
  APIUnit,
  Delivery,
  NoAccessACL,
  PropertyInfo,
  SearchableTask,
} from '@super-software-inc/foundation'
import { CompanyType } from '@super-software-inc/foundation/dist/types/Company'
import { TemporaryFile } from 'components/app/Editor/Editor'
import { TaskFormTask } from 'components/app/TaskForm'
import { FilterInterface } from 'components/app/TaskViewOptions'
import { atom } from 'recoil'
import { MobileOperatingSystem } from 'utils/getMobileOperatingSystem'

export const heartbeatAtom = atom<APIHeartbeat>({
  key: 'heartbeat',
  default: {
    latestSystemMessage: undefined,
    secrets: [],
  },
})

/**
 * This atom is used to cache the download URL of the user's avatar.
 */
// eslint-disable-next-line import/prefer-default-export
export const avatarDownloadURLCacheAtom = atom<{ [key: string]: string }>({
  key: 'avatarDownloadURLCache',
  default: {},
})

export const tempFilesAtom = atom<TemporaryFile[]>({
  key: 'tempFiles',
  default: [],
})

export const taskInitialValuesAtom = atom<Partial<TaskFormTask> | undefined>({
  key: 'taskInitialValues',
  default: undefined,
})

export const taskFilterAtom = atom<FilterInterface>({
  key: 'taskFilter',
  default: {
    type: undefined,
    value: undefined,
  },
})

export const showTaskFormAtom = atom<boolean>({
  key: 'showTaskForm',
  default: false,
})

export const showAnnouncementFormAtom = atom<boolean>({
  key: 'showAnnouncementForm',
  default: false,
})

export const newAnnouncementDescriptionAtom = atom<string>({
  key: 'newAnnouncementDescription',
  default: '',
})

export enum DeliveryFormStage {
  SelectAction = 'select-action',
  MobileCamera = 'mobile-camera',
  Camera = 'camera',
  ContactSelection = 'contact-selection',
  ManualEntry = 'manual-entry',
}

export const deliveryFormAtom = atom<{
  isOpen: boolean
  deliveryFormStage: DeliveryFormStage
  delivery: Partial<Delivery>
  labelDestinationOptions: APIDeliveryDestination[]
}>({
  key: 'deliveryForm',
  default: {
    isOpen: false,
    deliveryFormStage: DeliveryFormStage.SelectAction,
    delivery: {},
    labelDestinationOptions: [],
  },
})

export const deliverySheetAtom = atom<APIDelivery | undefined>({
  key: 'deliverySheet',
  default: undefined,
})

export const deliveriesAtom = atom<APIDelivery[]>({
  key: 'deliveries',
  default: [],
})

export const companyDeliveryTypesAtom = atom<APIDeliveryType[]>({
  key: 'companyDeliveryTypes',
  default: [],
})

export const companyDeliveryStatusesAtom = atom<APIDeliveryStatus[]>({
  key: 'companyDeliveryStatuses',
  default: [],
})

export const showMeetingFormAtom = atom<boolean>({
  key: 'showMeetingForm',
  default: false,
})

export const communicationTabAtom = atom<number>({
  key: 'communicationTab',
  default: 0,
})

export const getDefaultPropertyInfo = (): PropertyInfo => ({
  associationId: '',
  units: [],
  accessLevel: AccessLevel.NoAccess,
  acl: NoAccessACL,
  customAccess: false,
  propertyRelation: null,
  boardTerms: [],
  groups: [],
  title: '',
})

export const getDefaultAPIContact = (): APIContact => ({
  id: '',
  userId: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: {
    type: 'mobile',
    number: '',
  },
  associationIds: [],
  secondaryEmails: [],
  secondaryPhones: [],
  companyId: '',
  notificationSettings: {
    frequency: 'activity',
    monthlySummary: true,
    accountUpdates: true,
    marketingUpdates: true,
  },
  propertyInfo: [],
})

export const authenticatedUserAtom = atom<{
  contacts: APIContact[]
  companies: APICompany[]
  selectedContact: APIContact
  selectedCompany: APICompany
}>({
  key: 'authenticatedUser',
  default: {
    companies: [], // all companies that have a contact with the userId
    contacts: [], // all contacts that have a contact with the userId
    selectedCompany: {
      id: '',
      name: '',
      associationIds: [],
      active: true,
      pilot: false,
      primaryContact: null,
      type: CompanyType.PropertyManager,
      createdAt: new Date().getTime(),
      modifiedBy: null,
      shortCode: '',
    },
    selectedContact: getDefaultAPIContact(),
  },
})

export const companyAssociationsAtom = atom<{
  associations: APIAssociation[]
  companyId: string
}>({
  key: 'companyAssociations',
  default: {
    associations: [],
    companyId: '',
  },
})

export const profileModalAtom = atom<{
  sidebarIsOpen: boolean
  editProfileIsOpen: boolean
  selectedContact: APIContact
  abandonProfileFormIsOpen: boolean
  mergeContactIsOpen: boolean
  corpFirst: boolean
}>({
  key: 'profileModal',
  default: {
    sidebarIsOpen: false,
    editProfileIsOpen: false,
    selectedContact: getDefaultAPIContact(),
    abandonProfileFormIsOpen: false,
    mergeContactIsOpen: false,
    corpFirst: false, // true = the sidebar should show all units/board terms // false =  just the selected property.
  },
})

export const currentContactTabAtom = atom<number>({
  key: 'currentContactTab',
  default: 0,
})

export const currentAssociationTabAtom = atom<number>({
  key: 'currentAssociationTab',
  default: 0,
})

interface UnitActionsAtomType {
  view: {
    isOpen: boolean
    selectedUnit: APIUnit | undefined
  }
  edit: {
    isOpen: boolean
    selectedUnit: APIUnit | undefined
  }
  delete: {
    isOpen: boolean
    selectedUnit: APIUnit | undefined
  }
}

export const unitActionsAtom = atom<UnitActionsAtomType>({
  key: 'unitActionsAtom',
  default: {
    view: {
      isOpen: false,
      selectedUnit: undefined,
    },
    edit: {
      isOpen: false,
      selectedUnit: undefined,
    },
    delete: {
      isOpen: false,
      selectedUnit: undefined,
    },
  },
})

// string is the associationId
export const companyUnitsAtom = atom<Map<string, APIUnit[]>>({
  key: 'companyUnits',
  default: new Map<string, APIUnit[]>(),
})

export const editingRuleAtom = atom<APIRule>({
  key: 'editingRule',
  default: {} as APIRule,
})

interface RoutingAtomType {
  settingsTab: number
  companySettingsTab: number
}
export const routingStateAtom = atom<RoutingAtomType>({
  key: 'routingState',
  default: {
    settingsTab: 0,
    companySettingsTab: 0,
  },
})

export const mobileOperatingSystemAtom = atom<MobileOperatingSystem>({
  key: 'mobileOperatingSystem',
  default: MobileOperatingSystem.Unknown,
})

export const searchableTasksAtom = atom<SearchableTask[]>({
  key: 'searchableTasks',
  default: [],
})

import NiceModal from '@ebay/nice-modal-react'
import {
  APIAssociation,
  APICompany,
  APIContact,
  StripeConnectStatus,
} from '@super-software-inc/foundation'
import { getContacts } from 'api/contacts/getContacts'
import {
  FlexRow,
  MultilevelDropdown,
  MultilevelDropdownPositions,
  MultilevelItem,
  PrimaryButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TruncatedText,
} from 'components/lib'
import Tabs from 'components/lib/Navigation/Tabs'
import { toastError } from 'components/lib/Toast'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'
import { kebabCase, orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import copyToClipboard from 'utils/copyToClipboard'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import AdminFormModal from './AdminFormModal'
import CSVUploadModal from './CSVUploadModal'

const AdminManagement = () => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getCompanies: HttpsCallable<void, APICompany[]> = httpsCallable(
    functions,
    'getCompanies',
  )
  const getAssociations: HttpsCallable<void, APIAssociation[]> = httpsCallable(
    functions,
    'getAssociations',
  )

  const [companies, setCompanies] = useState<APICompany[]>([])
  const [associations, setAssociations] = useState<APIAssociation[]>([])
  const [contacts, setContacts] = useState<APIContact[]>([])

  const [selectedCompany, setSelectedCompany] = useState<Partial<APICompany>>(
    {},
  )
  const [selectedAssociation, setSelectedAssociation] = useState<
    Partial<APIAssociation>
  >({})

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  useEffect(() => {
    getCompanies()
      .then(result => {
        const { data } = result
        setCompanies(data)
      })
      .catch(err => {
        setCompanies([])

        // TODO: make a wrapper function for this; it's being
        // used in a bunch of places now
        if (err?.message) {
          toastError(
            <div>
              <div style={{ fontWeight: 'bold' }}>{err.message}</div>
            </div>,
          )
        } else {
          toastError(
            'An error occurred. Please check your inputs and try again.',
          )
        }
      })

    getAssociations()
      .then(result => {
        const { data } = result
        setAssociations(data)
      })
      .catch(err => {
        setAssociations([])

        // TODO: make a wrapper function for this; it's being
        // used in a bunch of places now
        if (err?.message) {
          toastError(
            <div>
              <div style={{ fontWeight: 'bold' }}>{err.message}</div>
            </div>,
          )
        } else {
          toastError(
            'An error occurred. Please check your inputs and try again.',
          )
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const companiesWithAssociationNames = useMemo(
  //   () =>
  //     companies.map(company => ({
  //       ...company,
  //       associationNames: company.associationIds.map(associationId => {
  //         const association = associations.find(a => a.id === associationId)
  //         return association ? association.name : 'Unknown'
  //       }),
  //     })),
  //   [companies, associations],
  // )

  const associationsWithCompanyNames = useMemo(
    () =>
      associations.map(association => {
        const company = companies.find(c =>
          c.associationIds.includes(association.id),
        )

        return {
          ...association,
          companyId: company?.id || null,
          companyName: company?.name || '--',
        }
      }),
    [companies, associations],
  )

  const handleCompanySort = (key: string) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')

    switch (key) {
      case 'associations':
        setCompanies(
          orderBy(companies, c => c.associationIds.length, [sortOrder]),
        )
        break
      default:
        setCompanies(orderBy(companies, key, [sortOrder]))
    }
  }

  const handlePropertySort = (key: string) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')

    switch (key) {
      default:
        setAssociations(orderBy(associationsWithCompanyNames, key, [sortOrder]))
    }
  }

  const contactsConstraintName = useMemo(
    () => selectedCompany?.name || selectedAssociation?.name,
    [selectedCompany, selectedAssociation],
  )

  const [currentTab, setTab] = useState(0)

  const tabs = [
    {
      id: 'companies',
      name: 'Companies',
    },
    {
      id: 'properties',
      name: 'Properties',
    },
  ]

  const applyCurrentTab = (index: number) => {
    setTab(index)
    setContacts([])
    setSelectedCompany({})
    setSelectedAssociation({})
  }

  return (
    <div style={{ padding: 20 }}>
      <div>
        <h2>Company and association management</h2>
      </div>

      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={applyCurrentTab}
        customStyle="top-14"
      />

      {tabs[currentTab]?.id === 'companies' && (
        <div style={{ margin: '40px 0' }}>
          <div style={{ marginBottom: 20 }}>
            <PrimaryButton
              onClick={async () => {
                try {
                  const company: APICompany = await NiceModal.show(
                    AdminFormModal,
                    {
                      currentTab,
                      company: {},
                      association: selectedAssociation,
                      assigneeOptions: contacts,
                    },
                  )

                  setCompanies(orderBy([...companies, company], 'name'))
                } catch (e) {
                  // console.error('Modal was cancelled', e)
                }
              }}
            >
              Add Company
            </PrimaryButton>
          </div>
          <h3>Companies</h3>
          <div style={{ maxHeight: 575, overflowY: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* TODO: use SortableTableHeaderCell */}
                  <TableHeaderCell
                    style={{ textAlign: 'left', width: 250 }}
                    onClick={() => handleCompanySort('name')}
                  >
                    Company
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('slug')}
                  >
                    Slug
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('phone')}
                  >
                    Phone
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('associations')}
                  >
                    Associations
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('corpFirst')}
                  >
                    Corp first?
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() =>
                      handleCompanySort('disableEmailNotifications')
                    }
                  >
                    Notifs disabled?
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('createdAt')}
                  >
                    Created
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handleCompanySort('updatedAt')}
                  >
                    Updated
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company, idx) => (
                  <TableRow
                    key={company.id}
                    isSelected={company.id === selectedCompany?.id}
                    onClick={async () => {
                      setSelectedCompany(company)

                      if (selectedCompany?.id !== company.id) {
                        try {
                          const data = await getContacts(company.id, [])

                          setContacts(orderBy(data, ['firstName', 'lastName']))
                        } catch (err) {
                          setContacts([])

                          // TODO: make a wrapper function for this; it's being
                          // used in a bunch of places now
                          // @ts-expect-error - HttpsErrors contain a message property
                          if (err?.message) {
                            toastError(
                              <div>
                                <div style={{ fontWeight: 'bold' }}>
                                  {/* @ts-expect-error - HttpsErrors contains a message property */}
                                  {err.message}
                                </div>
                              </div>,
                            )
                          } else {
                            toastError(
                              'An error occurred. Please check your inputs and try again.',
                            )
                          }
                        }
                      }
                    }}
                  >
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{company.name}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{company.slug}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      {company.phone ? formatPhoneNumber(company.phone) : '--'}
                    </TableCell>
                    <TableCell>{company.associationIds.length}</TableCell>
                    <TableCell>{company.corpFirst ? '✅' : '--'}</TableCell>
                    <TableCell>
                      {company.disableEmailNotifications ? '✅' : '--'}
                    </TableCell>
                    <TableCell>
                      {new Date(company.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {company.updatedAt
                        ? new Date(company.updatedAt).toLocaleDateString()
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <MultilevelDropdown
                        title="..."
                        position={
                          companies.length - 1 === idx
                            ? MultilevelDropdownPositions.TopLeft
                            : MultilevelDropdownPositions.Left
                        }
                      >
                        <MultilevelItem
                          onClick={async () => {
                            setSelectedCompany(company)

                            let sortedContacts: APIContact[] = []

                            try {
                              const data = await getContacts(company.id, [])
                              sortedContacts = orderBy(data, [
                                'firstName',
                                'lastName',
                              ])

                              setContacts(sortedContacts)
                            } catch (err) {
                              setContacts([])

                              toastError('Error fetching contacts.')
                            }

                            try {
                              const result: APICompany = await NiceModal.show(
                                AdminFormModal,
                                {
                                  currentTab,
                                  company,
                                  association: {},
                                  assigneeOptions: sortedContacts,
                                  // Filter associations that are already assigned to other companies
                                  propertyOptions: associations.filter(
                                    a =>
                                      !companies.some(
                                        c =>
                                          c.id !== company.id &&
                                          c.associationIds.includes(a.id),
                                      ),
                                  ),
                                },
                              )

                              setCompanies(
                                orderBy(
                                  [
                                    ...companies.filter(
                                      c => c.id !== result.id,
                                    ),
                                    result,
                                  ],
                                  'name',
                                ),
                              )
                            } catch (e) {
                              // Modal was cancelled, do nothing
                            }
                          }}
                        >
                          Update
                        </MultilevelItem>
                        <MultilevelItem
                          onClick={async () => {
                            const filename = `import-company-contacts-${kebabCase(
                              company.name,
                            )}-${company.id}.csv`
                            const csvTemplate = `Company Name,Property Name,Property ID,First name,Last name,Email,Phone number,Street address,Unit number,City,State (2 letter abbv),Zip code,Residence start date,Residence end date,Contact type,Access level,Phone: Landline,Phone: Landline alternate,Phone: Alternate cell,Email: Alternate,Vendor Company\n${company.associationIds
                              .map(
                                id =>
                                  `"${company.name}","${
                                    associations.find(a => a.id === id)?.name
                                  }",${id},,,,,,,,,,,,,,,,,,\n`,
                              )
                              .join('')}`

                            const element = document.createElement('a')

                            element.setAttribute(
                              'href',
                              `data:text/csv;charset=utf-8,${encodeURIComponent(
                                csvTemplate,
                              )}`,
                            )

                            element.setAttribute('download', filename)

                            element.style.display = 'none'

                            document.body.appendChild(element)

                            element.click()

                            document.body.removeChild(element)
                          }}
                        >
                          Download CSV template
                        </MultilevelItem>
                        <MultilevelItem
                          onClick={async () => {
                            try {
                              await NiceModal.show(CSVUploadModal, {
                                companyId: company.id,
                                companyName: company.name,
                              })
                            } catch (e) {
                              // console.error('Modal was cancelled', e)
                            }
                          }}
                        >
                          Import contacts
                        </MultilevelItem>

                        <MultilevelItem
                          onClick={() => copyToClipboard(company.id)}
                        >
                          Copy ID to clipboard
                        </MultilevelItem>
                      </MultilevelDropdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      {tabs[currentTab]?.id === 'properties' && (
        <div style={{ margin: '40px 0' }}>
          <div style={{ marginBottom: 20 }}>
            <PrimaryButton
              onClick={async () => {
                try {
                  const result: APIAssociation = await NiceModal.show(
                    AdminFormModal,
                    {
                      currentTab,
                      company: selectedCompany,
                      association: {},
                      assigneeOptions: contacts,
                    },
                  )

                  setAssociations(
                    orderBy(
                      [...associations.filter(a => a.id !== result.id), result],
                      'name',
                    ),
                  )
                } catch (e) {
                  // console.error('Modal was cancelled', e)
                }
              }}
            >
              Add Property
            </PrimaryButton>
          </div>
          <h3>Properties</h3>
          <div style={{ maxHeight: 575, overflowY: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell
                    style={{ textAlign: 'left', width: 250 }}
                    onClick={() => handlePropertySort('name')}
                  >
                    Property
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('slug')}
                  >
                    Slug
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('phone')}
                  >
                    Phone
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('companyName')}
                  >
                    Company
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('stripeConnectStatus')}
                  >
                    Payments status
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('createdAt')}
                  >
                    Created
                  </TableHeaderCell>
                  <TableHeaderCell
                    style={{ textAlign: 'left' }}
                    onClick={() => handlePropertySort('updatedAt')}
                  >
                    Updated
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }} />
                </TableRow>
              </TableHead>

              <TableBody>
                {associationsWithCompanyNames.map((association, idx) => (
                  <TableRow
                    key={association.id}
                    isSelected={association.id === selectedAssociation?.id}
                    onClick={async () => {
                      setSelectedAssociation(association)

                      if (!association.companyId) {
                        setContacts([])

                        return
                      }

                      if (selectedAssociation?.id !== association.id) {
                        try {
                          const data = await getContacts(
                            association.companyId,
                            [association.id],
                          )

                          setContacts(orderBy(data, ['firstName', 'lastName']))
                        } catch (err) {
                          setContacts([])

                          // TODO: make a wrapper function for this; it's being
                          // used in a bunch of places now
                          // @ts-expect-error - HttpsErrors contain a message property
                          if (err?.message) {
                            toastError(
                              <div>
                                <div style={{ fontWeight: 'bold' }}>
                                  {/* @ts-expect-error - HttpsErrors contains a message property */}
                                  {err.message}
                                </div>
                              </div>,
                            )
                          } else {
                            toastError(
                              'An error occurred. Please check your inputs and try again.',
                            )
                          }
                        }
                      }
                    }}
                  >
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{association.name}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{association.slug}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      {association.phone
                        ? formatPhoneNumber(association.phone)
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{association.companyName}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      {association.stripeConnectStatus ===
                      StripeConnectStatus.Active
                        ? 'Active'
                        : association.stripeConnectStatus ===
                          StripeConnectStatus.Pending
                        ? 'Pending'
                        : 'Disabled'}
                    </TableCell>
                    <TableCell>
                      {association.createdAt
                        ? new Date(association.createdAt).toLocaleDateString()
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {association.updatedAt
                        ? new Date(association.updatedAt).toLocaleDateString()
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <MultilevelDropdown
                        title="..."
                        position={
                          associationsWithCompanyNames.length - 1 === idx
                            ? MultilevelDropdownPositions.TopLeft
                            : MultilevelDropdownPositions.Left
                        }
                      >
                        <MultilevelItem
                          onClick={async () => {
                            setSelectedAssociation(association)

                            let sortedContacts: APIContact[] = []

                            if (association.companyId) {
                              try {
                                const data = await getContacts(
                                  association.companyId,
                                  [association.id],
                                )
                                sortedContacts = orderBy(data, [
                                  'firstName',
                                  'lastName',
                                ])

                                setContacts(sortedContacts)
                              } catch (err) {
                                setContacts([])

                                toastError('Error fetching contacts.')
                              }
                            } else {
                              setContacts([])
                            }

                            try {
                              const result: APIAssociation =
                                await NiceModal.show(AdminFormModal, {
                                  currentTab,
                                  company: selectedCompany,
                                  association,
                                  assigneeOptions: sortedContacts,
                                })

                              setAssociations(
                                orderBy(
                                  [
                                    ...associations.filter(
                                      a => a.id !== result.id,
                                    ),
                                    result,
                                  ],
                                  'name',
                                ),
                              )
                            } catch (e) {
                              // Modal was cancelled, do nothing
                            }
                          }}
                        >
                          Update
                        </MultilevelItem>
                        <MultilevelItem
                          onClick={() => copyToClipboard(association.id)}
                        >
                          Copy ID to clipboard
                        </MultilevelItem>
                      </MultilevelDropdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      <div style={{ margin: '40px 0' }}>
        <h3>Contacts</h3>
        <h4>{contactsConstraintName}</h4>
        {contactsConstraintName && !contacts.length ? (
          <div>No contacts found</div>
        ) : !contacts.length ? (
          <div>
            Select a {currentTab === 0 ? 'company' : 'property'} to view
            contacts
          </div>
        ) : null}
        {contacts.length > 0 && (
          <div style={{ maxHeight: 575, overflowY: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ textAlign: 'left', width: 250 }}>
                    Name
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Email
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Phone
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Auth ID
                  </TableHeaderCell>
                  {selectedAssociation?.id && (
                    <TableHeaderCell style={{ textAlign: 'left' }}>
                      Access level
                    </TableHeaderCell>
                  )}
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Created
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Updated
                  </TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: 'left' }} />
                </TableRow>
              </TableHead>

              <TableBody>
                {contacts.map((contact, idx) => (
                  <TableRow key={contact.id}>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>
                          {contact.firstName} {contact.lastName}
                        </TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{contact.email || '--'}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    <TableCell>
                      {contact.phone?.number
                        ? formatPhoneNumber(contact.phone.number)
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <FlexRow>
                        <TruncatedText>{contact.userId}</TruncatedText>
                      </FlexRow>
                    </TableCell>
                    {selectedAssociation?.id && (
                      <TableCell>
                        <FlexRow>
                          {contact.propertyInfo.find(
                            p => p.associationId === selectedAssociation.id,
                          )?.accessLevel || '--'}
                        </FlexRow>
                      </TableCell>
                    )}

                    <TableCell>
                      {contact.createdAt
                        ? new Date(contact.createdAt).toLocaleDateString()
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {contact.updatedAt
                        ? new Date(contact.updatedAt).toLocaleDateString()
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <MultilevelDropdown
                        title="..."
                        position={
                          contacts.length - 1 === idx
                            ? MultilevelDropdownPositions.TopLeft
                            : MultilevelDropdownPositions.Left
                        }
                      >
                        <MultilevelItem
                          onClick={async () => {
                            try {
                              const result: APIContact = await NiceModal.show(
                                AdminFormModal,
                                {
                                  currentTab,
                                  company: selectedCompany,
                                  association: selectedAssociation,
                                  contact,
                                  propertyOptions: associations.filter(a =>
                                    selectedCompany.associationIds?.includes(
                                      a.id,
                                    ),
                                  ),
                                },
                              )

                              setContacts(
                                orderBy(
                                  [
                                    ...contacts.filter(c => c.id !== result.id),
                                    result,
                                  ],
                                  ['firstName', 'lastName'],
                                ),
                              )
                            } catch (e) {
                              // console.error('Modal was cancelled', e)
                            }
                          }}
                        >
                          Update
                        </MultilevelItem>
                        <MultilevelItem
                          onClick={() => copyToClipboard(contact.id)}
                        >
                          Copy ID to clipboard
                        </MultilevelItem>
                      </MultilevelDropdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminManagement

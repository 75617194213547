import React, { useState } from 'react'
import {
  Association,
  Announcement,
  TaskSubscriber,
  ContactReference,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import {
  FlexRow,
  Modal,
  PageTitle,
  TextButton,
  PrimaryButton,
} from 'components/lib'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  pdf,
  Image,
} from '@react-pdf/renderer'
import { ref } from 'firebase/storage'
import { format } from 'date-fns'
import { useStorageDownloadURL } from 'reactfire'
import { saveAs } from 'file-saver'
import Html from 'react-pdf-html'
import MarkdownIt from 'markdown-it'
import * as Sentry from '@sentry/react'
import { storage } from '../../../firebase/setup'

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
})

Font.register({
  family: 'Inter',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
})

Font.register({
  family: 'InterBold',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
  fontWeight: 'bold',
})

Font.register({
  family: 'InterLight',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
  fontWeight: 'light',
})

Font.register({
  family: 'InterItalic',
  src: 'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHy5XgqoUPvi5.ttf',
  fontWeight: 'normal',
  fontStyle: 'italic',
})

Font.register({
  family: 'InterItalicBold',
  src: 'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ylGC5XgqoUPvi5.ttf',
  fontWeight: 'bold',
  fontStyle: 'italic',
})

const styles = StyleSheet.create({
  page: {
    color: '#0A1F44',
    fontFamily: 'Inter',
    margin: 10,
    padding: 10,
    width: '100%',
  },
  section: {
    fontSize: 12,
  },
  title: {
    fontFamily: 'InterBold',
    fontSize: 20,
  },
  text: {
    fontSize: 12,
  },
  subHeading: {
    fontSize: 10,
    color: '#627088',
    textTransform: 'uppercase',
    marginBottom: 10,
    marginTop: 20,
  },
  subscriberText: {
    color: '#627088',
    fontSize: 12,
    fontFamily: 'InterLight',
  },
  checkmark: {
    width: 22,
    height: 22,
    transform: 'rotate(45deg)',
    marginLeft: -5,
  },
  checkmarkStem: {
    position: 'absolute',
    width: 1.5,
    height: 13,
    backgroundColor: '#627088',
    left: 11,
    top: 1,
    borderRadius: 10,
  },
  checkmarkKick: {
    position: 'absolute',
    width: 6,
    height: 1.5,
    backgroundColor: '#627088',
    left: 6,
    top: 13,
    borderRadius: 10,
  },
})

const renderAnnouncementDescription = (description: string) => {
  // remove the li bullet before checkboxes
  const checkboxDescription = description.replaceAll('- [', '[')
  const md = new MarkdownIt()
  const styledDescription = `
    <html>
      <body>
      <style>
      ul {
        padding: 3px;
        margin: 5px 0;
      }
      p {
        font-size: 12px;
        font-family: 'Inter';
        padding: 3px 0;
        margin: 0;
        max-width: 90%;
      }
      li {
        font-size: 12px;
        display: inline-block;
        font-family: 'Inter';
        padding: 3px 0;
      }
      li p {
        padding: 0;
        display: inline-block;
        max-width: 80%;
      }
      em {
        font-family: 'InterItalic';
      }
      strong {
        font-family: 'InterBold'
      }
      strong em {
        font-family: 'InterItalicBold';
      }
      em strong {
        font-family: 'InterItalicBold';
      }
      a {
        color: #0A1F44;
      }
      img {
        object-fit: scale-down;
        max-width: 500px;
        height: auto;
      }
      </style>
        ${md.render(checkboxDescription)}
      </body>
    </html>

`
  return <Html>{styledDescription}</Html>
}

const RenderInitial = ({
  contact,
  size,
}: {
  contact: TaskSubscriber | ContactReference
  size: number
}) => {
  const style = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: '#0A1F44',
    marginHorizontal: 3,
  }

  const initial = contact?.email ? `E` : `#`
  const text = contact.firstName
    ? `${contact.firstName[0]}${
        (contact.lastName && contact.lastName[0]) || ''
      }`
    : initial

  return (
    <View style={style}>
      <Text
        style={{
          color: 'white',
          fontSize: size / 2,
          textAlign: 'center',
          marginTop: size / 5,
        }}
      >
        {text}
      </Text>
    </View>
  )
}

const RenderImage = ({
  contact,
  size,
}: {
  contact: TaskSubscriber | ContactReference
  size: number
}) => {
  const style = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: '#0A1F44',
    marginHorizontal: 3,
    objectFit: 'cover',
  }

  const fileRef = ref(storage, contact.photoURL!)
  const { status, data: imageURL } = useStorageDownloadURL(fileRef)

  if (status === 'success') {
    return <Image style={style} cache={false} source={imageURL} />
  }
  // TODO - large images are taking too long to load. when we have re-sized
  // images, update this component to call the smallest images
  return <RenderInitial contact={contact} size={size} />
}

const MyDocument = ({ announcement }: { announcement: Announcement }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{announcement.title}</Text>
      </View>
      {announcement.description && (
        <View style={[styles.section, { marginLeft: -5 }]}>
          {renderAnnouncementDescription(announcement.description)}
        </View>
      )}
      <View>
        <Text style={styles.subHeading}>ACTIVITY:</Text>
        <View
          wrap
          style={{
            maxWidth: '95%',
            flex: 1,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 15,
            }}
          >
            <View style={{ width: 30 }}>
              {announcement.createdBy?.photoURL ? (
                <RenderImage
                  contact={announcement.createdBy as ContactReference}
                  size={20}
                />
              ) : (
                <RenderInitial
                  contact={announcement.createdBy as ContactReference}
                  size={20}
                />
              )}
            </View>
            <View style={{ width: '80%' }}>
              <View
                wrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'InterBold',
                    fontSize: 12,
                    marginRight: 5,
                  }}
                >
                  {getContactDisplayName(announcement.createdBy)}
                </Text>
                <Text style={{ fontSize: 12 }}>
                  {announcement?.createdBy?.title || ''} created this
                  announcement.
                </Text>
              </View>
              <Text
                style={{
                  color: '#8A94A6',
                  fontSize: 10,
                  marginTop: 10,
                }}
              >
                {' '}
                {announcement.createdAt &&
                  format(announcement.createdAt.toDate(), 'PPpp')}
              </Text>
              <Text
                style={{
                  color: '#8A94A6',
                  fontSize: 10,
                  marginLeft: 10,
                }}
              >
                {' '}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

const generatePdfDocument = async (
  announcement: Announcement,
  fileName: any,
  setDownloadError: any,
  closeModal: any,
) => {
  try {
    const blob = await pdf(<MyDocument announcement={announcement} />).toBlob()
    if (blob.size === 1167) {
      setDownloadError('There is an error downloading your PDF')
      Sentry.captureException({
        message: 'Attempted download of PDF blob is too big',
        blob,
      })
    } else {
      await saveAs(blob, fileName)
      closeModal(1000)
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('cloudflare-ipfs.com')) {
        setDownloadError(
          'PDFs with images can not be downloaded on an emulator due to CORS issues.',
        )
      } else {
        Sentry.captureException({
          message: 'User is unable to download PDF',
          error,
        })
        setDownloadError('There is an error downloading your PDF')
      }
    }
  }
}

interface AnnouncementItem extends Announcement {
  id?: string
}

const DownloadPdf = ({
  announcement,
  association,
  onClose,
}: {
  announcement: AnnouncementItem
  association: Association
  onClose: Function
}) => {
  const [downloadError, setDownloadError] = useState('')

  const closeModal = (time: number) => {
    setTimeout(() => {
      onClose()
    }, time)
  }

  return (
    <Modal isOpen>
      <PageTitle>Export PDF</PageTitle>
      <p style={{ marginBottom: 30 }}>
        This announcement will be exported as a PDF, including uploaded files
        and images.
      </p>
      {downloadError && <p style={{ color: '#d62323' }}>{downloadError}</p>}
      <FlexRow justify="flex-end" align="center">
        <FlexRow>
          <TextButton onClick={() => closeModal(0)} style={{ marginRight: 5 }}>
            Cancel
          </TextButton>
          <PrimaryButton
            onClick={() =>
              generatePdfDocument(
                announcement,
                `${association.name} - ${announcement.title}.pdf`,
                setDownloadError,
                closeModal,
              )
            }
            style={{ marginRight: 5 }}
          >
            Download PDF
          </PrimaryButton>
        </FlexRow>
      </FlexRow>
    </Modal>
  )
}

export default DownloadPdf

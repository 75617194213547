import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  AnnoucementFormData,
  APIAssociation,
  APIContact,
  ContactGroup,
  ContactReference,
  createContactReference,
  getContactDisplayName,
  NotificationContactMethod,
  PropertyInfo,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import {
  ContactForSelection,
  ContactRow,
  DropdownFixedHeader,
  GroupForSelection,
  GroupItem,
  groupStatus,
  GroupWrapper,
  residentGroups,
  totalInGroup,
} from 'components/app/Dropdowns/ContactSelectEmailSms'
import Editor from 'components/app/Editor/Editor'
import { EditorRef } from 'components/app/Editor/types'
import { findInArray } from 'components/app/Tasks/TaskSubscriptions'
import { Banner, BannerDismiss } from 'components/app/TaskSheet'
import {
  Checkbox,
  FlexRow,
  GhostTextInput,
  IconButton,
  Modal,
  MultilevelDivider,
  MultilevelDropdown,
  MultilevelDropdownButton,
  MultilevelDropdownPositions,
  MultilevelHeader,
  MultilevelItem,
  MultilevelNoResults,
  PrimaryButton,
  TextButton,
  TruncatedText,
} from 'components/lib'
import { Field, Form, Formik } from 'formik'
import useContactsCache from 'hooks/useContactsCache'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClose,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineGroups,
  MdOutlineIndeterminateCheckBox,
} from 'react-icons/md'
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
import {
  authenticatedUserAtom,
  newAnnouncementDescriptionAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import sortSubscriberAssigneeContacts from 'utils/sortSubscriberAssigneeContacts'
import * as Yup from 'yup'
import { associationChoicesAtom, windowDimensionsAtom } from '../../AppRoutes'

const EditorWrapper = styled.div`
  padding: 0 2px;
  width: 100%;
  min-height: 128px;
  max-height: 300px;
  flex: 1;
  border-radius: 4;
  overflow: auto;

  @media (min-width: 1000px) {
    width: 480;
  }
`

export const FormTitle = styled.h2`
  margin: 0 0 16px;
  color: ${props => props.theme.colors.text100};
`

const FormField = styled(FlexRow)`
  padding: 16px 4px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`

const FormFieldLabel = styled.div`
  color: ${props => props.theme.colors.text250};
  width: 200px;
  margin-right: 8px;
`

const FormFieldGhostInput = styled(GhostTextInput)`
  font-size: 14px;
  margin-bottom: 0;
`

export const anncSelectedContactsAtom = atom<TaskSubscriber[]>({
  key: 'anncSelectedContacts',
  default: [],
})

const CreateAnnouncementModal = NiceModal.create(
  ({
    formValues = {
      title: '',
      description: '',
      subscriptions: [],
      associationIds: [],
      sendCopyToSelf: false,
    },
  }: {
    formValues: AnnoucementFormData
  }) => {
    const modal = useModal()
    const editorRef = useRef<EditorRef>(null)
    const windowDimensions = useRecoilValue(windowDimensionsAtom)
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)
    const [newAnnouncementDescription, setNewAnnouncementDescription] =
      useRecoilState(newAnnouncementDescriptionAtom)
    const resetNewAnnouncementDescription = useResetRecoilState(
      newAnnouncementDescriptionAtom,
    )
    const [searchContacts, setSearchContacts] = useState('')

    const [initialDescription] = useState<string>(
      newAnnouncementDescription !== ''
        ? newAnnouncementDescription
        : formValues.description || '',
    )
    const [isSmsBannerDismissed, setIsSmsBannerDismissed] =
      useState<boolean>(false)

    useEffect(() => {
      if (editorRef.current) {
        editorRef.current.value =
          newAnnouncementDescription !== ''
            ? newAnnouncementDescription
            : formValues.description || ''
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const associationChoices = useRecoilValue(associationChoicesAtom)
    const [associationSearchValue, setAssociationSearchValue] = useState('')
    const [filteredAssociations, setFilteredAssociations] = useState<
      APIAssociation[]
    >([])

    const [selectedAssociations, setSelectedAssociations] = useState<string[]>(
      formValues.associationIds.filter(id => id !== null) as string[],
    )

    const [selectedContacts, setSelectedContacts] = useRecoilState(
      anncSelectedContactsAtom,
    )

    const [contactChoices, setContactChoices] = useState<ContactForSelection[]>(
      [],
    )

    const { data: contacts } = useContactsCache(
      authenticatedUser.selectedCompany.id,
      selectedAssociations,
    )

    useEffect(() => {
      if (associationChoices !== undefined) {
        setFilteredAssociations(
          associationChoices.filter(hoa => {
            // Filter associations that the user can create announcements in
            const canCreateAnnouncementInAssociation =
              authenticatedUser.selectedContact.propertyInfo.find(
                p => p.associationId === hoa.id,
              )?.acl.announcements.create ?? false

            return (
              canCreateAnnouncementInAssociation &&
              hoa.name
                .toLowerCase()
                .includes(associationSearchValue.trim().toLowerCase())
            )
          }),
        )
      }
    }, [
      associationChoices,
      associationSearchValue,
      authenticatedUser.selectedContact?.propertyInfo,
    ])

    const AnnouncementSchema = Yup.object({
      title: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required('Required'),
      description: Yup.string(),
      associationIds: Yup.array()
        .of(Yup.string())
        .min(1, 'Must select at least 1 property.'),
      sendCopyToSelf: Yup.boolean(),
    })

    const filteredContacts = useMemo(
      () =>
        contacts.filter(c =>
          c.associationIds.some(id => selectedAssociations.includes(id)),
        ),
      [contacts, selectedAssociations],
    )

    const setContactOptions = () => {
      const searchText = searchContacts.toLowerCase().trim()
      const options =
        filteredContacts.length > 0
          ? [
              ...filteredContacts
                .filter((contact: APIContact) => {
                  const fullName = `${contact.firstName ?? ''} ${
                    contact.lastName ?? ''
                  }`.trim()

                  return (
                    fullName.toLowerCase().includes(searchText) ||
                    (!!contact.email &&
                      contact.email.toLowerCase().includes(searchText)) ||
                    (!!contact.phone &&
                      contact.phone.number.toLowerCase().includes(searchText))
                  )
                })
                .map(c => ({
                  id: c.id,
                  data: c,
                  value: c.id,
                  label: getContactDisplayName(c),
                  groups: _.flatten(
                    c.propertyInfo
                      .filter((p: PropertyInfo) =>
                        selectedAssociations.includes(p.associationId),
                      )
                      .map(p => p.groups),
                  ),
                  title: '',
                  canNotContact: {
                    email: !c.email || c.email.length === 0,
                    phone:
                      !c.phone ||
                      c.phone.type !== 'mobile' ||
                      c.phone.number.length < 9,
                    voice: !c.phone || c.phone.number.length < 9,
                  },
                  selected: selectedContacts.some(
                    (s: TaskSubscriber) => s.contactId === c.id,
                  ),
                  selectedType:
                    selectedContacts.find(
                      (s: ContactReference) => s.contactId === c.id,
                    )?.preferences || [],
                  photoURL: c.photoURL,
                  isGroup: false,
                  userId: c.userId,
                })),
            ]
          : []

      setContactChoices(sortSubscriberAssigneeContacts(options))
    }

    useEffect(() => {
      setContactOptions()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssociations, contacts])

    const removeSubscription = async (contact: any) => {
      const subs = [...(selectedContacts || [])].filter(
        s => s.contactId !== contact.id,
      )
      const indexOfContactChoices = findInArray(contactChoices, contact.id)
      contactChoices[indexOfContactChoices].selected = false
      contactChoices[indexOfContactChoices].selectedType = []
      setContactChoices(contactChoices)
      setSelectedContacts(subs)
    }

    const toggleContact = async (
      contact: ContactForSelection,
      preference: NotificationContactMethod,
    ) => {
      const indexOfContactChoices = findInArray(contactChoices, contact.id)
      const existingSubscriptions = selectedContacts

      if (
        contact.selectedType &&
        contact.selectedType?.filter(p => p === preference).length > 0
      ) {
        const newPreferences = contact.selectedType
          ? contact.selectedType.filter(p => p !== preference)
          : ([] as NotificationContactMethod[])
        let updatedSubscriber = existingSubscriptions.find(
          (s: TaskSubscriber) => s.contactId === contact.id,
        )

        if (!updatedSubscriber) {
          return
        }
        // remove the preference from the subscriber.
        if (newPreferences.length > 0) {
          // if the other preference is still selected, don't remove the contact from subscribers, but update the preferences
          updatedSubscriber = {
            ...updatedSubscriber,
            preferences: newPreferences,
          }
          const updatedSubs = existingSubscriptions.map(s =>
            s.contactId === contact.id ? updatedSubscriber : s,
          )
          setSelectedContacts(updatedSubs as TaskSubscriber[])
          contactChoices[indexOfContactChoices].selectedType = newPreferences
          setContactChoices(contactChoices)
        } else {
          // if neither preference is selected, remove the contact from subscribers
          contactChoices[indexOfContactChoices].selectedType = []
          contactChoices[indexOfContactChoices].selected = false
          setContactChoices(contactChoices)
          removeSubscription(contact.data)
        }
      } else {
        // add the new preference.
        const newSubscriber = selectedContacts.find(
          c => c.contactId === contact.id,
        )
        if (!newSubscriber) {
          contactChoices[indexOfContactChoices].selectedType = [
            preference,
          ] as NotificationContactMethod[]
          contactChoices[indexOfContactChoices].selected = true
          setContactChoices(contactChoices)
          const apiContact = contacts.find(
            c => c.id === contact.id,
          ) as APIContact
          setSelectedContacts(
            existingSubscriptions.concat({
              ...createContactReference(apiContact),
              preferences: [preference],
            }),
          )
        } else {
          // find index in updatedSubscribers and update the preferences
          const updatedSubscriber = selectedContacts.find(
            (s: TaskSubscriber) => s.contactId === contact.id,
          )
          if (!updatedSubscriber) {
            return
          }

          const updatedSub = {
            ...updatedSubscriber,
            preferences: [
              ...updatedSubscriber.preferences.filter(p => p !== preference),
              preference,
            ],
          }
          setSelectedContacts(
            existingSubscriptions.map(s =>
              s.contactId === contact.id ? updatedSub : s,
            ) as TaskSubscriber[],
          )
          contactChoices[indexOfContactChoices].selectedType =
            contact.selectedType
              ? [
                  ...contact.selectedType.filter(p => p !== preference),
                  preference,
                ]
              : [preference]
          setContactChoices(contactChoices)
        }
      }
    }

    const removeGroupSubscription = (
      group: ContactGroup,
      preference: NotificationContactMethod,
    ) => {
      const existingSubscriptions = selectedContacts

      const updatedSubscriptions = existingSubscriptions
        .map((s: TaskSubscriber) =>
          s.preferences.length === 1 && s.preferences[0] === preference
            ? undefined
            : {
                ...s,
                preferences: s.preferences.filter(p => p !== preference),
              },
        )
        .filter(Boolean) as TaskSubscriber[]

      setSelectedContacts(updatedSubscriptions)
      const updatedContactChoices = contactChoices

      contactChoices
        .filter(c =>
          group === ContactGroup.Residents
            ? c.groups.some(g => residentGroups.includes(g))
            : c.groups.includes(group),
        )
        .forEach((contact: ContactForSelection) => {
          const indexOfContactChoices = findInArray(
            updatedContactChoices,
            contact.id,
          )

          updatedContactChoices[indexOfContactChoices] = {
            ...contactChoices[indexOfContactChoices],
            selected: true,
            selectedType: contact.selectedType
              ? [...contact.selectedType.filter(p => p !== preference)]
              : ([] as NotificationContactMethod[]),
          }

          setContactChoices(updatedContactChoices)
        })
    }

    const saveGroupSubscriptions = async (
      groupsContacts: ContactForSelection[],
      preference: NotificationContactMethod,
    ) => {
      const updatedContactChoices = contactChoices

      // add preference to existing subscribers and add new subscribers
      const newSubscriptions = [
        ...groupsContacts.map((n: ContactForSelection) => ({
          ...createContactReference(
            contacts.find(c => c.id === n.id) as APIContact,
          ),
          preferences: n.selectedType
            ? [
                ...n.selectedType.filter(
                  (p: NotificationContactMethod) => p !== preference,
                ),
                preference,
              ]
            : [preference],
        })),
        // remove selectedContacts that are in the groupContacts
        ...selectedContacts.filter(
          (s: TaskSubscriber) =>
            !groupsContacts.some(
              (n: ContactForSelection) => n.id === s.contactId,
            ),
        ),
      ]

      setSelectedContacts(newSubscriptions as TaskSubscriber[])

      groupsContacts.forEach((contact: ContactForSelection) => {
        const indexOfContactChoices = findInArray(
          updatedContactChoices,
          contact.id,
        )
        const preferences = contact.selectedType
          ? [
              ...contact.selectedType.filter(
                (p: NotificationContactMethod) => p !== preference,
              ),
              preference,
            ]
          : [preference]

        updatedContactChoices[indexOfContactChoices] = {
          ...contactChoices[indexOfContactChoices],
          selected: true,
          selectedType: preferences as NotificationContactMethod[],
        }
        setContactChoices(updatedContactChoices)
      })
    }

    const toggleGroup = async (
      item: GroupForSelection,
      preference: NotificationContactMethod,
    ) => {
      // if the groupStatus is 'all' or 'some', remove everyone from the group.
      if (
        ['all', 'some'].includes(groupStatus(item, contactChoices, preference))
      ) {
        // remove everyone from subscribers
        removeGroupSubscription(item.value, preference)
      } else {
        // if the groupStatus is anything other than 'all',
        // add anyone from group that isn't subscribed yet
        const groupContacts = contactChoices.filter(
          (person: ContactForSelection) => {
            if (
              ((item.value === ContactGroup.Residents &&
                person.groups.some(g => residentGroups.includes(g))) ||
                (item.value !== ContactGroup.Residents &&
                  person.groups?.includes(item.value))) &&
              ((preference === 'email' && !person.canNotContact.email) ||
                (preference === 'phone' && !person.canNotContact.phone) ||
                (preference === 'voice' && !person.canNotContact.voice))
            ) {
              return person.data
            }

            return false
          },
        )

        saveGroupSubscriptions(groupContacts, preference)
      }
    }

    const [groups, setGroups] = useState<GroupForSelection[]>([])
    // /* eslint-disable react-hooks/exhaustive-deps */
    const sortGroups = () => {
      if (selectedAssociations.length < 1) {
        setGroups([])
        return
      }
      const contactsForGroups = contacts.filter(c =>
        c.associationIds.some((id: string) =>
          selectedAssociations.includes(id),
        ),
      )

      const availableGroups = [
        GroupItem(ContactGroup.Board, contactsForGroups, selectedAssociations),
        GroupItem(
          ContactGroup.Management,
          contactsForGroups,
          selectedAssociations,
        ),
        GroupItem(
          ContactGroup.Sponsors,
          contactsForGroups,
          selectedAssociations,
        ),
        GroupItem(ContactGroup.Staff, contactsForGroups, selectedAssociations),
        GroupItem(ContactGroup.Owners, contactsForGroups, selectedAssociations),
        GroupItem(
          ContactGroup.Renters,
          contactsForGroups,
          selectedAssociations,
        ),
        GroupItem(
          ContactGroup.Residents,
          contactsForGroups,
          selectedAssociations,
        ),
      ].filter(Boolean)
      setGroups(availableGroups as GroupForSelection[])
    }

    useEffect(() => {
      if (selectedAssociations.length > 0) {
        sortGroups()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, selectedAssociations])

    const hasSmsSubscribers = useMemo(
      () =>
        selectedContacts.some((s: TaskSubscriber) => {
          const phonePreferences = ['phone', 'voice']
          return s.preferences.some(p => phonePreferences.includes(p))
        }),
      [selectedContacts],
    )

    const buttonText = useMemo(() => {
      // get unique preferences from selectedContacts
      const preferences = selectedContacts
        .map((s: TaskSubscriber) => s.preferences)
        .flat()
        .filter(
          (p: NotificationContactMethod, i: number, a: string[]) =>
            a.indexOf(p) === i,
        )

      // if preferences contains phone, replace it with 'SMS'
      const newPreferences = preferences.map(p =>
        p === 'phone' ? 'SMS' : p === 'voice' ? 'phone' : p,
      )

      switch (newPreferences.length) {
        case 1:
          return `Send via ${newPreferences[0]}`
        case 2:
          return `Send via ${newPreferences[0]} & ${newPreferences[1]}`
        case 3:
          return `Send via ${newPreferences[0]}, ${newPreferences[1]}, & ${newPreferences[2]}`
        default:
          return 'Send'
      }
    }, [selectedContacts])

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          resetNewAnnouncementDescription()
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <Formik
          initialValues={formValues}
          validationSchema={AnnouncementSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const annoucementValues = {
              ...values,
              subscriptions: selectedContacts,
            }
            modal.resolve(annoucementValues)
            modal.hide()
            setSubmitting(false)
            resetNewAnnouncementDescription()
          }}
        >
          {({ isValid, isSubmitting, setFieldValue, values }) => (
            <Form style={{ width: 600, maxWidth: '80vw' }}>
              <FormTitle>New announcement</FormTitle>
              <FormField align="center">
                <FormFieldLabel>Properties</FormFieldLabel>
                {associationChoices && associationChoices?.length && (
                  <Field name="associationIds">
                    {({
                      field: { value = [] },
                    }: {
                      field: { value: string[] }
                    }) => (
                      <MultilevelDropdown
                        maxHeight={`${windowDimensions.height - 300}px`}
                        position={MultilevelDropdownPositions.Left}
                        trigger={<MultilevelDropdownButton inheritStyles />}
                        title={
                          value.length > 1
                            ? `${
                                associationChoices?.length === value.length
                                  ? 'All'
                                  : value.length
                              } properties`
                            : !associationChoices || value.length < 1
                            ? 'Select properties'
                            : associationChoices.filter(
                                a => a.id === value[0],
                              )[0].name
                        }
                        closeOnClick={false}
                      >
                        <MultilevelHeader
                          onChange={setAssociationSearchValue}
                          value={associationSearchValue}
                          placeholder="Search properties"
                          clearValue={() => setAssociationSearchValue('')}
                        />
                        {associationChoices.length > 1 &&
                          filteredAssociations.length ===
                            associationChoices.length && (
                            <MultilevelItem
                              key="all-associations"
                              onClick={() => {
                                if (value.length > 0) {
                                  setSelectedAssociations([])
                                  setFieldValue('associationIds', [])
                                } else {
                                  setSelectedAssociations(
                                    associationChoices.map(a => a.id),
                                  )
                                  setFieldValue(
                                    'associationIds',
                                    associationChoices.map(a => a.id),
                                  )
                                }
                              }}
                            >
                              <FlexRow align="center">
                                {value.length === 0
                                  ? `Select All (${associationChoices.length})`
                                  : `Deselect All (${value.length})`}
                              </FlexRow>
                            </MultilevelItem>
                          )}
                        {filteredAssociations.map(association => (
                          <MultilevelItem
                            key={`${association.id}+${association.name}`}
                            onClick={() => {
                              const isDeselect =
                                value.indexOf(association.id) !== -1
                              if (isDeselect) {
                                setSelectedAssociations(
                                  selectedAssociations.filter(
                                    a => a !== association.id,
                                  ),
                                )
                                setFieldValue(
                                  'associationIds',
                                  value.filter(a => a !== association.id),
                                )
                              } else {
                                setSelectedAssociations([
                                  ...selectedAssociations,
                                  association.id,
                                ])
                                setFieldValue('associationIds', [
                                  ...value,
                                  association.id,
                                ])
                              }
                            }}
                          >
                            <FlexRow justify="space-between" align="center">
                              <FlexRow align="center">
                                {value.indexOf(association.id) !== -1 ? (
                                  <MdCheckBox
                                    style={{
                                      fontSize: 18,
                                      marginRight: 5,
                                    }}
                                  />
                                ) : (
                                  <MdCheckBoxOutlineBlank
                                    style={{
                                      fontSize: 18,
                                      color: '#C9CED6',
                                      marginRight: 5,
                                    }}
                                  />
                                )}
                                <TruncatedText
                                  style={{
                                    maxWidth: windowDimensions.width * 0.6,
                                  }}
                                >
                                  {association.name}
                                </TruncatedText>
                              </FlexRow>
                            </FlexRow>
                          </MultilevelItem>
                        ))}
                        {filteredAssociations.length < 1 && (
                          <MultilevelNoResults />
                        )}
                      </MultilevelDropdown>
                    )}
                  </Field>
                )}
              </FormField>
              <FormField align="center">
                <FormFieldLabel>Recipients</FormFieldLabel>

                <MultilevelDropdown
                  isDisabled={values.associationIds.length === 0}
                  position={
                    windowDimensions.isMobile || windowDimensions.isTablet
                      ? MultilevelDropdownPositions.Left
                      : MultilevelDropdownPositions.Right
                  }
                  trigger={<MultilevelDropdownButton inheritStyles />}
                  title={
                    selectedContacts.length === 0
                      ? 'Select recipients'
                      : `${selectedContacts.length} recipient${
                          selectedContacts.length > 1 ? 's' : ''
                        }`
                  }
                  closeOnClick={false}
                  maxHeight="400px"
                  fixedHeader={
                    <DropdownFixedHeader
                      search={searchContacts}
                      setSearch={setSearchContacts}
                      handleReset={() => setSearchContacts('')}
                      searchPlaceHolder="Recipients"
                      allowSms
                      allowVoiceCall
                    />
                  }
                >
                  {groups
                    .filter((group: GroupForSelection) =>
                      group.label
                        .toLowerCase()
                        .includes(searchContacts.toLowerCase()),
                    )
                    .map((option: GroupForSelection) => (
                      <MultilevelItem
                        isDisabled={totalInGroup(option, contactChoices) === 0}
                        key={option.id}
                      >
                        <FlexRow align="center" justify="space-between">
                          <GroupWrapper group={option.value}>
                            <FlexRow justify="space-between" align="center">
                              <FlexRow
                                align="center"
                                style={{
                                  color:
                                    totalInGroup(option, contactChoices) === 0
                                      ? '#B0B7C3'
                                      : 'initial',
                                }}
                              >
                                <MdOutlineGroups
                                  style={{
                                    marginRight: 8,
                                    fontSize: 20,
                                    color:
                                      totalInGroup(option, contactChoices) === 0
                                        ? '#B0B7C3'
                                        : '#627088',
                                  }}
                                />
                                <TruncatedText
                                  style={{
                                    maxWidth: windowDimensions.width * 0.4,
                                  }}
                                >
                                  {option.label}

                                  <span
                                    style={{
                                      color:
                                        totalInGroup(option, contactChoices) ===
                                        0
                                          ? '#B0B7C3'
                                          : '#627088',
                                      fontSize: 12,
                                      marginLeft: 5,
                                    }}
                                  >
                                    {`${totalInGroup(option, contactChoices)} ${
                                      totalInGroup(option, contactChoices) === 1
                                        ? ' person'
                                        : ' people'
                                    }`}
                                  </span>
                                </TruncatedText>
                              </FlexRow>
                            </FlexRow>
                          </GroupWrapper>
                          <FlexRow
                            align="center"
                            justify="space-between"
                            style={{
                              height: 20,
                              width: 135,
                            }}
                          >
                            {option.isGroup && (
                              <IconButton
                                type="button"
                                onClick={evt => {
                                  evt.preventDefault()
                                  evt.stopPropagation()
                                  toggleGroup(
                                    option as GroupForSelection,
                                    'email',
                                  )
                                }}
                              >
                                <FlexRow align="center" justify="center">
                                  {groupStatus(
                                    option,
                                    contactChoices,
                                    'email',
                                  ) === 'all' ? (
                                    <MdOutlineCheckBox
                                      style={{
                                        fontSize: 20,
                                        color: '#0A1F44',
                                      }}
                                    />
                                  ) : groupStatus(
                                      option,
                                      contactChoices,
                                      'email',
                                    ) === 'some' ? (
                                    <MdOutlineIndeterminateCheckBox
                                      style={{
                                        fontSize: 20,
                                        color: '#0A1F44',
                                      }}
                                    />
                                  ) : (
                                    <MdOutlineCheckBoxOutlineBlank
                                      style={{
                                        fontSize: 20,
                                        color: '#C9CED6',
                                      }}
                                    />
                                  )}
                                </FlexRow>
                              </IconButton>
                            )}
                            <IconButton
                              type="button"
                              onClick={evt => {
                                evt.preventDefault()
                                evt.stopPropagation()
                                toggleGroup(
                                  option as GroupForSelection,
                                  'phone',
                                )
                              }}
                            >
                              <FlexRow align="center" justify="center">
                                {groupStatus(
                                  option,
                                  contactChoices,
                                  'phone',
                                ) === 'all' ? (
                                  <MdOutlineCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : groupStatus(
                                    option,
                                    contactChoices,
                                    'phone',
                                  ) === 'some' ? (
                                  <MdOutlineIndeterminateCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : (
                                  <MdOutlineCheckBoxOutlineBlank
                                    style={{
                                      fontSize: 20,
                                      color: '#C9CED6',
                                    }}
                                  />
                                )}
                              </FlexRow>
                            </IconButton>
                            <IconButton
                              type="button"
                              onClick={evt => {
                                evt.preventDefault()
                                evt.stopPropagation()
                                toggleGroup(
                                  option as GroupForSelection,
                                  'voice',
                                )
                              }}
                            >
                              <FlexRow align="center" justify="center">
                                {groupStatus(
                                  option,
                                  contactChoices,
                                  'voice',
                                ) === 'all' ? (
                                  <MdOutlineCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : groupStatus(
                                    option,
                                    contactChoices,
                                    'voice',
                                  ) === 'some' ? (
                                  <MdOutlineIndeterminateCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : (
                                  <MdOutlineCheckBoxOutlineBlank
                                    style={{
                                      fontSize: 20,
                                      color: '#C9CED6',
                                    }}
                                  />
                                )}
                              </FlexRow>
                            </IconButton>
                          </FlexRow>
                        </FlexRow>
                      </MultilevelItem>
                    ))}
                  {groups.find((group: GroupForSelection) =>
                    group.label
                      .toLowerCase()
                      .includes(searchContacts.toLowerCase()),
                  ) && <MultilevelDivider />}
                  {contactChoices
                    .filter((contact: ContactForSelection) =>
                      contact.label
                        .toLowerCase()
                        .includes(searchContacts.toLowerCase()),
                    )
                    .map(contact => (
                      <MultilevelItem key={contact.id}>
                        <ContactRow
                          option={contact}
                          toggleContact={toggleContact}
                          allowSms
                          allowVoiceCall
                        />
                      </MultilevelItem>
                    ))}
                  {contactChoices.filter((contact: ContactForSelection) =>
                    contact.label
                      .toLowerCase()
                      .includes(searchContacts.toLowerCase()),
                  ).length === 0 && <MultilevelNoResults />}
                </MultilevelDropdown>
              </FormField>
              <FormField align="center">
                <Field
                  autoFocus
                  name="title"
                  type="text"
                  placeholder="Add title"
                  as={FormFieldGhostInput}
                />
              </FormField>
              <EditorWrapper style={{ marginBottom: 16 }}>
                <Editor
                  ref={editorRef}
                  placeholder="Add announcement details or drag and drop a file to attach it."
                  value={initialDescription}
                  onChange={val => {
                    setNewAnnouncementDescription(val)
                    setFieldValue('description', val)
                  }}
                  disableMentions
                />
              </EditorWrapper>
              {hasSmsSubscribers && (
                <p style={{ color: '#8A94A6', marginBottom: 5 }}>
                  {newAnnouncementDescription.length}/500
                </p>
              )}

              <Banner
                visible={hasSmsSubscribers && !isSmsBannerDismissed}
                style={{ marginBottom: 15 }}
              >
                <div>
                  SMS and phone will be sent as plain text, up to 500
                  characters.
                </div>
                <BannerDismiss
                  onClick={() => {
                    setIsSmsBannerDismissed(true)
                  }}
                >
                  <MdClose />
                </BannerDismiss>
              </Banner>
              {/* Do we still need this, if they can subscribe themselves now? */}
              <FlexRow align="center">
                <Field
                  name="sendCopyToSelf"
                  label="Send me a copy"
                  checked={values.sendCopyToSelf}
                  as={Checkbox}
                  onChange={() =>
                    setFieldValue('sendCopyToSelf', !values.sendCopyToSelf)
                  }
                />
                <TextButton
                  type="button"
                  onClick={() => {
                    resetNewAnnouncementDescription()
                    setSelectedContacts([])
                    modal.reject('cancel')
                    modal.hide()
                  }}
                  style={{ marginLeft: 'auto', marginRight: 4 }}
                >
                  Cancel
                </TextButton>
                <PrimaryButton
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    selectedContacts.length === 0 ||
                    selectedAssociations.length === 0
                  }
                >
                  {buttonText}
                </PrimaryButton>
              </FlexRow>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  },
)

export default CreateAnnouncementModal

import {
  APIAssociation,
  APIContact,
  Association,
} from '@super-software-inc/foundation'
import {
  Dropdown,
  FlexRow,
  IconButton,
  PrimaryButton,
  TextInput,
} from 'components/lib'
import { MdDeleteOutline, MdAdd } from 'react-icons/md'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'
import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik'
import React from 'react'
import { useRecoilValue } from 'recoil'
import * as Yup from 'yup'
import { useTheme } from 'styled-components'
import { windowDimensionsAtom } from '../../AppRoutes'
import {
  ErrorText,
  FieldWithError,
  InputGroup,
  InputGroupLabel,
} from '../../components/app/ContactForm/ProfileForm'
import { pillTitle } from '../../components/app/Tasks/TaskActionRow'

const PropertyFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  phone: Yup.string(),
  propertyAddresses: Yup.array().of(
    Yup.object().shape({
      borough: Yup.number().nullable(),
      block: Yup.number().nullable(),
      lot: Yup.number().nullable(),
      buildingNumber: Yup.string().required('Required'),
      streetAddress: Yup.string().required('Required'),
      bin: Yup.number().nullable(),
    }),
  ),
})

const boroughOptions = [
  { id: '1', label: 'Manhattan', value: 1 },
  { id: '2', label: 'Bronx', value: 2 },
  { id: '3', label: 'Brooklyn', value: 3 },
  { id: '4', label: 'Queens', value: 4 },
  { id: '5', label: 'Staten Island', value: 5 },
]

interface AddAddressFieldsProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  removeAddress: () => void
  index: number
}

const AddAddressFields = ({
  setFieldValue,
  removeAddress,
  index,
}: AddAddressFieldsProps) => {
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  return (
    <>
      <InputGroup>
        <FlexRow justify="space-between" align="center">
          <InputGroupLabel>Borough-Block-Lot (BBL)</InputGroupLabel>
          <IconButton type="button" destructive onClick={removeAddress}>
            <MdDeleteOutline />
          </IconButton>
        </FlexRow>
        <FlexRow
          align={windowDimensions.isMobile ? 'flex-start' : 'center'}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          {pillTitle('Borough')}
          <FieldWithError marginRight>
            <Field name={`propertyAddresses.${index}.borough`}>
              {({ field: { value } }: any) => (
                <Dropdown
                  value={value}
                  showIcon
                  options={boroughOptions}
                  onChange={(newValue: number) => {
                    setFieldValue(
                      `propertyAddresses.${index}.borough`,
                      newValue,
                    )
                  }}
                />
              )}
            </Field>
            <ErrorMessage
              name={`propertyAddresses.${index}.borough`}
              component={ErrorText}
            />
          </FieldWithError>
        </FlexRow>
        <FlexRow justify="space-between">
          <FieldWithError marginRight>
            <Field
              name={`propertyAddresses.${index}.block`}
              type="number"
              label="Block"
              placeholder="Block"
              as={TextInput}
            />
            <ErrorMessage
              name={`propertyAddresses.${index}.borough`}
              component={ErrorText}
            />
          </FieldWithError>
          <FieldWithError marginRight>
            <Field
              name={`propertyAddresses.${index}.lot`}
              type="number"
              placeholder="Lot"
              as={TextInput}
            />
            <ErrorMessage
              name={`propertyAddresses.${index}.lot`}
              component={ErrorText}
            />
          </FieldWithError>
        </FlexRow>
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Address</InputGroupLabel>
        <FlexRow justify="space-between">
          <FieldWithError marginRight style={{ flex: 1 }}>
            <Field
              name={`propertyAddresses.${index}.buildingNumber`}
              type="string"
              placeholder="Building Number"
              as={TextInput}
            />
            <ErrorMessage
              name={`propertyAddresses.${index}.buildingNumber`}
              component={ErrorText}
            />
          </FieldWithError>
          <FieldWithError marginRight style={{ flex: 2 }}>
            <Field
              name={`propertyAddresses.${index}.streetAddress`}
              type="text"
              placeholder="Address"
              as={TextInput}
            />
            <ErrorMessage
              name={`propertyAddresses.${index}.streetAddress`}
              component={ErrorText}
            />
          </FieldWithError>
        </FlexRow>
        <FlexRow>
          <FieldWithError marginRight>
            <Field
              name={`propertyAddresses.${index}.bin`}
              type="number"
              label="Building Identification Number"
              placeholder="Building Identification Number"
              as={TextInput}
            />
            <ErrorMessage
              name={`propertyAddresses.${index}.bin`}
              component={ErrorText}
            />
          </FieldWithError>
        </FlexRow>
      </InputGroup>
    </>
  )
}

const PropertyForm = ({
  association,
  assigneeOptions,
  closeModal,
}: {
  association: APIAssociation
  assigneeOptions: APIContact[]
  closeModal: (association: APIAssociation) => void
}) => {
  const theme = useTheme()
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  return (
    <Formik
      initialValues={{
        ...association,
        propertyAddresses: association.propertyAddresses || [],
      }}
      validationSchema={PropertyFormSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const functions = getFunctions()
        functions.region = 'us-east1'

        const createAssociation: HttpsCallable<
          { association: Partial<Association> },
          APIAssociation
        > = httpsCallable(functions, 'createAssociation')

        const updateAssociation: HttpsCallable<
          { associationId: string; association: APIAssociation },
          APIAssociation
        > = httpsCallable(functions, 'updateAssociation')

        try {
          const { data } = association.id
            ? await updateAssociation({
                associationId: association.id,
                association: values,
              })
            : await createAssociation({
                association: values,
              })

          setSubmitting(false)
          toastSuccess(`Property ${association.id ? 'updated' : 'created'}.`)
          resetForm({ values })
          closeModal(data)
        } catch (err) {
          // @ts-expect-error - HttpsErrors contain a message property
          if (err?.message) {
            toastError(
              <div>
                {/* @ts-expect-error - HttpsErrors contains a message property */}
                <div style={{ fontWeight: 'bold' }}>{err.message}</div>
              </div>,
            )
          } else {
            toastError(
              'An error occurred. Please check your inputs and try again.',
            )
          }
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values, dirty, isValid }) => (
        <Form
          style={{
            width: windowDimensions.isMobile
              ? windowDimensions.width * 0.95
              : 600,
          }}
        >
          <InputGroup>
            <InputGroupLabel>Name</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="name"
                  type="text"
                  placeholder="Property name"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="name" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>
          <InputGroup>
            <InputGroupLabel>Slug</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="slug"
                  type="text"
                  placeholder="Slug (e.g. property-name)"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="slug" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>
          <InputGroup>
            <InputGroupLabel>Phone (Twilio)</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="phone" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <FieldArray
              name="propertyAddresses"
              render={arrayHelpers => (
                <>
                  {values.propertyAddresses?.map((address, index) => (
                    <AddAddressFields
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      index={index}
                      setFieldValue={setFieldValue}
                      removeAddress={() => arrayHelpers.remove(index)}
                    />
                  ))}
                  <IconButton
                    type="button"
                    onClick={() => {
                      const addresses = values.propertyAddresses
                        ? values.propertyAddresses
                        : []
                      setFieldValue('propertyAddresses', [
                        ...addresses,
                        {
                          borough: undefined,
                          block: undefined,
                          lot: undefined,
                          buildingNumber: '',
                          streetAddress: '',
                          bin: undefined,
                        },
                      ])
                    }}
                    style={{ fontSize: 'inherit', color: theme.colors.text100 }}
                  >
                    Add Address <MdAdd />
                  </IconButton>
                </>
              )}
            />
          </InputGroup>

          <PrimaryButton
            type="submit"
            disabled={isSubmitting || !isValid || !dirty}
          >
            {association?.id ? `Save changes` : `Save`}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

export default PropertyForm

import * as Sentry from '@sentry/react'
import { FlexRow, PageTitle } from 'components/lib'
import { DocumentData } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components/macro'
import { selectedAssociationChoicesAtom } from '../../AppRoutes'
import AddCommunicationMenu from '../../components/app/AddCommunicationMenu'
import AnnouncementsView from './AnnouncementsView'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const showAnnouncementDrawerAtom = atom<boolean>({
  key: 'showAnnouncementDrawer',
  default: false,
})

export const selectedAnnouncementAtom = atom<DocumentData | undefined>({
  key: 'selectedAnnouncement',
  default: undefined,
})

export const PageHeader = styled.div`
  height: 60px;
  background-color: ${props => props.theme.colors.bg0};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: ${props => props.theme.zIndex.stickyHeader};
`

const AnncPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const associations = useRecoilValue(selectedAssociationChoicesAtom)
  const [, setShowAnnouncementDrawer] = useRecoilState(
    showAnnouncementDrawerAtom,
  )
  const [selectedAnnouncement, setSelectedAnnouncement] = useRecoilState(
    selectedAnnouncementAtom,
  )
  const [, setMissingAnnouncement] = useState<string | undefined>()

  const onAnncSelected = (announcement: DocumentData | null) => {
    if (announcement && announcement.id !== selectedAnnouncement?.id) {
      setSelectedAnnouncement(announcement)
      setMissingAnnouncement(undefined)
    }
    // Open the announcement sheet regardless.
    if (announcement) {
      setShowAnnouncementDrawer(true)
    }
  }

  useEffect(() => {
    setShowAnnouncementDrawer(false)
    setSelectedAnnouncement(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associations])

  const onRequestSheetClose = (e?: React.MouseEvent<HTMLDivElement>) => {
    setShowAnnouncementDrawer(false)
    setSelectedAnnouncement(undefined)

    const element = e ? (e.target as HTMLDivElement) : null
    const firstChildElement = element?.firstChild
      ? (element.firstChild as HTMLElement)
      : null
    const parentElement = element?.parentElement
      ? (element.parentElement as HTMLElement)
      : null

    // If a skip attribute is on an element itself, its first child, or its parent:
    if (
      element?.attributes.getNamedItem('data-prevent-request-sheet-close')
        ?.value ||
      firstChildElement?.attributes?.getNamedItem(
        'data-prevent-request-sheet-close',
      )?.value ||
      parentElement?.attributes?.getNamedItem(
        'data-prevent-request-sheet-close',
      )?.value
    ) {
      return
    }

    if (window.location.pathname.includes('announcements')) {
      navigate('/announcements')
    }

    // When closing the sheet from the calendar..
    if (!location.pathname.includes('/calendar')) {
      navigate('/announcements')
    }
  }

  return (
    <>
      <PageHeader>
        <PageTitle>Announcements</PageTitle>
        <FlexRow align="center">
          <AddCommunicationMenu />
        </FlexRow>
      </PageHeader>

      <SentryRoutes>
        <Route
          path="/"
          element={
            <AnnouncementsView
              onAnncSelected={a => onAnncSelected(a)}
              onAnncMissing={(anncId: string) => {
                setMissingAnnouncement(anncId)
                setShowAnnouncementDrawer(false)
              }}
              onRequestSheetClose={onRequestSheetClose}
            />
          }
        />
        <Route
          path="/:announcementId"
          element={
            <AnnouncementsView
              onAnncSelected={a => onAnncSelected(a)}
              onAnncMissing={(anncId: string) => {
                onAnncSelected({ id: anncId } as DocumentData)
                setShowAnnouncementDrawer(true)
              }}
              onRequestSheetClose={onRequestSheetClose}
            />
          }
        />
      </SentryRoutes>
    </>
  )
}

const AnncPageWrapper = () => {
  const associations = useRecoilValue(selectedAssociationChoicesAtom)

  if (!associations?.length) {
    return (
      <FlexRow align="center" justify="center" style={{ height: '100vh' }}>
        Please select a property!
      </FlexRow>
    )
  }

  return <AnncPage />
}

export default AnncPageWrapper

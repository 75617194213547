import {
  Contact,
  ContactGroup,
  createContactReference,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import { getContacts } from 'api/contacts/getContacts'
import { MentionNode } from 'components/app/Editor/mentions/types'
import parser from 'components/app/Editor/parser'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { firestore } from '../../firebase/setup'

interface JsonNode {
  type: string
  content?: JsonNode[]
  attrs: any
}

// Looks through a parsed Prosemirror doc for mentions and returns a flat array of them
export const recursiveFindMentionNodes = (node: JsonNode | JsonNode[]) => {
  const mentions: MentionNode[] = []

  if (!node) {
    return mentions
  }

  if (Array.isArray(node)) {
    node.forEach(n => {
      mentions.push(...recursiveFindMentionNodes(n))
    })
  } else if (node.type === 'mention') {
    mentions.push(node as MentionNode)
  } else if (node.content) {
    node.content.forEach((child: JsonNode) => {
      mentions.push(...recursiveFindMentionNodes(child))
    })
  }

  return mentions
}

// Takes markdown text, finds mentions in it and returns a uniquified array of resulting valid subscriptions.
export const turnMentionsIntoSubscriptions = async (
  markdownText: string,
  associationId: string | null,
) => {
  const parsed = parser.parse(markdownText)?.content.toJSON()
  const result: TaskSubscriber[] = []
  let mentions: MentionNode[] = []

  if (!associationId) {
    return { mentions: [], subscriptions: [] }
  }

  const companySnapshot = await getDocs(
    query(
      collection(firestore, 'companies'),
      where('associationIds', 'array-contains', associationId),
    ),
  )

  if (parsed && !companySnapshot.empty) {
    const companyId = companySnapshot.docs[0].id

    mentions = recursiveFindMentionNodes(parsed as JsonNode[])

    if (mentions && mentions.length) {
      const subscriptionsFromMentions = await Promise.all(
        mentions.map(async mention => {
          if (mention.attrs.type === 'contact') {
            const contact = await getDoc(
              doc(
                firestore,
                'companies',
                companyId,
                'companyContacts',
                mention.attrs.id,
              ),
            )
            const contactData = contact.data()
            if (
              (!contactData?.email || contactData?.email.length < 1) &&
              (!contactData?.phone ||
                contactData?.phone.type !== 'mobile' ||
                contactData?.phone.number.length < 1)
            ) {
              return null
            }
            const preference =
              contactData?.email?.length > 0 ? 'email' : 'phone'
            return {
              ...createContactReference(
                {
                  id: contact.id,
                  ...(contactData as Contact),
                },
                associationId,
              ),
              preferences: [preference],
            }
          }

          if (mention.attrs.type === 'group') {
            let contactGroups = [] as ContactGroup[]
            const groupName = mention.attrs.id

            switch (groupName) {
              // TODO: Resolve confusion around 'all' group before re-enabling.
              /*
              case 'all':
                contactsQuery = query(
                  collection(
                    firestore,
                    'companies',
                    companyId,
                    'companyContacts',
                  ),
                )
                break
                */
              case 'management':
                contactGroups = [ContactGroup.Management]
                break
              case 'board':
                contactGroups = [ContactGroup.Board]
                break
              case 'sponsors':
                contactGroups = [ContactGroup.Sponsors]
                break
              case 'owners':
                contactGroups = [ContactGroup.Owners]
                break
              case 'renters':
                contactGroups = [ContactGroup.Renters]
                break
              case 'residents':
                contactGroups = [
                  ContactGroup.Residents,
                  ContactGroup.Owners,
                  ContactGroup.Renters,
                ]
                break
              default:
                contactGroups = []
            }

            if (contactGroups.length === 0) {
              return []
            }

            // NOTE: I'm not sure this request can be refactored out
            const contacts = await getContacts(
              companyId,
              [associationId],
              contactGroups,
            )

            const contactsWithContactMethods = contacts.filter(
              contact =>
                (contact?.email && contact?.email.length > 0) ||
                (contact?.phone &&
                  contact?.phone.type === 'mobile' &&
                  contact?.phone.number.length > 0),
            )

            if (contactsWithContactMethods.length > 0) {
              return contactsWithContactMethods.map(contact => ({
                ...createContactReference(contact, associationId),
                preferences: [
                  contact.email && contact.email.length > 0 ? 'email' : 'phone',
                ],
              }))
            }

            return null
          }
          return null
        }),
      )
      subscriptionsFromMentions.forEach(s => {
        if (s) {
          if (Array.isArray(s)) {
            result.push(...(s as TaskSubscriber[]))
          } else {
            result.push(s as TaskSubscriber)
          }
        }
      })
    }
  }

  return { mentions, subscriptions: result }
}

export { default as getSubscribersToastMessage } from './getSubscribersToastMessage'

import {
  Announcement,
  Association,
  PropertyInfo,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import Editor from 'components/app/Editor/Editor'
import { EditorRef } from 'components/app/Editor/types'
import {
  FlexRow,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
} from 'components/lib'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import useIsOverflow from 'hooks/useIsOverflow'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MdClose,
  MdLink,
  MdMoreHoriz,
  MdOutlineCloudDownload,
} from 'react-icons/md'
import { useNavigate } from 'react-router'
import { usePrevious } from 'react-use'
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import AnncNotFound from 'pages/Secondary/AnnouncementNotFound'

import { associationChoicesAtom } from '../../../AppRoutes'
import DownloadAnnoucementPdf from '../PDFs/DownloadAnnouncementPdf'
import AnnouncementActionRow from './AnnouncementActionRow'
import AnnouncementActivity from './AnnouncementActivity'
import AnnouncementRecipients from './AnnouncementRecipients'

export const FileText = styled.div`
  color: ${props => props.theme.colors.text300};
  font-size: 10px;
`

export const FileHeaderText = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
`

const Banner = styled.aside<{ visible?: boolean }>`
  background: #fff1cb;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: 8px 16px;
  font-size: 12px;
  color: #0a1f44;
  margin: 0 -16px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
`

const BannerDismiss = styled.div`
  font-size: 16px;
  position: relative;
  top: 2px;
  cursor: pointer;
`

interface AnnouncementSheetProps {
  announcementId: string
  associationId: string
  onRequestClose: Function
}

const AnnouncementSheet: React.FC<AnnouncementSheetProps> = ({
  announcementId,
  associationId,
  onRequestClose,
}) => {
  const firestore = useFirestore()
  const functions = useFunctions()
  functions.region = 'us-east1'

  const associationChoices = useRecoilValue(associationChoicesAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const acl = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === associationId,
      )?.acl,

    [authenticatedUser.selectedContact, associationId],
  )

  const [downloadPdfIsOpen, setDownloadPdfIsOpen] = useState(false)

  const association = useMemo(
    () => associationChoices?.find(a => a.id === associationId),
    [associationChoices, associationId],
  )

  const associationUrl = `/associations/${associationId}`
  const announcementRef = doc(
    firestore,
    associationUrl,
    'announcements',
    announcementId,
  )
  const { status, data: announcement } = useFirestoreDocData(announcementRef, {
    idField: 'id',
  })

  const editorRef = useRef<EditorRef>(null)
  const [tempTitle, setTempTitle] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)

  const copyLinkToClipboard = () => {
    window.navigator.clipboard.writeText(
      `${window.location.origin}/announcements/${announcementRef.id}`,
    )
  }

  const prevAnnouncementId = usePrevious(announcement?.id)

  useEffect(() => {
    if (!announcement) {
      return
    }

    if (prevAnnouncementId !== announcement.id) {
      setTempTitle(announcement.title)

      if (editorRef.current) {
        editorRef.current.value = announcement.description
      }
    }
  }, [announcement, prevAnnouncementId])

  useEffect(() => {
    setIsDeleted(false)
  }, [announcementRef.id])

  useEffect(() => {
    const setAnnouncementAsRead = async () => {
      if (
        prevAnnouncementId !== announcementRef.id &&
        authenticatedUser.selectedContact.id &&
        !announcement?.read.includes(authenticatedUser.selectedContact.userId)
      ) {
        // Add the contact's user ID to the read array:
        await updateDoc(announcementRef, {
          read: arrayUnion(authenticatedUser.selectedContact.userId),
        })
      }
    }

    setAnnouncementAsRead()
    // We only want to run when the taskRef changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAnnouncementId, announcementRef])

  const hasSmsSubscribers = useMemo(
    () =>
      (announcement?.subscriptions || []).some((s: TaskSubscriber) =>
        s.preferences?.includes('phone'),
      ),
    [announcement?.subscriptions],
  )

  const [isSmsBannerDismissed, setIsSmsBannerDismissed] = useState<boolean>(
    localStorage.getItem(`dismissed-sms-banner-${announcementId}`) === 'true',
  )

  // adjust the shadow above text input if the contents scroll
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const sheetRef = React.useRef(null)

  useIsOverflow(sheetRef, (isOverflowFromCallback: boolean) => {
    if (isOverflowFromCallback !== isOverflowing) {
      setIsOverflowing(isOverflowFromCallback)
    }
  })

  const navigate = useNavigate()

  // check the loading status
  if (status === 'loading') {
    return null
  }

  if (!announcement || isDeleted) {
    return (
      <AnncNotFound
        onRequestClose={(event: any) => {
          event.preventDefault()
          event.stopPropagation()
          if (window.location.pathname.includes('announcements')) {
            const currentLocation = window.location.pathname
            const currentLocationParts = currentLocation.split('/')
            const newLocationParts = currentLocationParts.slice(0, -1)
            const newLocation = newLocationParts.join('/')

            navigate(`${newLocation}${window.location.search}`, {
              relative: 'path',
            })
          }

          onRequestClose()
        }}
      />
    )
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
      key={announcement.id}
    >
      <AnnouncementActionRow
        style={{
          flexWrap: 'wrap',
        }}
      >
        {downloadPdfIsOpen && (
          <DownloadAnnoucementPdf
            announcement={announcement as Announcement}
            association={association as Association}
            onClose={() => setDownloadPdfIsOpen(false)}
          />
        )}
        <div style={{ marginLeft: 'auto' }} />
        <MultilevelDropdown
          title={
            <IconButton as="div">
              <MdMoreHoriz />
            </IconButton>
          }
        >
          <MultilevelItem onClick={() => copyLinkToClipboard()}>
            <FlexRow align="center">
              <MdLink style={{ marginRight: 8, fontSize: 16 }} /> Copy link
            </FlexRow>
          </MultilevelItem>
          <MultilevelItem onClick={() => setDownloadPdfIsOpen(true)}>
            <FlexRow align="center">
              <MdOutlineCloudDownload
                style={{ marginRight: 8, fontSize: 16 }}
              />
              Export PDF
            </FlexRow>
          </MultilevelItem>
        </MultilevelDropdown>
        <IconButton
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            if (window.location.pathname.includes('announcements')) {
              const currentLocation = window.location.pathname
              const currentLocationParts = currentLocation.split('/')
              const newLocationParts = currentLocationParts.slice(0, -1)
              const newLocation = newLocationParts.join('/')

              navigate(`${newLocation}${window.location.search}`, {
                relative: 'path',
              })
            }

            onRequestClose()
          }}
        >
          <MdClose />
        </IconButton>
      </AnnouncementActionRow>
      <div
        ref={sheetRef}
        style={{
          // To avoid drop shadow cut-off issue.
          paddingLeft: 16,
          marginLeft: -16,
          overflowY: 'auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlexRow>
          <div style={{ width: '100%' }}>
            {/* Announcement title */}
            <FlexRow align="center">
              <h1 style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {tempTitle}
              </h1>
            </FlexRow>
            {/* Announcement description */}
            <div>
              <Editor
                isReadonly
                ref={editorRef}
                placeholder="No description"
                associationId={associationId}
              />
            </div>
          </div>
        </FlexRow>
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: '#e0e4e8',
            boxShadow: 'none',
            marginBottom: 20,
          }}
        />
        <FlexRow style={{ marginBottom: 8, alignItems: 'center' }}>
          <div>
            <h3>Activity</h3>
          </div>
          {acl?.announcements.create && (
            <AnnouncementRecipients announcementRef={announcementRef} />
          )}
        </FlexRow>
        <AnnouncementActivity announcement={announcement} />
      </div>
      {/* Comment form */}
      <Banner visible={hasSmsSubscribers && !isSmsBannerDismissed}>
        <div>
          SMS subscribers will only receive plain text updates in SMS replies.
        </div>
        <BannerDismiss
          onClick={() => {
            setIsSmsBannerDismissed(true)
            localStorage.setItem(
              `dismissed-sms-banner-${announcementId}`,
              'true',
            )
          }}
        >
          <MdClose />
        </BannerDismiss>
      </Banner>
    </div>
  )
}

export default AnnouncementSheet

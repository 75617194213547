import React, { useState, useEffect, ReactElement } from 'react'
import { RecoilRoot } from 'recoil'
import styled, { ThemeProvider } from 'styled-components/macro'
import NiceModal from '@ebay/nice-modal-react'
import { IconButton } from 'components/lib'
import {
  unstable_HistoryRouter as HistoryRouter,
  useLocation,
} from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import 'react-datepicker/dist/react-datepicker.css'

import {
  AuthProvider,
  FirestoreProvider,
  FirebaseAppProvider,
  StorageProvider,
  FunctionsProvider,
  AnalyticsProvider,
  useAnalytics,
} from 'reactfire'
import { isFeatureEnabled } from 'lib/featureFlags'

import { MdLightMode, MdDarkMode } from 'react-icons/md'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { createBrowserHistory } from 'history'
import { DarkTheme, LightTheme, ThemeGlobals } from './theme'
import firebaseConfig from './firebase/config'
import AppRoutes from './AppRoutes'

import { firebaseApp } from './firebase/setup'

const ThemeSelectorButton = styled(IconButton)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: ${props => props.theme.zIndex.themeSelector};
`

export const routerHistory = createBrowserHistory({ window })

export function FirebaseComponents({ children }: { children: ReactElement }) {
  const auth = getAuth(firebaseApp)
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp)
  const functions = getFunctions(firebaseApp)
  const analytics = getAnalytics(firebaseApp)
  const [emulators, setEmulators] = useState<boolean>(false)

  if (!firebaseApp || !auth || !db) {
    return null
  }

  if (isFeatureEnabled('REACT_APP_USE_EMULATORS')) {
    if (!emulators) {
      connectFirestoreEmulator(db, 'localhost', 8080)
      connectFunctionsEmulator(functions, 'localhost', 5001)
      if (isFeatureEnabled('REACT_APP_USE_STORAGE_EMULATOR')) {
        connectStorageEmulator(storage, 'localhost', 9199)
      }
      setEmulators(true)
    }
  }

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={db}>
        <StorageProvider sdk={storage}>
          <FunctionsProvider sdk={functions}>
            <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
          </FunctionsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}

function App() {
  const [theme, setTheme] = useState(
    isFeatureEnabled('REACT_APP_ENABLE_DARK_MODE')
      ? window.localStorage.getItem('theme') || 'light'
      : 'light',
  )

  const changeTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    window.localStorage.setItem('theme', newTheme)
  }

  function PageViewLogger() {
    const analytics = useAnalytics()
    const location = useLocation()

    // By passing `location.pathname` to the second argument of `useEffect`,
    // we only log on first render and when the `pathname` changes
    useEffect(() => {
      if (location) {
        logEvent(analytics, 'page_view', { page_location: location.pathname })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return null
  }

  const themeObject = theme === 'dark' ? DarkTheme : LightTheme

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>
        <RecoilRoot>
          <ThemeProvider theme={themeObject}>
            <ThemeGlobals isDark={themeObject.isDark} />
            {/* @ts-expect-error */}
            <HistoryRouter history={routerHistory}>
              <NiceModal.Provider>
                <AppRoutes />
                <PageViewLogger />
              </NiceModal.Provider>
            </HistoryRouter>
            {isFeatureEnabled('REACT_APP_ENABLE_DARK_MODE') && (
              <ThemeSelectorButton
                onClick={() => {
                  changeTheme()
                }}
              >
                {theme === 'dark' ? <MdLightMode /> : <MdDarkMode />}
              </ThemeSelectorButton>
            )}
          </ThemeProvider>
        </RecoilRoot>
      </FirebaseComponents>
    </FirebaseAppProvider>
  )
}
export default App

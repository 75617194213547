import { autocomplete, Render } from '@algolia/autocomplete-js'
import { BaseItem } from '@algolia/autocomplete-core'
import '@algolia/autocomplete-theme-classic'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'
import { SearchableTask } from '@super-software-inc/foundation'
import { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MdArrowForward } from 'react-icons/md'
import {
  NavLink,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { routerHistory } from '../../App'

// We fetch minimal data from Typesense to reduce the payload size
type MinimalSearchableTask = Pick<
  SearchableTask,
  'id' | 'title' | 'associationName' | 'companyName' | 'shortCodeId'
>

const GlobalSearch = ({ searchApiKey }: { searchApiKey: string }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const renderFn = render as Render

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render: renderFn },
      placeholder: 'Search for tasks',
      detachedMediaQuery: 'none', // prevents the search from being detached on mobile
      classNames: {
        form: `!border-2 !border-gray-300 !rounded-md text-sm h-9`,
        panel: `z-[200]`,
      },
      getSources: async ({ query }) => {
        const params = new URLSearchParams({
          q: query,
          query_by: 'title,shortCodeId',
          highlight_full_fields: 'title,shortCodeId',
          include_fields: 'shortCodeId,title,associationName,id,companyName',
        })
        const uri = `${process.env.REACT_APP_TYPESENSE_CLUSTER_URI}/collections/searchableTasks/documents/search?${params}`

        const response = await fetch(uri, {
          method: 'GET',
          headers: {
            'X-TYPESENSE-API-KEY': searchApiKey,
          },
        })

        const results =
          (await response.json()) as SearchResponse<MinimalSearchableTask>

        return [
          {
            sourceId: 'predictions',
            getItems() {
              return results.hits as unknown as BaseItem[]
            },
            getItemInputValue({ item }) {
              const doc = item.document as MinimalSearchableTask
              return doc.title
            },
            getItemUrl({ item }) {
              const doc = item.document as MinimalSearchableTask
              return `/tasks/${doc.id}`
            },
            templates: {
              item({ item }) {
                const doc = item.document as MinimalSearchableTask
                const highlights = item.highlights as {
                  matched_tokens: string[]
                }[]
                const firstMatchedToken =
                  highlights[0].matched_tokens.join(' ') || ''
                const titleToShow = doc.shortCodeId.includes(firstMatchedToken)
                  ? `${doc.shortCodeId}: ${doc.title}`
                  : doc.title
                const titleSplit = titleToShow.split(firstMatchedToken)

                return (
                  // @ts-expect-error
                  // The search panel is created outside of the existing router context
                  // so we have to wrap it in a router context to allow navigation
                  <HistoryRouter history={routerHistory}>
                    <NavLink to={`/tasks/${doc.id}`}>
                      <div className="">
                        <span className="text-sm">
                          {titleSplit[0]}
                          <span className="font-bold">{firstMatchedToken}</span>
                          {titleSplit[1]}
                        </span>
                        <span className="flex items-center text-xs text-gray-500 ml-2">
                          <MdArrowForward /> &nbsp;
                          {doc.associationName
                            ? doc.associationName
                            : doc.companyName}
                        </span>
                      </div>
                    </NavLink>
                  </HistoryRouter>
                )
              },
              noResults() {
                return 'No results found.'
              },
            },
          },
        ]
      },
    })

    return () => {
      search.destroy()
    }
  }, [searchApiKey])

  return (
    <div
      ref={containerRef}
      className={`
        grow
        mx-6
      `}
    />
  )
}

export default GlobalSearch

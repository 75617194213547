import { APIContact, TaskStatus } from '@super-software-inc/foundation'
import ContactAvatar from 'components/app/ContactAvatar'
import ContactsFilter from 'components/app/ContactsFilter'
import { Checked, Unselected } from 'components/app/Status'
import {
  FlexRow,
  GhostTextInput,
  MultilevelDivider,
  MultilevelDropdown,
  MultilevelItem,
  MultilevelSubmenu,
  Switch,
} from 'components/lib'
import { uniqBy } from 'lodash'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useMemo } from 'react'
import {
  MdAccountCircle,
  MdCached,
  MdCalendarToday,
  MdChevronRight,
  MdGroup,
  MdOutlineErrorOutline,
  MdOutlineKeyboardArrowDown,
  MdPersonAdd,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from 'react-icons/md'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { useTheme } from 'styled-components/macro'
import { TagsLookup } from 'types/Tags'
import { windowDimensionsAtom } from '../../AppRoutes'

const statusOptions = [
  { label: 'Open', value: TaskStatus.OPEN },
  { label: 'Pending', value: TaskStatus.PENDING },
  { label: 'On hold', value: TaskStatus.ON_HOLD },
  { label: 'Closed', value: TaskStatus.CLOSED },
  { label: 'Canceled', value: TaskStatus.CANCELLED },
]

export enum SortOption {
  Default = 'default',
  UnreadFirst = 'unread',
  DueDate = 'dueDate',
  Assignee = 'assignee',
  Category = 'categories',
  Newest = 'newest',
  Oldest = 'oldest',
  AtoZ = 'a-z',
  ZtoA = 'z-a',
  Title = 'title',
  CreatedBy = 'createdBy',
  UpdatedAt = 'updatedAt',
  Property = 'hoa',
  Locations = 'locations',
  Workspaces = 'workspaces',
  ModifiedBy = 'modifiedBy',
  Status = 'status',
}

export enum FilterType {
  Assignee = 'Assignee',
  CreatedBy = 'Created by',
  Subscriber = 'Subscriber',
  Category = 'Category',
  Date = 'Date', // For tasks, this is due date
  Recurring = 'Recurring',
  Urgent = 'Urgent',
  MyTasks = 'My tasks',
  SharedBy = 'Shared by',
  FileType = 'File type',
  UploadDate = 'Upload date',
  Status = 'Status',
  CreatedDate = 'Created date',
  ClosedDate = 'Closed date',
}

export enum DateFilterOption {
  Overdue = 'Overdue',
  Next7Days = 'Next 7 Days',
  Next14Days = 'Next 14 Days',
  Next30Days = 'Next 30 Days',
  Last30Days = 'Last 30 Days',
  MonthToDate = 'Month to Date',
  ThisYear = 'This Year',
  NoDueDate = 'No Due Date',
}

export enum FileTypeOption {
  All = 'All file types',
  Images = 'Images',
  Videos = 'Videos',
  Documents = 'Documents',
  Spreadsheets = 'Spreadsheets',
  Links = 'Links',
}

export enum UploadDateOption {
  AnyTime = 'Any time',
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last3Months = 'Last 3 months',
  Last12Months = 'Last 12 months',
  // TODO:
  // On = 'On...',
  // Before = 'Before...',
  // After = 'After...',
  // Range = 'Range...',
}

export enum MyTasksOption {
  Assigned = 'Assigned',
  Subscribed = 'Subscribed',
  Created = 'Created',
}

export type FilterValue = any

export interface FilterInterface {
  type: FilterType | undefined
  value: FilterValue | undefined
}

const TaskViewOptions = ({
  contacts,
  sort,
  filter,
  showCompleted = false,
  handleSortChange,
  handleFilterChange,
  handleShowCompletedToggle,
  handleResetViewOptions,
  isCalendarView,
  isFilesView,
}: {
  contacts: APIContact[]
  sort?: SortOption
  filter: FilterInterface
  showCompleted?: boolean
  handleSortChange?: (x: SortOption) => void
  handleFilterChange: (x: FilterInterface) => void
  handleShowCompletedToggle?: (checked: boolean) => void
  handleResetViewOptions: React.MouseEventHandler
  isCalendarView?: boolean
  isFilesView?: boolean
}) => {
  const theme = useTheme()

  const [searchParams] = useSearchParams()
  const workspace = searchParams.get('workspace')

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const location = useLocation()
  const navigate = useNavigate()

  const [dateOptionsSearchText, setDateOptionsSearchText] = React.useState('')
  const [categoryOptionsSearchText, setCategoryOptionsSearchText] =
    React.useState('')

  const [contactSearchText, setContactSearchText] = React.useState('')
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  const currentSort = useMemo(() => {
    if (sort) {
      const keys = Object.keys(SortOption)
      const values = Object.values(SortOption)

      const idx = values.indexOf(sort)
      const words = keys[idx].split(/(?=[A-Z])/)

      return words.join(' ')
    }

    return ''
  }, [sort])

  const currentFilter = useMemo(() => {
    if (filter.value) {
      const dateFilterValues: string[] = Object.values(DateFilterOption)

      if (
        filter.type === FilterType.Date &&
        dateFilterValues.includes(filter.value as DateFilterOption)
      ) {
        return dateFilterValues.find(
          x => x === (filter.value as DateFilterOption),
        )
      }

      if (
        [
          FilterType.Assignee,
          FilterType.CreatedBy,
          FilterType.Subscriber,
          FilterType.SharedBy,
        ].includes(filter.type as FilterType)
      ) {
        const contact = contacts.find(c => c.id === filter.value)

        const contactName = contact?.firstName
          ? `${contact.firstName}`
          : contact?.email || contact?.phone?.number

        switch (filter.type) {
          case FilterType.Assignee:
            return contactName ? `Assigned to ${contactName}` : 'Unassigned'
          case FilterType.CreatedBy:
            return contactName ? `Created by ${contactName}` : 'None'
          case FilterType.Subscriber:
            return contactName ? `Subscriber is ${contactName}` : 'None'
          case FilterType.SharedBy:
            return contactName ? `Shared by ${contactName}` : 'None'
          default:
            return 'None'
        }
      }

      if (filter.type === FilterType.MyTasks) {
        const values: string[] = Object.values(MyTasksOption)

        return values.find(x => x === filter.value)
      }

      if (filter.type === FilterType.Category) {
        if (Array.isArray(filter.value)) {
          if (filter.value.length > 1) {
            return `${filter.value.length} categories`
          }

          if (filter.value.length === 1) {
            return TagsLookup.get(filter.value[0])
          }
        }
      }

      if (filter.type === FilterType.Status) {
        const values: string[] = Object.values(TaskStatus)

        return `Status: ${values
          .filter(x => (filter.value as string[]).includes(x))
          .map(x => statusOptions.find(s => s.value === x)?.label)
          .join(', ')}`
      }

      if (filter.type === FilterType.CreatedDate) {
        return `Created date: ${filter.value}`
      }

      if (filter.type === FilterType.ClosedDate) {
        return `Closed date: ${filter.value}`
      }
    }

    if (filter.type === FilterType.Urgent) {
      return 'Urgent only'
    }

    if (filter.type === FilterType.Recurring) {
      return 'Re-occuring only'
    }

    if (filter.type === FilterType.FileType) {
      const values: string[] = Object.values(FileTypeOption)

      return values.find(x => x === filter.value) || 'None'
    }

    if (filter.type === FilterType.UploadDate) {
      const values: string[] = Object.values(UploadDateOption)

      return values.find(x => x === filter.value) || 'None'
    }

    return 'None'
  }, [filter, contacts])

  const dateOptionsMemo = useMemo(
    () =>
      Object.keys(DateFilterOption)
        .filter(x =>
          x.toLowerCase().includes(dateOptionsSearchText.toLowerCase()),
        )
        .map(x => ({
          label: x.split(/(?=[A-Z | 1 | 3 | 7])/).join(' '),
          value: DateFilterOption[x as keyof typeof DateFilterOption],
        })),
    [dateOptionsSearchText],
  )

  const categoryOptionsMemo = useMemo(
    () =>
      companyTaskCategories.filter(({ name }) =>
        name.toLowerCase().includes(categoryOptionsSearchText.toLowerCase()),
      ),
    [categoryOptionsSearchText, companyTaskCategories],
  )

  const contactsMemo = useMemo(() => {
    const filteredContacts = contacts.filter(
      c =>
        c.firstName?.toLowerCase().includes(contactSearchText.toLowerCase()) ||
        c.lastName?.toLowerCase().includes(contactSearchText.toLowerCase()) ||
        c.email?.toLowerCase().includes(contactSearchText.toLowerCase()) ||
        c.phone?.number.toLowerCase().includes(contactSearchText.toLowerCase()),
    )

    return filteredContacts
  }, [contacts, contactSearchText])

  const isDefault = useMemo(() => {
    if (isCalendarView) {
      return (
        ![FilterType.Urgent, FilterType.Recurring].includes(
          filter.type as FilterType,
        ) &&
        !filter.value &&
        !showCompleted
      )
    }

    if (isFilesView) {
      return (
        sort === SortOption.Newest &&
        ![
          FilterType.FileType,
          FilterType.UploadDate,
          FilterType.SharedBy,
        ].includes(filter.type as FilterType) &&
        !filter.value &&
        !showCompleted
      )
    }

    return (
      sort === SortOption.Default &&
      ![FilterType.Urgent, FilterType.Recurring].includes(
        filter.type as FilterType,
      ) &&
      !filter.value &&
      !showCompleted
    )
  }, [sort, filter, showCompleted, isCalendarView, isFilesView])

  const displayLayoutOption = useMemo(
    () => !location.pathname.includes('/announcements'),
    [location.pathname],
  )

  return (
    <MultilevelDropdown
      title={
        <FlexRow align="center" justify="center">
          {!windowDimensions.isMobile &&
            `View options: ${isDefault ? 'Default' : 'Custom'}`}
          <MdOutlineKeyboardArrowDown style={{ marginLeft: 4, fontSize: 18 }} />
        </FlexRow>
      }
      closeOnClick={false}
    >
      {displayLayoutOption && (
        <MultilevelItem>
          <FlexRow align="center" justify="space-between">
            <div>Layout</div>
            <FlexRow
              align="center"
              justify="space-between"
              style={{ color: theme.colors.text250 }}
            >
              {isCalendarView ? 'Calendar' : 'List'}{' '}
              <MdChevronRight style={{ marginLeft: '4px' }} />
            </FlexRow>
          </FlexRow>
          <MultilevelSubmenu>
            <MultilevelItem
              data-prevent-request-sheet-close
              onClick={() => {
                navigate(
                  isCalendarView
                    ? location.pathname.replace('/calendar', '')
                    : location.pathname,
                )
              }}
            >
              List
            </MultilevelItem>
            <MultilevelItem
              data-prevent-request-sheet-close
              onClick={() =>
                !isCalendarView &&
                navigate(
                  `${location.pathname}${
                    location.pathname.endsWith('/') ? '' : '/'
                  }calendar${workspace ? `?workspace=${workspace}` : ''}`,
                )
              }
            >
              Calendar
            </MultilevelItem>
          </MultilevelSubmenu>
        </MultilevelItem>
      )}
      {!isCalendarView && handleSortChange && (
        <MultilevelItem>
          <FlexRow align="center" justify="space-between">
            <div>Sort</div>
            <FlexRow
              align="center"
              justify="space-between"
              style={{ color: theme.colors.text250 }}
            >
              {currentSort} <MdChevronRight style={{ marginLeft: '4px' }} />
            </FlexRow>
          </FlexRow>
          <MultilevelSubmenu>
            {!isFilesView ? (
              <>
                {/* List view sort options */}
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.Default)}
                >
                  Last updated (default)
                </MultilevelItem>
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.UnreadFirst)}
                >
                  Unread first
                </MultilevelItem>
              </>
            ) : (
              <>
                {/* Files view sort options */}
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.Newest)}
                >
                  Newest (default)
                </MultilevelItem>
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.Oldest)}
                >
                  Oldest
                </MultilevelItem>
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.AtoZ)}
                >
                  A to Z
                </MultilevelItem>
                <MultilevelItem
                  onClick={() => handleSortChange(SortOption.ZtoA)}
                >
                  Z to A
                </MultilevelItem>
              </>
            )}
          </MultilevelSubmenu>
        </MultilevelItem>
      )}

      <MultilevelItem>
        <FlexRow align="center" justify="space-between">
          <div>Filter</div>
          <FlexRow
            align="center"
            justify="space-between"
            style={{ color: theme.colors.text250, marginLeft: 48 }}
          >
            {currentFilter} <MdChevronRight style={{ marginLeft: '4px' }} />
          </FlexRow>
        </FlexRow>

        <MultilevelSubmenu>
          {/* My tasks */}
          {!isFilesView && (
            <MultilevelItem
              onClick={() =>
                handleFilterChange({ type: FilterType.MyTasks, value: '' })
              }
            >
              <FlexRow align="center">
                <ContactAvatar
                  data={authenticatedUser.selectedContact}
                  style={{ marginRight: 8 }}
                />
                My tasks
              </FlexRow>

              <MultilevelSubmenu>
                <MultilevelItem
                  key={MyTasksOption.Assigned}
                  onClick={evt => {
                    handleFilterChange({
                      type: FilterType.MyTasks,
                      value: MyTasksOption.Assigned,
                    })

                    evt.stopPropagation()
                  }}
                >
                  Assigned
                </MultilevelItem>
                <MultilevelItem
                  key={MyTasksOption.Subscribed}
                  onClick={evt => {
                    handleFilterChange({
                      type: FilterType.MyTasks,
                      value: MyTasksOption.Subscribed,
                    })

                    evt.stopPropagation()
                  }}
                >
                  Subscribed
                </MultilevelItem>
                <MultilevelItem
                  key={MyTasksOption.Created}
                  onClick={evt => {
                    handleFilterChange({
                      type: FilterType.MyTasks,
                      value: MyTasksOption.Created,
                    })

                    evt.stopPropagation()
                  }}
                >
                  Created
                </MultilevelItem>
              </MultilevelSubmenu>
            </MultilevelItem>
          )}

          {/* Assignee */}
          {!isFilesView && (
            <ContactsFilter
              title={
                <>
                  <MdAccountCircle style={{ marginRight: 8, fontSize: 20 }} />{' '}
                  Assignee
                </>
              }
              contacts={contactsMemo}
              searchText={contactSearchText}
              handleSearch={evt =>
                setContactSearchText(evt.currentTarget.value)
              }
              filter={{ type: FilterType.Assignee, value: filter.value }}
              handleFilterChange={handleFilterChange}
              showUnassigned
            />
          )}

          {/* Created by */}
          {!isCalendarView && !isFilesView && (
            <>
              <ContactsFilter
                title={
                  <>
                    <MdPersonAdd
                      style={{ margin: '0 6px 0 2px', fontSize: 20 }}
                    />{' '}
                    Created by
                  </>
                }
                contacts={contactsMemo}
                searchText={contactSearchText}
                handleSearch={evt =>
                  setContactSearchText(evt.currentTarget.value)
                }
                filter={{ type: FilterType.CreatedBy, value: filter.value }}
                handleFilterChange={handleFilterChange}
              />

              <ContactsFilter
                title={
                  <>
                    <MdGroup style={{ marginRight: 8, fontSize: 20 }} />{' '}
                    Subscriber
                  </>
                }
                contacts={contactsMemo}
                searchText={contactSearchText}
                handleSearch={evt =>
                  setContactSearchText(evt.currentTarget.value)
                }
                filter={{ type: FilterType.Subscriber, value: filter.value }}
                handleFilterChange={handleFilterChange}
              />
            </>
          )}

          {/* Status */}
          {!isFilesView && (
            <MultilevelItem
              onClick={evt => {
                handleFilterChange({
                  type: FilterType.Status,
                  value: !Array.isArray(filter.value) ? [] : filter.value,
                })
                evt.stopPropagation()
              }}
            >
              <FlexRow align="center">
                <MdRadioButtonUnchecked
                  style={{ marginRight: 8, fontSize: 18 }}
                />{' '}
                Status
              </FlexRow>
              <MultilevelSubmenu>
                <div style={{ maxHeight: 360, overflow: 'scroll' }}>
                  {statusOptions.map(({ label, value }) => (
                    <MultilevelItem
                      key={value}
                      onClick={evt => {
                        if (filter.type !== FilterType.Status) {
                          handleFilterChange({
                            type: FilterType.Status,
                            value: [value],
                          })
                        } else if (filter.value?.includes(value)) {
                          handleFilterChange({
                            type: FilterType.Status,
                            value: filter.value.filter(
                              (x: string) => x !== value,
                            ),
                          })
                        } else {
                          handleFilterChange({
                            type: FilterType.Status,
                            value: [...(filter.value || []), value],
                          })
                        }

                        evt.stopPropagation()
                      }}
                    >
                      <FlexRow align="center">
                        <div style={{ marginRight: 8 }}>
                          {filter.value?.includes(value) ? (
                            <Checked />
                          ) : (
                            <Unselected />
                          )}
                        </div>
                        {label}
                      </FlexRow>
                    </MultilevelItem>
                  ))}
                </div>
              </MultilevelSubmenu>
            </MultilevelItem>
          )}

          {/* Category */}
          {!isFilesView && (
            <MultilevelItem
              onClick={evt => {
                handleFilterChange({
                  type: FilterType.Category,
                  value: !Array.isArray(filter.value) ? [] : filter.value,
                })
                evt.stopPropagation()
              }}
            >
              <FlexRow align="center">
                <MdRadioButtonChecked
                  style={{ marginRight: 8, fontSize: 18 }}
                />{' '}
                Category
              </FlexRow>
              <MultilevelSubmenu>
                <MultilevelItem>
                  <GhostTextInput
                    style={{ margin: 0, padding: 0 }}
                    placeholder="Category"
                    value={categoryOptionsSearchText}
                    onChange={x =>
                      setCategoryOptionsSearchText(x.currentTarget.value)
                    }
                  />
                </MultilevelItem>
                <MultilevelDivider />
                <div style={{ maxHeight: 360, overflow: 'scroll' }}>
                  {uniqBy(categoryOptionsMemo, 'name').map(({ name }) => (
                    <MultilevelItem
                      key={name}
                      onClick={evt => {
                        if (filter.type !== FilterType.Category) {
                          handleFilterChange({
                            type: FilterType.Category,
                            value: [name],
                          })
                        } else if (filter.value?.includes(name)) {
                          handleFilterChange({
                            type: FilterType.Category,
                            value: filter.value.filter(
                              (x: string) => x !== name,
                            ),
                          })
                        } else {
                          handleFilterChange({
                            type: FilterType.Category,
                            value: [...(filter.value || []), name],
                          })
                        }

                        evt.stopPropagation()
                      }}
                    >
                      <FlexRow align="center">
                        <div style={{ marginRight: 8 }}>
                          {filter.value?.includes(name) ? (
                            <Checked />
                          ) : (
                            <Unselected />
                          )}
                        </div>
                        {name}
                      </FlexRow>
                    </MultilevelItem>
                  ))}
                </div>
              </MultilevelSubmenu>
            </MultilevelItem>
          )}

          {/* Due Date */}
          {!isCalendarView && !isFilesView && (
            <MultilevelItem
              onClick={() =>
                handleFilterChange({ type: FilterType.Date, value: '' })
              }
            >
              <FlexRow align="center">
                <MdCalendarToday style={{ marginRight: 8, fontSize: 20 }} />{' '}
                Date
              </FlexRow>
              <MultilevelSubmenu>
                <MultilevelItem>
                  <GhostTextInput
                    style={{ margin: 0, padding: 0 }}
                    placeholder="Date"
                    value={dateOptionsSearchText}
                    onChange={x =>
                      setDateOptionsSearchText(x.currentTarget.value)
                    }
                  />
                </MultilevelItem>
                <MultilevelDivider />
                {dateOptionsMemo.map(({ label, value }) => (
                  <MultilevelItem
                    key={value}
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.Date,
                        value,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {label}
                  </MultilevelItem>
                ))}
              </MultilevelSubmenu>
            </MultilevelItem>
          )}

          {/* Recurring */}
          {!isFilesView && (
            <>
              <MultilevelItem
                onClick={() =>
                  handleFilterChange({ type: FilterType.Recurring, value: '' })
                }
              >
                <FlexRow align="center">
                  <MdCached style={{ marginRight: 8, fontSize: 20 }} />{' '}
                  Re-occurring only
                </FlexRow>
              </MultilevelItem>

              {/* Urgent */}
              <MultilevelItem
                onClick={() =>
                  handleFilterChange({ type: FilterType.Urgent, value: '' })
                }
              >
                <FlexRow align="center">
                  <MdOutlineErrorOutline
                    style={{ marginRight: 8, fontSize: 20 }}
                  />{' '}
                  Urgent only
                </FlexRow>
              </MultilevelItem>
            </>
          )}

          {/* Files view */}
          {isFilesView && (
            <>
              {/* Shared By */}
              <ContactsFilter
                title="Shared by"
                contacts={contactsMemo}
                searchText={contactSearchText}
                handleSearch={evt =>
                  setContactSearchText(evt.currentTarget.value)
                }
                filter={{ type: FilterType.SharedBy, value: filter.value }}
                handleFilterChange={handleFilterChange}
              />

              {/* Upload date */}
              <MultilevelItem
                onClick={() =>
                  handleFilterChange({ type: FilterType.UploadDate, value: '' })
                }
              >
                <FlexRow align="center">Date</FlexRow>
                <MultilevelSubmenu>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.UploadDate,
                        value: UploadDateOption.AnyTime,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {UploadDateOption.AnyTime}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.UploadDate,
                        value: UploadDateOption.Last7Days,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {UploadDateOption.Last7Days}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.UploadDate,
                        value: UploadDateOption.Last30Days,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {UploadDateOption.Last30Days}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.UploadDate,
                        value: UploadDateOption.Last3Months,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {UploadDateOption.Last3Months}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.UploadDate,
                        value: UploadDateOption.Last12Months,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {UploadDateOption.Last12Months}
                  </MultilevelItem>
                </MultilevelSubmenu>
              </MultilevelItem>

              {/* File type */}
              <MultilevelItem
                onClick={() =>
                  handleFilterChange({ type: FilterType.FileType, value: '' })
                }
              >
                <FlexRow align="center">File type</FlexRow>
                <MultilevelSubmenu>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.All,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.All}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.Images,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.Images}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.Videos,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.Videos}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.Documents,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.Documents}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.Spreadsheets,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.Spreadsheets}
                  </MultilevelItem>
                  <MultilevelItem
                    onClick={evt => {
                      handleFilterChange({
                        type: FilterType.FileType,
                        value: FileTypeOption.Links,
                      })

                      evt.stopPropagation()
                    }}
                  >
                    {FileTypeOption.Links}
                  </MultilevelItem>
                </MultilevelSubmenu>
              </MultilevelItem>
            </>
          )}
        </MultilevelSubmenu>
      </MultilevelItem>
      {!isFilesView && handleShowCompletedToggle && (
        <MultilevelItem>
          <FlexRow align="center" justify="space-between">
            <div style={{ marginRight: '8px' }}>Show closed tasks</div>
            <Switch
              checked={showCompleted}
              onChange={checked => handleShowCompletedToggle(checked)}
            />
          </FlexRow>
        </MultilevelItem>
      )}
      {!isDefault && (
        <>
          <MultilevelDivider />
          <MultilevelItem onClick={handleResetViewOptions}>
            Reset to default
          </MultilevelItem>
        </>
      )}
    </MultilevelDropdown>
  )
}

export default TaskViewOptions

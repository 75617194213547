import {
  ActionTargetType,
  ActionType,
  APIContact,
  APIRule,
  Category,
  ContactGroup,
  ContactReference,
  createContactReference,
  DynamicAction,
  getContactDisplayName,
  HaltAction,
  RuleInputContext,
  StaticAction,
  TaskStatus,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import {
  FlexColumn,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
} from 'components/lib'
import SearchableContactDropdown from 'components/lib/MultilevelDropdown/SearchableDropdown/SearchableContactDropdown'
import useContactsCache from 'hooks/useContactsCache'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { associationChoicesIdsSelector } from '../../../AppRoutes'
import { InputGroupLabel } from '../ContactForm/ProfileForm'
import TagSelector from '../Tags/TagSelector'
import TaskSubscriptions from '../Tasks/TaskSubscriptions'
import WorkspaceSelector from '../Tasks/WorkspaceSelector'
import TemplateSelector from './TemplateSelector'
import StatusSelector from '../StatusSelector'

const NewActionSelector = ({
  rule,
  onChange,
  conditional, // then or and
}: {
  rule: APIRule
  onChange: Function
  conditional: string
}) => {
  // get the current target keys of only static actions
  const currentTargetKeys = rule.actions.onMatch
    .filter(a => a.type === ActionType.STATIC)
    .map(a => (a as StaticAction).target.key)

  return (
    <div className="mb-2 w-[452px] h-16 p-3 bg-white rounded-lg shadow border border-zinc-200 flex-col justify-center items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="w-10 h-10 p-3 bg-gray-50 rounded border border-gray-400 justify-center items-center inline-flex">
          <div className="text-center text-gray-400 text-[10px] font-semibold font-['Inter'] uppercase leading-none tracking-wide">
            {conditional}
          </div>
        </div>
        <div className="self-stretch  items-center gap-4 inline-flex">
          <div className="justify-center items-center gap-1 flex">
            <MultilevelDropdown
              trigger={
                <div className="w-[380px] h-10 px-3 py-2 bg-white rounded border border-zinc-200 justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 py-0.5 justify-start items-center gap-2 flex">
                    <div className="grow shrink basis-0 h-5 justify-start items-center gap-1 flex">
                      <div className="grow shrink basis-0 text-gray-400 text-sm font-normal font-['Inter'] leading-tight">
                        Add condition
                      </div>
                    </div>
                    <div className="h-5 justify-start items-start flex">
                      <div className="w-5 h-5 relative">
                        <div className="w-5 h-5 left-0 top-0 absolute bg-zinc-300" />
                      </div>
                    </div>
                  </div>
                </div>
              }
              title="Add action"
            >
              {!currentTargetKeys.includes('assignee') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1, // TODO - this should be updated on save. It's not a reliable way to track order

                      type: ActionType.STATIC,
                      value: {
                        // contact reference
                      },

                      target: {
                        type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
                        context: RuleInputContext.STATE,
                        key: 'assignee',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Assign</p>
                </MultilevelItem>
              )}
              {!currentTargetKeys.includes('subscriptions') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1,

                      type: ActionType.STATIC,

                      value: [
                        // array of TaskSubscribers
                      ],
                      target: {
                        type: ActionTargetType.OBJECT_ARRAY_ADDITION, // for an array value
                        context: RuleInputContext.STATE,
                        key: 'subscriptions',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Subscribe</p>
                </MultilevelItem>
              )}
              {!currentTargetKeys.includes('taskCategories') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1,

                      type: ActionType.STATIC,

                      value: [
                        // array of categories
                      ],
                      target: {
                        type: ActionTargetType.OBJECT_ARRAY_ADDITION, // for an array value
                        context: RuleInputContext.STATE,
                        key: 'taskCategories',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Apply category</p>
                </MultilevelItem>
              )}
              {!currentTargetKeys.includes('workspace') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1,

                      type: ActionType.STATIC,
                      value: '',

                      target: {
                        type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
                        context: RuleInputContext.STATE,
                        key: 'workspace',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Apply workspace</p>
                </MultilevelItem>
              )}
              {!currentTargetKeys.includes('savedReplyId') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1,

                      type: ActionType.STATIC,
                      value: '',

                      target: {
                        type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
                        context: RuleInputContext.STATE,
                        key: 'savedReplyId',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Reply with</p>
                </MultilevelItem>
              )}
              {!currentTargetKeys.includes('status') && (
                <MultilevelItem
                  onClick={() => {
                    onChange({
                      title: '',
                      order: rule.actions.onMatch.length + 1,

                      type: ActionType.STATIC,
                      value: TaskStatus.OPEN,

                      target: {
                        type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
                        context: RuleInputContext.STATE,
                        key: 'status',
                      },
                    } as StaticAction)
                  }}
                >
                  <p style={{ width: 380 }}>Update status</p>
                </MultilevelItem>
              )}
            </MultilevelDropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

const staticActionSelector = ({
  conditional, // e.g. "if"
  contacts,
  onSelect,
  onMatchAction,
  onDelete,
  canDelete = true,
}: {
  conditional: string
  contacts: APIContact[]
  onSelect: Function
  onMatchAction: StaticAction
  onDelete: Function
  canDelete?: boolean
}) => {
  const targetKey = onMatchAction.target.key
  const leadingText =
    targetKey === 'subscriptions'
      ? 'Subscribe'
      : targetKey === 'assignee'
      ? 'Assign'
      : targetKey === 'savedReplyId'
      ? 'Reply with'
      : targetKey === 'status'
      ? 'Update status to'
      : 'Apply'

  return (
    <div className="mb-2 w-[452px] h-16 p-3 bg-white rounded-lg shadow border border-zinc-200 flex-col justify-center items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="w-10 h-10 p-3 bg-slate-50 rounded border border-violet-600 justify-center items-center flex">
          <div className="text-center text-violet-600 text-[9px] font-semibold font-['Inter'] uppercase leading-3 tracking-wide">
            {conditional}
          </div>
        </div>
        <div className="grow shrink text-sky-950 text-sm font-normal font-['Inter'] leading-tight">
          {leadingText}
        </div>
        {targetKey === 'assignee' && (
          <SearchableContactDropdown
            items={contacts}
            selectedItem={
              onMatchAction.value
                ? contacts.find(c => c.id === onMatchAction.value.contactId) ||
                  null
                : null
            }
            onChange={(contact: APIContact) =>
              onSelect(createContactReference(contact))
            }
            placeholder="Select contact"
          />
        )}
        {targetKey === 'subscriptions' && (
          <TaskSubscriptions
            subscribers={
              onMatchAction.value
                ? (onMatchAction.value as TaskSubscriber[])
                : ([] as TaskSubscriber[])
            }
            onChange={(subscribers: TaskSubscriber[]) => onSelect(subscribers)}
            contacts={contacts}
            associationId={null}
            isTaskSheet={false}
          />
        )}
        {targetKey === 'taskCategories' && (
          <TagSelector
            isTaskSheet={false}
            value={onMatchAction.value}
            onChange={(newValue: Category[] | undefined) => onSelect(newValue)}
          />
        )}
        {targetKey === 'workspace' && (
          <WorkspaceSelector
            isTaskSheet={false}
            isDisabled={false} // TODO - make this an optional prop on WorkspaceSelector
            value={onMatchAction.value}
            onChange={(newValue: string | undefined) => onSelect(newValue)}
          />
        )}
        {targetKey === 'savedReplyId' && (
          <TemplateSelector
            value={onMatchAction.value}
            onChange={(newValue: string) => onSelect(newValue)}
          />
        )}
        {targetKey === 'status' && (
          <StatusSelector
            isRule
            showLabel
            value={onMatchAction.value}
            onChange={({ newStatus }) => onSelect(newStatus)}
          />
        )}
        <div className="grow shrink basis-0 text-sky-950 text-sm font-normal font-['Inter'] leading-tight">
          {targetKey === 'taskCategories'
            ? 'category'
            : targetKey === 'workspace'
            ? 'workspace'
            : ''}
        </div>
        {canDelete && (
          <IconButton onClick={() => onDelete()}>
            <span className="material-symbols-rounded" style={{ fontSize: 16 }}>
              close
            </span>
          </IconButton>
        )}
      </div>
    </div>
  )
}

const ActionFormSection = ({
  rule,
  setRule,
}: {
  rule: APIRule
  setRule: Function
}) => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const associationIds = useRecoilValue(associationChoicesIdsSelector)

  const { data: contacts } = useContactsCache(
    authenticatedUser.selectedCompany.id,
    associationIds,
  )

  const ruleIsCreateTask = useMemo(
    () => rule.locked && rule.conditions.length === 0,
    [rule],
  )

  const availableContacts = useMemo(
    () =>
      contacts.length > 0
        ? contacts
            .filter(contact =>
              contact.propertyInfo.some(p =>
                p.groups.some(group =>
                  [
                    ContactGroup.Management,
                    ContactGroup.Board,
                    ContactGroup.Sponsors,
                    ContactGroup.Staff,
                    ContactGroup.Vendors,
                  ].includes(group),
                ),
              ),
            )
            .sort((a, b) =>
              getContactDisplayName(a) > getContactDisplayName(b) ? 1 : -1,
            )
        : [],
    [contacts],
  )

  return (
    <div style={{ marginBottom: 32 }}>
      <InputGroupLabel>Then...</InputGroupLabel>
      <FlexColumn>
        {rule.actions.onMatch.map((onMatchAction, index) => (
          <span
            key={
              onMatchAction.type === ActionType.STATIC
                ? (onMatchAction as StaticAction).target.key
                : (onMatchAction as DynamicAction).title
            }
          >
            {onMatchAction.type === ActionType.STATIC &&
              staticActionSelector({
                canDelete: !ruleIsCreateTask,
                conditional: index === 0 ? 'then' : 'and',
                onMatchAction,
                contacts: availableContacts,
                onSelect: (
                  newValue: ContactReference | TaskSubscriber | string,
                ) => {
                  setRule({
                    ...rule,
                    actions: {
                      ...rule.actions,
                      onMatch: rule.actions.onMatch.map((a, i) =>
                        i === index
                          ? {
                              ...a,
                              value: newValue,
                            }
                          : a,
                      ),
                    },
                  })
                },
                onDelete: () => {
                  setRule({
                    ...rule,
                    actions: {
                      ...rule.actions,
                      onMatch: rule.actions.onMatch.filter(
                        (_, i) => i !== index,
                      ),
                    },
                  })
                },
              })}
            {/* TODO - what does a dynamic action look like? */}
            {onMatchAction.type === ActionType.DYNAMIC && <div />}
          </span>
        ))}
        {/* currently only have 4 action types */}
        {!ruleIsCreateTask && rule.actions.onMatch.length < 6 && (
          <NewActionSelector
            rule={rule}
            onChange={(
              newMatchAction: StaticAction | DynamicAction | HaltAction,
            ) => {
              setRule({
                ...rule,
                actions: {
                  onMatch: [...rule.actions.onMatch, newMatchAction],
                },
              })
            }}
            conditional={rule.actions.onMatch.length === 0 ? 'then' : 'and'}
          />
        )}
      </FlexColumn>
    </div>
  )
}

export default ActionFormSection

import React from 'react'
import { useRecoilState } from 'recoil'
import { currentContactTabAtom } from 'state/atoms'
import { FlexRow, PageTitle } from 'components/lib'
import ContactsTable from 'pages/Association/ContactsTable'
import Tabs from 'components/lib/Navigation/Tabs'
import { PageHeader } from 'pages/Tasks'

const contactTabs = [
  {
    name: 'Property contacts',
  },
  {
    name: '',
  },
  {
    name: 'Management',
  },
  {
    name: 'Vendors',
  },
  {
    name: 'Auto-created',
  },
]
const Directory = () => {
  const [currentContactTab, setCurrentContactTab] = useRecoilState<number>(
    currentContactTabAtom,
  )

  return (
    <div>
      <PageHeader>
        <FlexRow
          align="center"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <PageTitle>Directory</PageTitle>
        </FlexRow>
      </PageHeader>
      <Tabs
        tabs={contactTabs}
        currentTab={currentContactTab}
        setCurrentTab={setCurrentContactTab}
        disableBottomBorder
        breakOnMobile
        breakOnTablet
        customStyle="top-14"
      />

      <ContactsTable corpFirst />
    </div>
  )
}

export default Directory

import React, { useMemo } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { AnnoucementFormData } from '@super-software-inc/foundation'
import { useTheme } from 'styled-components'

import { FlexRow, Modal, PrimaryButton, TextButton } from 'components/lib'
import { FormTitle } from './CreateAnnouncementModal'

const ConfirmAnnouncementModal = NiceModal.create(
  ({ draftAnnouncement }: { draftAnnouncement: AnnoucementFormData }) => {
    const modal = useModal()
    const theme = useTheme()

    const { associationIds, subscriptions, sendCopyToSelf } = draftAnnouncement

    const onlySendingToSelf = useMemo(
      () => subscriptions?.length === 0 && sendCopyToSelf,
      [subscriptions, sendCopyToSelf],
    )

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ width: 400, maxWidth: '80vw' }}>
          <FormTitle>Send announcement</FormTitle>
          <div
            style={{
              marginBottom: 24,
              lineHeight: '20px',
              color: theme.colors.text200,
            }}
          >
            Once you hit send, this announcement will be sent to{' '}
            {onlySendingToSelf ? 'you' : 'the'}{' '}
            <span style={{ fontWeight: 600 }}>
              {subscriptions?.length === 1
                ? '1 person'
                : `${subscriptions ? subscriptions.length : 0} people`}{' '}
              across {associationIds.length}
              {associationIds.length > 1 ? ' properties' : ' property'}
            </span>{' '}
            and will no longer be editable.
          </div>
          <FlexRow align="center">
            <TextButton
              type="button"
              onClick={() => {
                modal.reject('cancel')
                modal.hide()
              }}
              style={{ marginLeft: 'auto', marginRight: 4 }}
            >
              Continue editing
            </TextButton>
            <PrimaryButton
              onClick={() => {
                modal.resolve(draftAnnouncement)
                modal.hide()
              }}
            >
              Send announcement
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default ConfirmAnnouncementModal

import SidebarNavItem from 'components/app/SidebarNav/SidebarNavItem'
import { Dot } from 'components/lib'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { profileModalAtom } from 'state/atoms'
import { selectedAssociationChoicesAtom } from '../../../AppRoutes'

const CommunicationSection = () => {
  const resetProfileModal = useResetRecoilState(profileModalAtom)
  const navigate = useNavigate()

  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )

  const searchParams = window.location.search

  useEffect(() => {
    resetProfileModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociationChoices])

  return (
    <div style={{ paddingTop: 15 }}>
      <h3 className="ml-2 w-60 mb-2 text-gray-400 text-sm font-medium font-['Inter'] leading-tight">
        Communications
      </h3>
      <div style={{ marginLeft: 20 }}>
        <SidebarNavItem
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              workspaces
            </span>
          }
          label="All tasks"
          onClick={() => {
            navigate(
              `/tasks${
                window.location.pathname.includes('calendar') ? '/calendar' : ''
              }`,
            )
          }}
          isActive={
            window.location.pathname.includes('/tasks') && searchParams === ''
          }
        />
        <SidebarNavItem
          icon={
            <div style={{ marginLeft: -5, marginRight: -5 }}>
              <Dot color="#8A94A6" />
            </div>
          }
          label="General"
          onClick={() => {
            navigate(
              `/tasks${
                window.location.pathname.includes('calendar') ? '/calendar' : ''
              }?workspace=null`,
            )
          }}
          isActive={
            window.location.pathname.includes('/tasks') &&
            searchParams === '?workspace=null'
          }
        />
        <SidebarNavItem
          icon={
            <div style={{ marginLeft: -5, marginRight: -5 }}>
              <Dot color="#EB7738" />
            </div>
          }
          label="Compliance"
          onClick={() => {
            navigate(
              `/tasks${
                window.location.pathname.includes('calendar') ? '/calendar' : ''
              }?workspace=compliance`,
            )
          }}
          isActive={
            window.location.pathname.includes('/tasks') &&
            searchParams === '?workspace=compliance'
          }
        />
        <SidebarNavItem
          icon={
            <div style={{ marginLeft: -5, marginRight: -5 }}>
              <Dot color="#0B96EC" />
            </div>
          }
          label="Work orders"
          onClick={() => {
            navigate(
              `/tasks${
                window.location.pathname.includes('calendar') ? '/calendar' : ''
              }?workspace=work-orders`,
            )
          }}
          isActive={
            window.location.pathname.includes('/tasks') &&
            searchParams === '?workspace=work-orders'
          }
        />
        <SidebarNavItem
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              volume_up
            </span>
          }
          label="All announcements"
          onClick={() => {
            navigate('/announcements')
          }}
          isActive={
            window.location.pathname.includes('/announcements') &&
            searchParams === ''
          }
          disabled={selectedAssociationChoices.length === 0}
        />
        <SidebarNavItem
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              package
            </span>
          }
          label="Deliveries"
          onClick={() => {
            navigate('/deliveries')
          }}
          isActive={window.location.pathname.includes('/deliveries')}
        />
      </div>
    </div>
  )
}

export default CommunicationSection

import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import { getContacts } from 'api/contacts/getContacts'
import { isEqual, uniq, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { atom, useRecoilState } from 'recoil'

export const contactsCacheAtom = atom<APIContact[]>({
  key: 'contactsCache',
  default: [],
})

const idCacheAtom = atom<string[]>({
  key: 'idCache',
  default: [],
})

const filterByGroups = (
  contacts: APIContact[],
  associationIds: string[],
  groups?: ContactGroup[],
) => {
  if (groups?.length) {
    return contacts.filter(c => {
      const relevantPropertyInfo = c.propertyInfo.filter(p =>
        associationIds.includes(p.associationId),
      )

      return relevantPropertyInfo.some(p =>
        groups.some(g => p.groups.includes(g)),
      )
    })
  }

  return contacts
}

function useContactsCache(
  companyId: string,
  associationIds: (string | null)[],
  groups?: ContactGroup[],
) {
  const [cachedContacts, setCachedContacts] = useRecoilState(contactsCacheAtom)
  const [cachedIds, setCachedIds] = useRecoilState(idCacheAtom)

  const [data, setData] = useState<APIContact[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  const prevCompanyId = usePrevious(companyId)
  const prevAssociationIds = usePrevious(associationIds)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Remove any null (no property) values from the associationIds array
    const filteredAssociationIds = associationIds.filter(
      id => id !== null,
    ) as string[]

    if (
      companyId &&
      filteredAssociationIds.length > 0 &&
      (prevCompanyId !== companyId ||
        !isEqual(prevAssociationIds, filteredAssociationIds))
    ) {
      setLoading(true)

      // If the IDs in the request already all exist in the cache, use the cache
      if (filteredAssociationIds.every(id => cachedIds.includes(id))) {
        const relevantContacts = cachedContacts.filter(c =>
          c.associationIds.some(id => filteredAssociationIds.includes(id)),
        )

        setData(
          filterByGroups(relevantContacts, filteredAssociationIds, groups),
        )

        setLoading(false)
      }
      // Otherwise, make the request and cache the results
      else {
        try {
          getContacts(companyId, filteredAssociationIds).then(contacts => {
            setData(filterByGroups(contacts, filteredAssociationIds, groups))
            setLoading(false)
            setCachedContacts(uniqBy([...cachedContacts, ...contacts], 'id'))
            setCachedIds(uniq([...cachedIds, ...filteredAssociationIds]))
          })
        } catch (error) {
          setError(true)
          setLoading(false)
        }
      }
    }
  }, [
    companyId,
    associationIds,
    groups,
    prevCompanyId,
    prevAssociationIds,
    cachedContacts,
    cachedIds,
    setCachedContacts,
    setCachedIds,
  ])

  return { data, isLoading, isError }
}

export default useContactsCache

import React from 'react'
import { Announcement } from '@super-software-inc/foundation'
import { format } from 'date-fns'

import { FlexRow, SubtleLink, Tooltip } from 'components/lib'

import {
  humanizeActivityRelativeTime,
  Dot,
  EventText,
} from '../Tasks/TaskActivity'

interface TaskActivityProps {
  announcement: any
}

// Work out by looking at the announcement subscribers whether we sent
// this announcement via email, SMS, phone, any two, or all three
const getDeliveryMode = (announcement: Announcement) => {
  const deliveryModes = []
  if (
    announcement.subscriptions?.some(subscription =>
      subscription?.deliveryLog?.some(
        deliveryLog => deliveryLog.contactMethod === 'email',
      ),
    )
  ) {
    deliveryModes.push('email')
  }
  if (
    announcement.subscriptions?.some(subscription =>
      subscription?.deliveryLog?.some(
        deliveryLog => deliveryLog.contactMethod === 'phone',
      ),
    )
  ) {
    deliveryModes.push('SMS')
  }
  if (
    announcement.subscriptions?.some(subscription =>
      subscription?.deliveryLog?.some(
        deliveryLog => deliveryLog.contactMethod === 'voice',
      ),
    )
  ) {
    deliveryModes.push('phone')
  }
  if (deliveryModes.length === 0) {
    return 'unknown delivery method'
  }
  if (deliveryModes.length === 1) {
    return deliveryModes[0]
  }
  if (deliveryModes.length === 2) {
    return `${deliveryModes[0]} and ${deliveryModes[1]}`
  }
  return 'email, SMS, and phone'
}

const AnnouncementActivity = ({ announcement }: TaskActivityProps) => (
  <FlexRow align="center" style={{ marginBottom: '1rem' }}>
    <Dot />
    <EventText>
      {announcement.createdBy && (
        <span>
          {announcement.createdBy.firstName &&
          announcement.createdBy.firstName.length > 0
            ? `${announcement.createdBy.firstName} ${
                announcement.createdBy.lastName || ''
              }`
            : announcement.createdBy.email ||
              announcement.createdBy.phone?.number}
        </span>
      )}{' '}
      {`sent this announcement via ${getDeliveryMode(announcement)}`}
      <Tooltip
        overlay={<span>{format(announcement.createdAt.toDate(), 'PPpp')}</span>}
      >
        <SubtleLink style={{ marginLeft: 8 }}>
          {humanizeActivityRelativeTime(announcement.createdAt.toDate())}
        </SubtleLink>
      </Tooltip>
    </EventText>
  </FlexRow>
)

export default AnnouncementActivity

import { APIAnnouncement } from '@super-software-inc/foundation'
import placeholder from 'assets/images/loading-placeholder.gif'
import AnnouncementViewOptions, {
  SortOption,
} from 'components/app/Announcements/AnnouncementViewOptions'
import { noPropertySelectedAtom } from 'components/app/SidebarNav/AssociationDropdown'
import {
  LoadingIndicator,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from 'components/lib'
import { httpsCallable } from 'firebase/functions'
import { orderBy } from 'lodash'
import {
  SectionHeader,
  TasksContent,
  ViewOptionsContainer,
} from 'pages/Tasks/VirtualizedTaskListView'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMountedState, usePrevious } from 'react-use'
import { useFunctions } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedAssociationChoicesAtom,
  windowDimensionsAtom,
} from '../../AppRoutes'
import {
  authenticatedUserAtom,
  showAnnouncementFormAtom,
} from '../../state/atoms'
import AnnouncementRow from './AnnouncementRow'
import { showAnnouncementDrawerAtom } from './index'

interface AnncListViewProps {
  onAnncSelected: (annc: APIAnnouncement) => void
  onAnncMissing: (anncId: string) => void
  onRequestSheetClose: () => void
}

const AnnouncementsView = ({
  onAnncSelected,
  onAnncMissing,
  onRequestSheetClose,
}: AnncListViewProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const { selectedContact: authenticatedContact } = useRecoilValue(
    authenticatedUserAtom,
  )
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const prevAssociations = usePrevious(selectedAssociationChoices)
  const [showAnnouncementDrawer] = useRecoilState(showAnnouncementDrawerAtom)
  const [showAnncForm, setShowAnnouncementForm] = useRecoilState(
    showAnnouncementFormAtom,
  )
  const prevShowAnncForm = usePrevious(showAnnouncementFormAtom)
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const noPropertySelected = useRecoilValue(noPropertySelectedAtom)

  const [sortKey, setSortKey] = useState<SortOption>(SortOption.CreatedAt)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const associationsMap = useMemo(() => {
    const map = new Map()

    selectedAssociationChoices?.forEach(a => map.set(a.id, a))

    return map
  }, [selectedAssociationChoices])
  // If a user can create an announcement in any of their associations, they can
  // view the create modal. We filter out which associations they can create in
  // in the modal itself.
  const canCreateAnnouncement = useMemo(
    () =>
      authenticatedContact &&
      authenticatedContact.propertyInfo.some(p => p.acl.announcements.create),

    [authenticatedContact],
  )

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  // TODO - store this in the associations atom
  const associationsIdArray = useMemo(
    () => selectedAssociationChoices.map(a => a.id),
    [selectedAssociationChoices],
  )

  const functions = useFunctions()
  const getAnnouncements = httpsCallable(functions, 'getAnnouncements')
  const [apiAnnouncements, setApiAnnouncements] = useState<APIAnnouncement[]>(
    [],
  )
  const [loading, setLoading] = useState<boolean>(false)

  const isMounted = useMountedState()
  function getAnnouncementsFromApi() {
    if (selectedAssociationChoices.length === 0) {
      setApiAnnouncements([] as APIAnnouncement[])
      setLoading(false)
      return
    }
    functions.region = 'us-east1'
    getAnnouncements({
      associationIds: associationsIdArray,
    }).then(result => {
      if (isMounted()) {
        const { data } = result
        setApiAnnouncements(data as APIAnnouncement[])
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    // only show loader if changing associations
    if (prevAssociations !== selectedAssociationChoices) {
      setLoading(true)
    }

    // don't call tasks api if the sheet is open
    if (!showAnnouncementDrawer && !showAnncForm) {
      getAnnouncementsFromApi()
      // if the user is on the annc page, check for new annc every 30 seconds.
      // TODO - stop timer after 5-10 minutes of running.
      const timer = window.setInterval(() => {
        if (!showAnnouncementDrawer && !showAnncForm) {
          getAnnouncementsFromApi()
        }
      }, 30000)

      return () => {
        window.clearInterval(timer)
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociationChoices, showAnnouncementDrawer, noPropertySelected])

  // if the new annc form closes, update annclist to get new annc.
  useEffect(() => {
    if (prevShowAnncForm && !showAnncForm) {
      // set a timeout to allow time for the new annc to save to db.
      // otherwise it won't show up for 30 seconds.
      setTimeout(() => {
        getAnnouncementsFromApi()
      }, 1000)
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnncForm])

  const announcements: APIAnnouncement[] = useMemo(() => {
    if (
      !apiAnnouncements ||
      loading ||
      (noPropertySelected && !associationsIdArray.length)
    ) {
      setLoading(false)
      return []
    }
    let order = sortOrder
    if (sortKey === SortOption.Default || sortKey === SortOption.UnreadFirst) {
      order = 'desc'
    }

    // Handle unread manually:
    if (sortKey === SortOption.UnreadFirst) {
      return [
        ...apiAnnouncements.filter(t => !t.isRead),
        ...apiAnnouncements.filter(t => t.isRead),
      ]
    }

    // Handle announcement name:
    if (sortKey === SortOption.Name) {
      const ascendingAnnouncementsSortedByName = apiAnnouncements.sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      return order === 'asc'
        ? ascendingAnnouncementsSortedByName
        : ascendingAnnouncementsSortedByName.reverse()
    }

    // Handle HOA by association name:
    if (sortKey === SortOption.Property) {
      // We have the associationId, need to translate that to the association name
      const ascendingAnnouncementsSortedByHOA = apiAnnouncements.sort(
        (a, b) => {
          const aAssociation = associationsMap.get(a.associationId)
          const bAssociation = associationsMap.get(b.associationId)
          if (!aAssociation || !bAssociation) {
            return 0
          }
          return aAssociation.name.localeCompare(bAssociation.name)
        },
      )

      return order === 'asc'
        ? ascendingAnnouncementsSortedByHOA
        : ascendingAnnouncementsSortedByHOA.reverse()
    }

    // Handle createdBy using the full name:
    if (sortKey === SortOption.CreatedBy) {
      const ascendingAnnouncementsSortedByCreatedBy = apiAnnouncements.sort(
        (a, b) => {
          const aCreatedBy = `${a.createdBy?.firstName} ${a.createdBy?.lastName}`
          const bCreatedBy = `${b.createdBy?.firstName} ${b.createdBy?.lastName}`
          return aCreatedBy.localeCompare(bCreatedBy)
        },
      )

      return order === 'asc'
        ? ascendingAnnouncementsSortedByCreatedBy
        : ascendingAnnouncementsSortedByCreatedBy.reverse()
    }

    // Handle createdAt (also default case):
    if (sortKey === SortOption.CreatedAt || sortKey === SortOption.Default) {
      const ascendingAnnouncementsSortedByCreatedAt = apiAnnouncements.sort(
        (a, b) => a.createdAt - b.createdAt,
      )

      return order === 'asc'
        ? ascendingAnnouncementsSortedByCreatedAt
        : ascendingAnnouncementsSortedByCreatedAt.reverse()
    }

    const sortedAnnc = orderBy(apiAnnouncements, sortKey, order)

    return sortedAnnc
  }, [
    apiAnnouncements,
    sortKey,
    loading,
    sortOrder,
    associationsMap,
    noPropertySelected,
    associationsIdArray,
  ])

  useEffect(() => {
    if (!params?.announcementId || !apiAnnouncements?.length) {
      return
    }

    let announcement = apiAnnouncements.find(
      t => t.id === params.announcementId,
    )

    if (announcement) {
      onAnncSelected(announcement)
      return
    }

    // Attempt to cross-check against all tasks just in case:
    getAnnouncements().then(result => {
      if (isMounted()) {
        const { data } = result
        announcement = (data as APIAnnouncement[]).find(
          a => a.id === params.announcementId,
        )

        if (announcement) {
          onAnncSelected(announcement)
        } else {
          onAnncMissing(params.announcementId!)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.announcementId, apiAnnouncements])

  const handleResetViewOptions = () => {
    setSortKey(SortOption.Default)
  }

  // TODO - vika will evenutally make a standard lottie table loader that can be
  // it's own component for all tables.
  const [spinnerTimeout, setSpinnerTimeout] = useState(false)
  const spinnerTimer = useRef<any>(null)
  useEffect(() => {
    clearTimeout(spinnerTimer.current)
    if (loading) {
      setSpinnerTimeout(false)

      spinnerTimer.current = setTimeout(() => {
        setSpinnerTimeout(true)
      }, 1500)
    }
    return () => {
      clearTimeout(spinnerTimer.current)
    }
  }, [loading, spinnerTimer])

  if (loading) {
    if (!spinnerTimeout) {
      return (
        <div style={{ marginTop: 200 }}>
          <LoadingIndicator />
        </div>
      )
    }
    return (
      <TasksContent>
        <SectionHeader>
          <p style={{ position: 'absolute', bottom: 0, left: 0 }}>
            LOADING ANNOUNCEMENTS
          </p>
        </SectionHeader>
        <img
          src={placeholder}
          alt="loading placeholder"
          style={{
            width: '95%',
            height: windowDimensions.isMobile
              ? windowDimensions.width * 2
              : windowDimensions.width * 0.5,
            marginTop: windowDimensions.isMobile
              ? -windowDimensions.width * 0.6
              : -windowDimensions.width * 0.15,
            marginLeft: windowDimensions.isMobile ? 5 : 0,
          }}
        />
      </TasksContent>
    )
  }
  return (
    <TasksContent onClick={() => onRequestSheetClose()}>
      <ViewOptionsContainer>
        <AnnouncementViewOptions
          sort={sortKey}
          handleSortChange={(key: SortOption) => setSortKey(key)}
          handleResetViewOptions={handleResetViewOptions}
        />
      </ViewOptionsContainer>
      <div style={{ height: 30 }} />
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              active={sortKey === SortOption.Name}
              sortOrder={sortOrder}
              onClick={() => handleSort(SortOption.Name)}
              style={{
                textAlign: 'left',
                width: '70%',
                paddingLeft: '5px',
                paddingTop: '18px',
              }}
            >
              ANNOUNCEMENTS ({announcements.length})
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === SortOption.CreatedBy}
              sortOrder={sortOrder}
              onClick={() => handleSort(SortOption.CreatedBy)}
              style={{ textAlign: 'left' }}
            >
              CREATED BY
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === SortOption.CreatedAt}
              sortOrder={sortOrder}
              onClick={() => handleSort(SortOption.CreatedAt)}
              style={{ textAlign: 'left' }}
            >
              CREATED AT
            </SortableTableHeaderCell>
            {selectedAssociationChoices.length > 1 && (
              <SortableTableHeaderCell
                active={sortKey === SortOption.Property}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Property)}
                style={{ textAlign: 'left' }}
              >
                Property
              </SortableTableHeaderCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {announcements &&
            announcements.length > 0 &&
            announcements.map(annc => (
              <React.Fragment key={annc.id}>
                <AnnouncementRow
                  annc={annc}
                  associationName={
                    selectedAssociationChoices.length !== 1 &&
                    associationsMap.get(annc.associationId)?.name
                  }
                  onClick={() => {
                    navigate(`/announcements/${annc.id}`)
                  }}
                />
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      {announcements && announcements.length === 0 && (
        <TableRow>
          <TableHeaderCell
            colSpan={windowDimensions.isMobile ? 4 : 8}
            style={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 500,
            }}
          >
            No announcements to display.{' '}
            {canCreateAnnouncement && (
              <>
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setShowAnnouncementForm(true)}
                >
                  Add your first announcement
                </span>{' '}
                to get started.
              </>
            )}
          </TableHeaderCell>
        </TableRow>
      )}
    </TasksContent>
  )
}

export default AnnouncementsView

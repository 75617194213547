import { APIContact } from '@super-software-inc/foundation'
import findCompanyById from 'api/companies'
import { useEffect, useState } from 'react'

/**
 * Util hook to get contacts with company data. Used with contacts table (management + vendors)
 * @param contacts Array of contacts
 * @returns reactive array of contacts appended with company data
 */
const useContactCompanies = (contacts: APIContact[]) => {
  const [contactWithCompanies, setContactWithCompanies] = useState<
    APIContact[]
  >([])

  useEffect(() => {
    const fetchCompanyNames = async () => {
      const getCompanies = contacts.map(async contact => {
        if (contact.businessName) {
          return contact
        }

        const data: APIContact = {
          ...contact,
          businessName: (await findCompanyById(contact.companyId))?.name,
        }
        return data
      })

      setContactWithCompanies(await Promise.all(getCompanies))
    }
    fetchCompanyNames()
  }, [contacts])

  return { contactWithCompanies }
}

export default useContactCompanies

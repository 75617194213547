import {
  APIAssociation,
  APIComment,
  APICompany,
  APIContact,
  APISchedule,
  APITask,
  Category,
  Comment,
  ContactSecrets,
  createContactReference,
  SearchableTask,
  SubTaskReference,
  Task,
  TaskStatus,
  TaskStatusChangeAction,
  TaskSubscriber,
  Workspace,
} from '@super-software-inc/foundation'
import { toastSuccess } from 'components/lib/Toast'
import { deleteField, serverTimestamp } from 'firebase/firestore'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'
import {
  getSubscribersToastMessage,
  turnMentionsIntoSubscriptions,
} from 'lib/mentions'
import { sortBy, uniqBy } from 'lodash'
import { SearchResponse } from 'typesense/lib/Typesense/Documents'

export const updateTask = async (
  companyId: string,
  associationId: string | null,
  taskId: string,
  task: Partial<APITask>,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const updateTaskCallable: HttpsCallable<
    {
      companyId: string
      associationId: string | null
      taskId: string
      task: Partial<APITask>
    },
    APITask
  > = httpsCallable(functions, 'updateTask')

  const { data } = await updateTaskCallable({
    companyId,
    associationId,
    taskId,
    task,
  })

  return data
}

export const createTask = async (
  companyId: string,
  associationId: string,
  task: Partial<Task>,
  schedule: APISchedule | null,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  // Create a task using Task but get back an APITask
  const createTaskCallable: HttpsCallable<
    {
      companyId: string
      associationId: string
      task: Partial<Task>
      schedule: APISchedule | null
    },
    APITask
  > = httpsCallable(functions, 'createTask')

  const { data } = await createTaskCallable({
    companyId,
    associationId,
    task,
    schedule,
  })

  return data
}

export const changeTaskStatus = async ({
  companyId,
  contact,
  task,
  newStatus,
  action,
}: {
  companyId: string
  contact: APIContact
  task: APITask | SearchableTask
  newStatus: TaskStatus
  action: TaskStatusChangeAction
}) => {
  const contactReference = createContactReference(
    contact,
    task.associationId || undefined,
  )
  return updateTask(companyId, task.associationId, task.id, {
    status: newStatus,
    modifiedBy: contactReference,

    // Mark when task was closed, or delete the timstamp if it was reopened
    closedAt:
      newStatus === TaskStatus.CLOSED ? serverTimestamp() : deleteField(),
    ...(action && { statusChangeAction: action }),
  })
}

export const changeTaskStatusWithComment = async ({
  companyId,
  contact,
  task,
  newStatus,
  values,
  withMentions = false,
  action,
}: {
  companyId: string
  contact: APIContact
  task: SearchableTask
  newStatus: TaskStatus
  values?: Omit<Comment, 'author'>
  withMentions?: boolean
  action?: TaskStatusChangeAction
}) => {
  const contactReference = createContactReference(
    contact,
    task.associationId || undefined,
  )

  if (values) {
    // Only send a single comment with the update request
    const comments: APIComment[] = [
      {
        ...values,
        author: contactReference,
        createdAt: Number(new Date()),
        read: true,
      },
    ]

    if (withMentions) {
      const { mentions, subscriptions: mentionSubscriptions } =
        await turnMentionsIntoSubscriptions(values.text, task.associationId)

      if (mentions && mentions.length) {
        toastSuccess(getSubscribersToastMessage(mentions))

        const subscriptions: TaskSubscriber[] = uniqBy(
          [
            ...(task.subscriptions || []),
            {
              ...contactReference,
              preferences: [
                contactReference.email && contactReference.email.length > 0
                  ? 'email'
                  : 'phone',
              ],
            },
            ...(mentionSubscriptions || []),
          ],
          'contactId',
        )

        // Update the task w/ the new status, action, comment, and
        // subscriptions from @ mentions
        return updateTask(companyId, task.associationId, task.id, {
          status: newStatus,
          ...(action && { statusChangeAction: action }),
          comments,
          subscriptions,
        })
      }
    }

    // Update the task w/ the new status, action, and comment
    return updateTask(companyId, task.associationId, task.id, {
      status: newStatus,
      ...(action && { statusChangeAction: action }),
      comments,
    })
  }

  // Only update the task w/ the new status and action
  return updateTask(companyId, task.associationId, task.id, {
    status: newStatus,
    ...(action && { statusChangeAction: action }),
  })
}

interface GetTasksFromTypesenseRes {
  tasks: SearchableTask[]
  totalTasks: number
}

export interface GetTasksFromTypesenseProps {
  page?: number
  pageSize?: number

  associationIds?: string[]
  status?: TaskStatus[]
  taskCategories?: string[]
  isUrgent?: boolean
  workspaces?: (Workspace | null)[]
  isWorkspaceTask?: boolean

  dueDateValueBefore?: string
  dueDateValueAfter?: string
  hasDueDate?: boolean
  isRecurring?: boolean

  createdByContactIds?: string[]
  assignedToContactIds?: string[]
  subscribedContactIds?: string[]

  isSubTask?: boolean

  unread?: string[]

  sortByStr?: string

  includeInternalComments?: boolean
}

export async function getTasksFromTypesense(
  {
    page,
    pageSize = 40,
    associationIds,
    status,
    taskCategories,
    isUrgent,
    workspaces,
    dueDateValueBefore,
    dueDateValueAfter,
    hasDueDate,
    isRecurring,
    isWorkspaceTask,
    createdByContactIds,
    assignedToContactIds,
    subscribedContactIds,
    isSubTask,
    unread,
    sortByStr,
    includeInternalComments,
  }: GetTasksFromTypesenseProps,
  contactSecrets: ContactSecrets | undefined = undefined,
): Promise<GetTasksFromTypesenseRes> {
  let filterBy = ''

  if (associationIds) {
    if (associationIds.includes('null')) {
      if (associationIds.length > 1) {
        filterBy += `associationId: [${associationIds
          .filter(a => a !== 'null')
          .join()}] || isPropertyTask: false`
      } else {
        filterBy = `isPropertyTask: false`
      }
    } else {
      // Otherwise only query by associationIds and screen out any no-prop tasks:
      filterBy = `associationId: [${associationIds.join()}] && isPropertyTask: true`
    }
  }

  if (status?.length) {
    filterBy += ` && status: [${status.map(s => `${s}`).join()}]`
  }

  if (taskCategories?.length) {
    filterBy += ` && taskCategories: [${taskCategories.join()}]`
  }

  if (isUrgent !== undefined) {
    filterBy += ` && isUrgent:${isUrgent}`
  }

  if (workspaces?.length) {
    filterBy += ` && workspace: [${workspaces.map(w => `${w}`).join()}]`
  }

  if (dueDateValueBefore) {
    filterBy += ` && dueDateValue:<${dueDateValueBefore}`
  }

  if (dueDateValueAfter) {
    filterBy += ` && dueDateValue:>${dueDateValueAfter}`
  }

  if (hasDueDate !== undefined) {
    filterBy += ` && hasDueDate:${hasDueDate}`
  }

  if (isRecurring !== undefined) {
    filterBy += ` && isRecurring:${isRecurring}`
  }

  if (isWorkspaceTask !== undefined) {
    filterBy += ` && isWorkspaceTask:${isWorkspaceTask}`
  }

  if (createdByContactIds?.length) {
    filterBy += ` && createdBy.contactId: [${createdByContactIds.join()}]`
  }

  if (assignedToContactIds?.length) {
    if (assignedToContactIds.includes('null')) {
      if (assignedToContactIds.length > 1) {
        filterBy += `&& (assignee.contactId: [${assignedToContactIds.join()}] || isAssigned: false)`
      } else {
        filterBy += `&& isAssigned: false`
      }
    } else {
      filterBy += ` && assignee.contactId: [${assignedToContactIds.join()}]`
    }
  }

  if (subscribedContactIds?.length) {
    subscribedContactIds.forEach(id => {
      filterBy += ` && subscriptions.contactId: ${id}`
    })
  }

  if (isSubTask !== undefined) {
    filterBy += ` && isSubTask:${isSubTask}`
  }

  if (unread?.length) {
    unread.forEach(id => {
      filterBy += ` && read:!=[${id}]`
    })
  }

  const includeFields = [
    'id',
    'title',
    'description100',
    'associationId',
    'shortCodeId',
    'status',
    'read',
    'isUrgent',
    'parentTaskTitle',
    'subTasks',
    'updatedAt',
    'modifiedBy',
    'workspace',
    'taskCategories',
    'dueDate',
    'schedule',
    'assignee',
    'associationName',
    'subTaskCount',
    'externalCommentsCount',
    'locationsString',
  ]

  if (includeInternalComments) {
    includeFields.push('internalCommentsCount')
  }

  const params = new URLSearchParams({
    q: '*', // get all docs so we can filter on them
    per_page: pageSize.toString(),
    include_fields: includeFields.join(','),
  })

  if (page) {
    params.append('page', page.toString())
  }

  if (filterBy) {
    // If filterBy happens to start with ' && ', remove it:
    if (filterBy.startsWith(' && ')) {
      filterBy = filterBy.slice(4)
    }

    params.append('filter_by', filterBy)
  }

  if (sortByStr) {
    params.append('sort_by', sortByStr)
  }

  const uri = `${process.env.REACT_APP_TYPESENSE_CLUSTER_URI}/collections/searchableTasks/documents/search?${params}`

  const response = await fetch(uri, {
    method: 'GET',
    headers: {
      'X-TYPESENSE-API-KEY': contactSecrets?.typesense.scopedSearchKey || '',
    },
  })

  // Get the data:
  const results = (await response.json()) as SearchResponse<any>

  // For each hit, get the task:
  const tasks =
    results.hits?.map(
      hit =>
        ({
          ...hit.document,
          createdAt: hit.document.createdAt * 1000,
          updatedAt: hit.document.updatedAt * 1000,
        } as SearchableTask),
    ) || []

  return {
    tasks,
    totalTasks: results.found,
  }
}

export async function getTaskFromTypesense(
  taskId: string,
  contactSecrets: ContactSecrets | undefined = undefined,
  includeInternalComments: boolean = false,
): Promise<SearchableTask | null> {
  const excludeFields = ['comments']

  if (!includeInternalComments) {
    excludeFields.push('internalComments', 'internalCommentsCount')
  }

  const params = new URLSearchParams({
    q: '*', // get all docs so we can filter on them
    filter_by: `id:=${taskId}`,
    exclude_fields: excludeFields.join(','),
  })

  const uri = `${process.env.REACT_APP_TYPESENSE_CLUSTER_URI}/collections/searchableTasks/documents/search?${params}`

  const response = await fetch(uri, {
    method: 'GET',
    headers: {
      'X-TYPESENSE-API-KEY': contactSecrets?.typesense.scopedSearchKey || '',
    },
  })

  if (!response.ok) {
    return null
  }

  // Get the data:
  const results = (await response.json()) as SearchResponse<any>

  // Get the first hit:
  if (results.hits?.length) {
    const task = results.hits[0].document as SearchableTask

    if (!task.associationId) {
      task.associationId = null
    }

    task.updatedAt *= 1000
    task.comments = [
      ...(task.internalComments?.filter(c => 'createdAt' in c) || []),
      ...(task.externalComments || []),
    ].map(c => ({ ...c, createdAt: c.createdAt * 1000 }))

    return task
  }

  return null
}

/**
 * Get a 100 character description that excludes image links
 * @param description - The description to get a 100 character version of
 * @returns - A max 100 character description or an empty string
 */
export const getDescription100 = (description?: string) =>
  description
    ? description
        .replace(/!\[.*\]\(https:\/\/firebasestorage.*\)/, '')
        .trim()
        .slice(0, 100)
    : ''

export function apiTaskToSearchableTask(
  apiTask: APITask,
  searchableProperties: Partial<SearchableTask>,
  companyTaskCategories: Category[],
  company: APICompany,
  contact: APIContact,
  association: APIAssociation | null,
): SearchableTask {
  const taskCategoriesString =
    apiTask.taskCategories
      ?.map(cat => companyTaskCategories.find(c => c.id === cat)?.name)
      .join(', ') || ''

  const internalComments = apiTask.comments?.filter(c => c.internal) || []
  const externalComments = apiTask.comments?.filter(c => !c.internal) || []

  // Create subtask references:
  const subTaskReferences: SubTaskReference[] =
    apiTask.subTasks?.map(s => ({
      id: s.id,
      title: s.title,
      status: s.status,
      description100: getDescription100(s.description),
      createdAt: s.createdAt,
    })) || []

  // Convert the task to a searchable task:
  const searchableTask: SearchableTask = {
    ...apiTask,

    internalComments,
    externalComments,
    internalCommentsCount: internalComments.length,
    externalCommentsCount: externalComments.length,
    comments: sortBy([...internalComments, ...externalComments], 'createdAt'),
    schedule: apiTask.schedule || undefined,

    companyName: company.name,
    associationName: association?.name || '',
    contactIdsCanAccess: [contact?.id],

    // Convenience fields for easy searching:
    isPropertyTask: !!apiTask.associationId,
    isSubTask: false,
    isAssigned: apiTask.assignee !== undefined,
    isRecurring: !!apiTask.schedule,
    isWorkspaceTask: !!apiTask.workspace,
    hasDueDate: !!apiTask.dueDate,
    dueDateValue: apiTask.dueDate
      ? Date.parse(apiTask.dueDate || '').valueOf()
      : undefined,
    locationsString: apiTask.locations?.map(l => l.name).join(', ') || '',
    taskCategoriesString,

    // Optimized values to save extra requests:
    description100: getDescription100(apiTask.description),
    ...(apiTask.schedule && {
      schedule: apiTask.schedule,
    }),
    subTaskCount: subTaskReferences.length,
    subTasks: subTaskReferences,

    // Override any assumptions:
    ...searchableProperties,
  }

  return searchableTask
}

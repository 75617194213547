import { APIContact } from '@super-software-inc/foundation'
import ContactAvatar from 'components/app/ContactAvatar'
import { FilterInterface } from 'components/app/TaskViewOptions'
import {
  FlexRow,
  GhostTextInput,
  MultilevelDivider,
  MultilevelItem,
  MultilevelSubmenu,
} from 'components/lib'
import MultilevelLoading from 'components/lib/MultilevelDropdown/MultilevelLoading'
import React, { ReactElement, useMemo } from 'react'
import { MdAccountCircle } from 'react-icons/md'
// import { useTheme } from 'styled-components/macro'

interface ContactsFilterProps {
  title: ReactElement | string
  contacts: APIContact[]
  searchText?: string
  filter: FilterInterface
  handleSearch: React.ChangeEventHandler<HTMLInputElement>
  handleFilterChange: (x: FilterInterface) => void
  showUnassigned?: boolean
  loadingContacts?: boolean
}

const ContactsFilter = ({
  title,
  contacts,
  searchText,
  filter,
  handleSearch,
  handleFilterChange,
  showUnassigned = false,
  loadingContacts,
}: ContactsFilterProps) => {
  // const theme = useTheme()

  const activeContact = useMemo(() => {
    const contact = contacts.find(c => c.id === filter.value)

    return contact || null
  }, [filter.value, contacts])

  return (
    <MultilevelItem
      onClick={() => handleFilterChange({ type: filter.type, value: '' })}
    >
      <FlexRow align="center">{title}</FlexRow>
      <MultilevelSubmenu>
        <MultilevelItem>
          <GhostTextInput
            style={{ margin: 0, padding: 0 }}
            placeholder={filter.type?.toString()}
            value={searchText}
            onChange={handleSearch}
          />
        </MultilevelItem>
        <MultilevelDivider />
        <div style={{ maxHeight: 450, overflow: 'scroll' }}>
          {activeContact && (
            <MultilevelItem
              onClick={evt => {
                handleFilterChange({ type: filter.type, value: undefined })

                evt.stopPropagation()
              }}
              active
            >
              <FlexRow align="center">
                <ContactAvatar
                  data={activeContact}
                  style={{ marginRight: 8 }}
                />
                {activeContact.firstName
                  ? `${activeContact.firstName} ${activeContact.lastName}`
                  : activeContact.email || activeContact.phone?.number}{' '}
                {/* FIXME: #corpsV2 we cannot know the `title` when multiple associations are selected */}
                {/* <span */}
                {/*   style={{ */}
                {/*     color: theme.colors.text300, */}
                {/*     marginLeft: 8, */}
                {/*     fontSize: 14, */}
                {/*   }} */}
                {/* > */}
                {/*   {activeContact.title} */}
                {/* </span> */}
              </FlexRow>
            </MultilevelItem>
          )}
          {showUnassigned && (
            <MultilevelItem
              // Pass 'unassigned' to handle tasks with no assignee
              onClick={evt => {
                handleFilterChange({ type: filter.type, value: 'unassigned' })

                evt.stopPropagation()
              }}
            >
              <FlexRow align="center">
                <MdAccountCircle style={{ marginRight: 8, fontSize: 20 }} />{' '}
                Unassigned
              </FlexRow>
            </MultilevelItem>
          )}
          {contacts
            // Remove selected contact from options
            .filter(c => c.id !== filter.value)
            .map(contact => {
              const {
                id,
                firstName,
                lastName,
                email,
                phone,
                // FIXME: #corpsV2 we cannot know the `title` when multiple associations are selected
                // title: contactTitle,
              } = contact

              return (
                <MultilevelItem
                  key={id}
                  onClick={evt => {
                    handleFilterChange({ type: filter.type, value: id })

                    evt.stopPropagation()
                  }}
                >
                  <FlexRow align="center">
                    <ContactAvatar data={contact} style={{ marginRight: 8 }} />
                    {firstName
                      ? `${firstName} ${lastName}`
                      : email || phone?.number}{' '}
                    {/* <span */}
                    {/*   style={{ */}
                    {/*     color: theme.colors.text300, */}
                    {/*     marginLeft: 8, */}
                    {/*     fontSize: 14, */}
                    {/*   }} */}
                    {/* > */}
                    {/*   {contactTitle} */}
                    {/* </span> */}
                  </FlexRow>
                </MultilevelItem>
              )
            })}
          {loadingContacts && <MultilevelLoading />}
        </div>
      </MultilevelSubmenu>
    </MultilevelItem>
  )
}

export default ContactsFilter

import React, { useState } from 'react'
import {
  Divider,
  FlexColumn,
  FlexRow,
  MultilevelDropdown,
  MultilevelDropdownPositions,
  MultilevelHeader,
  MultilevelItem,
  MultilevelNoResults,
  TruncatedText,
} from 'components/lib'
import { useAuth } from 'reactfire'
import { signOut } from 'firebase/auth'
import { MdExpandMore, MdOutlineExpandLess } from 'react-icons/md'
import { useRecoilState } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import {
  APIContact,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import { useNavigate, useLocation } from 'react-router'
import {
  selectedAnnouncementAtom,
  showAnnouncementDrawerAtom,
} from 'pages/Announcements'
import { sortBy } from 'lodash'
import ContactAvatar from '../ContactAvatar'

const CompaniesDropdown = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )
  const [companySearchText, setCompanySearchText] = useState<string>('')
  const { companies } = authenticatedUser
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useRecoilState(
    showAnnouncementDrawerAtom,
  )
  const [, setSelectedAnnouncement] = useRecoilState(selectedAnnouncementAtom)
  const auth = useAuth()

  const companiesMemo = React.useMemo(() => {
    if (!companies) return []
    return sortBy(
      companies.filter(company =>
        company.name.toLowerCase().includes(companySearchText.toLowerCase()),
      ),
      'name',
    )
  }, [companySearchText, companies])

  return (
    <MultilevelDropdown
      position={MultilevelDropdownPositions.Left}
      maxHeight="calc(100vh - 200px)"
      title={
        <div>
          <MdOutlineExpandLess
            className="zinc-300"
            style={{ marginBottom: -5 }}
          />
          <MdExpandMore className="zinc-300" style={{ marginTop: -5 }} />
        </div>
      }
      closeOnClick={false}
    >
      {authenticatedUser.companies.length > 1 && (
        <>
          <MultilevelHeader
            onChange={setCompanySearchText}
            isDisabled={false}
            value={companySearchText}
            placeholder="Search companies"
            clearValue={() => setCompanySearchText('')}
          />

          <div
            style={{ overflow: 'scroll', maxHeight: 160, paddingBottom: 10 }}
          >
            {companiesMemo.map(company => (
              <MultilevelItem key={company.id}>
                <FlexRow
                  style={{ height: 20, cursor: 'pointer', maxWidth: 180 }}
                  onClick={event => {
                    event.stopPropagation()
                    const selectedContact = authenticatedUser.contacts.find(
                      (c: APIContact) => c.companyId === company.id,
                    ) as APIContact
                    setAuthenticatedUser({
                      ...authenticatedUser,
                      selectedCompany: company,
                      selectedContact,
                    })
                    if (location.pathname.includes('tasks')) {
                      navigate('/tasks')
                    }

                    if (showAnnouncementDrawer) {
                      setShowAnnouncementDrawer(false)
                      setSelectedAnnouncement(undefined)
                      navigate('/announcements')
                    }
                  }}
                >
                  <TruncatedText>{company.name}</TruncatedText>
                </FlexRow>
              </MultilevelItem>
            ))}
            {companiesMemo.length < 1 && <MultilevelNoResults />}
          </div>
          <Divider style={{ marginBottom: 0 }} />
        </>
      )}
      <FlexRow
        justify="flex-start"
        align="center"
        style={{ padding: 15, paddingBottom: 10 }}
      >
        <ContactAvatar data={authenticatedUser.selectedContact} small={false} />
        {authenticatedUser.selectedContact && (
          <FlexColumn justify="flex-end">
            <p style={{ margin: 0, marginLeft: 10, fontWeight: 600 }}>
              {getContactDisplayName(authenticatedUser.selectedContact)}
            </p>
          </FlexColumn>
        )}
      </FlexRow>
      <MultilevelItem
        onClick={() => {
          navigate('/settings')
        }}
      >
        <FlexRow align="center">
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 16, paddingRight: 5 }}
          >
            manufacturing
          </span>
          <TruncatedText>Settings and support</TruncatedText>
        </FlexRow>
      </MultilevelItem>
      <MultilevelItem
        onClick={async () => {
          await signOut(auth)

          navigate('/')

          // This is a hack to force a reload of the page and keep
          // the auth from crashing the app if the user immediately
          // logs back in
          navigate(0)
        }}
      >
        <FlexRow align="center">
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 16, paddingRight: 5 }}
          >
            logout
          </span>
          <TruncatedText>Logout</TruncatedText>
        </FlexRow>
      </MultilevelItem>
    </MultilevelDropdown>
  )
}

export default CompaniesDropdown

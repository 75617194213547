import {
  APIContact,
  ContactGroup,
  ContactReference,
  createContactReference,
  getContactDisplayName,
  Workspace,
} from '@super-software-inc/foundation'
import {
  Avatar,
  Dot,
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelHeader,
  MultilevelItem,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import {
  MultilevelNoResults,
  Positions,
} from 'components/lib/MultilevelDropdown'
import MultilevelLoading from 'components/lib/MultilevelDropdown/MultilevelLoading'
import useContactsCache from 'hooks/useContactsCache'
import React, { useEffect, useMemo, useState } from 'react'
import {
  MdErrorOutline,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { useTheme } from 'styled-components'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import ContactAvatar from '../ContactAvatar'

const VendorSelector = ({
  value,
  onChange,
  associationIds,
  style,
  maxHeight,
  workspace,
  isDisabled = false,
}: {
  value: ContactReference[]
  onChange: Function
  associationIds: (string | null)[]
  style: any
  maxHeight: string
  workspace: Workspace | null
  isDisabled?: boolean
}) => {
  const [searchContact, setSearchContact] = useState<string>('')

  const [contactOptions, setContactOptions] = useState<any[]>([])
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const theme = useTheme()

  const { data: contacts, isLoading } = useContactsCache(
    authenticatedUser.selectedCompany.id,
    associationIds,
    [ContactGroup.Vendors, ContactGroup.Staff],
  )

  const vendorContactsInEveryProp = useMemo(
    () =>
      contacts.filter(contact =>
        associationIds.every(id =>
          contact.associationIds.includes(id as string),
        ),
      ),
    [contacts, associationIds],
  )

  useEffect(() => {
    const options =
      vendorContactsInEveryProp && vendorContactsInEveryProp.length > 0
        ? (vendorContactsInEveryProp.filter(
            contact =>
              (!!contact.firstName &&
                contact.firstName.toLowerCase().includes(searchContact)) ||
              (!!contact.lastName &&
                contact.lastName.toLowerCase().includes(searchContact)) ||
              (!!contact.email &&
                contact.email.toLowerCase().includes(searchContact)),
          ) as APIContact[])
        : []

    setContactOptions(sortSubscriberAssigneeContacts(options))
  }, [
    value,
    vendorContactsInEveryProp,
    searchContact,
    authenticatedUser.selectedContact,
    associationIds,
  ])

  const contactIsSelected = (contact: APIContact) =>
    value?.find((v: ContactReference) => v.contactId === contact.id) !==
    undefined

  const toggleContact = async (contact: APIContact) => {
    if (contactIsSelected(contact)) {
      const newVendors = [...value].filter(
        (v: ContactReference) => v.contactId !== contact.id,
      )
      onChange(newVendors)
    } else {
      onChange([...value, createContactReference(contact)])
    }
  }

  return (
    <MultilevelDropdown
      isDisabled={isDisabled}
      closeOnClick={false}
      maxHeight={maxHeight}
      position={Positions.Right}
      trigger={
        <DropdownTriggerButton style={style} hasValue={value.length > 0} />
      }
      title={
        <>
          <Dot
            color={
              workspace === Workspace.WorkOrders
                ? theme.colors.blue
                : theme.colors.orange
            }
            size={6}
          />
          {value.length === 1 ? (
            <TruncatedText>{getContactDisplayName(value[0])}</TruncatedText>
          ) : value.length > 1 ? (
            <TruncatedText>{value.length} vendors</TruncatedText>
          ) : (
            'Vendor'
          )}
        </>
      }
    >
      <MultilevelHeader
        onChange={setSearchContact}
        isDisabled={false}
        value={searchContact}
        placeholder="Search vendors"
        clearValue={() => setSearchContact('')}
      />
      {contactOptions.length > 1 &&
        contactOptions.map((option: APIContact) => (
          <MultilevelItem
            key={option.id}
            active={false}
            onClick={() => {
              toggleContact(option)
            }}
          >
            <FlexRow align="center" justify="space-between">
              <FlexRow align="center">
                <div style={{ display: 'flex' }}>
                  {contactIsSelected(option) ? (
                    <MdOutlineCheckBox
                      style={{
                        fontSize: 20,
                        color: '#0A1F44',
                        marginRight: 5,
                      }}
                    />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      style={{
                        fontSize: 20,
                        color: '#C9CED6',
                        marginRight: 5,
                      }}
                    />
                  )}
                </div>
                {option ? (
                  <ContactAvatar data={option} style={{ marginRight: 8 }} />
                ) : (
                  <Avatar small style={{ marginRight: 8 }} />
                )}
                {getContactDisplayName(option)}
                <span style={{ color: '#627088', fontSize: 12, marginLeft: 5 }}>
                  {/* {option.title} */}
                </span>
              </FlexRow>
              {(option?.email === undefined || option?.email.length < 1) && (
                <Tooltip
                  overlay={
                    <span data-tip-disable={false}>
                      Cannot be notified without an email on file.{' '}
                    </span>
                  }
                >
                  <span>
                    <MdErrorOutline
                      style={{
                        marginLeft: 10,
                        fontSize: 16,
                        color: '#DC6803',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </FlexRow>
          </MultilevelItem>
        ))}
      {isLoading && <MultilevelLoading />}
      {!isLoading && contactOptions.length === 0 && <MultilevelNoResults />}
    </MultilevelDropdown>
  )
}

export default VendorSelector

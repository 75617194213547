import React, { useMemo, useState } from 'react'
import {
  APIContact,
  Contact,
  ContactGroup,
} from '@super-software-inc/foundation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components/macro'
import { FlexRow, TruncatedText, Tooltip } from 'components/lib'
import { profileModalAtom } from 'state/atoms'
import { MdErrorOutline } from 'react-icons/md'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { orderBy } from 'lodash'
import {
  VirtualizedSortableTableHeader,
  VirtualizedTableCell,
} from 'components/lib/NewTable'
import { ContactTabProps } from 'pages/Association/ContactsTable'
import useContactTitle from './useContactTitle'
import useContactUnits from './useContactUnits'
import {
  primaryAssociationSelector,
  windowDimensionsAtom,
} from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'
import { AutoCreatedLabel } from './UncategorizedContacts'

const ContentContainer = styled.div`
  padding: 12px 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`

const headerCellStyle = {
  border: 'none',
  maxWidth: 'unset',
  outline: 'none',
}

const ContactTableHeader = ({
  handleSort,
  sortKey,
  sortOrder,
  corpFirst,
  tab,
  style,
  nameColumnWidth,
}: {
  handleSort: Function
  sortKey: string
  sortOrder: 'asc' | 'desc'
  corpFirst: boolean
  tab: ContactTabProps
  style: React.CSSProperties
  nameColumnWidth: number
}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      borderBottom: '1px solid #E5E5E5',
      width: 'fit-content',
    }}
  >
    {/* This is a placeholder column so the second column will be sticky */}
    <VirtualizedSortableTableHeader
      active={sortKey === 'none'}
      sortOrder={sortOrder}
      onClick={() => {}}
      style={{
        width: 0,
        minWidth: 0,
        ...headerCellStyle,
      }}
    />

    <VirtualizedSortableTableHeader
      active={sortKey === 'name'}
      sortOrder={sortOrder}
      onClick={() => handleSort('name')}
      style={{
        width: nameColumnWidth,
        minWidth: nameColumnWidth,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Name</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
    {tab.includeCompany && (
      <VirtualizedSortableTableHeader
        active={sortKey === 'businessName'}
        sortOrder={sortOrder}
        onClick={() => handleSort('businessName')}
        style={{
          width: 160,
          minWidth: 160,
          ...headerCellStyle,
        }}
      >
        <FlexRow align="center">Company</FlexRow>
      </VirtualizedSortableTableHeader>
    )}
    {tab.includeLocation && (
      <VirtualizedSortableTableHeader
        active={sortKey === 'units'}
        sortOrder={sortOrder}
        onClick={() => handleSort('units')}
        style={{
          width: 160,
          minWidth: 160,
          ...headerCellStyle,
        }}
      >
        <FlexRow align="center">Location</FlexRow>
      </VirtualizedSortableTableHeader>
    )}
    <VirtualizedSortableTableHeader
      active={sortKey === 'title'}
      sortOrder={sortOrder}
      onClick={() => handleSort('title')}
      style={{
        width: 160,
        minWidth: 160,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">Type</FlexRow>
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === 'email'}
      sortOrder={sortOrder}
      onClick={() => handleSort('email')}
      style={{
        width: 250,
        minWidth: 250,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Email</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === 'phone.number'}
      sortOrder={sortOrder}
      onClick={() => handleSort('phone.number')}
      style={{
        width: 200,
        minWidth: 200,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Phone</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
  </div>
)

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: APIContact[]
    handleTableRowClick: Function
    corpFirst?: boolean
    contactUnitNameMap: Record<string, string>
    contactTitleMap: Record<string, string>
    handleSort: Function
    sortKey: string
    sortOrder: 'asc' | 'desc'
    tab: ContactTabProps
    nameColumnWidth: number
  }
  style: React.CSSProperties
}) => {
  const contact = data.data[index]
  const {
    handleTableRowClick,
    corpFirst,
    contactUnitNameMap,
    contactTitleMap,
    handleSort,
    sortKey,
    sortOrder,
    tab,
    nameColumnWidth,
  } = data
  if (index === 0) {
    return (
      <ContactTableHeader
        handleSort={handleSort}
        sortKey={sortKey}
        sortOrder={sortOrder}
        corpFirst={corpFirst || true}
        tab={tab}
        style={style}
        nameColumnWidth={nameColumnWidth}
      />
    )
  }
  return (
    <div
      key={contact.id}
      style={{
        ...style,
        display: 'flex',
        borderBottom: '1px solid #E5E5E5',
        alignItems: 'flex-start',
        width: 'auto',
        height: 'unset',
        cursor: 'pointer',
      }}
    >
      <VirtualizedTableCell
        style={{
          width: 0,
          minWidth: 0,
          maxWidth: 'unset',
          padding: 8,
          border: 'none',
        }}
      />
      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        style={{
          width: nameColumnWidth,
          minWidth: nameColumnWidth,
          padding: 8,
        }}
      >
        <TruncatedText className="font-medium">
          <FlexRow align="center" justify="flex-start">
            <ContactAvatar
              data={contact as Contact}
              style={{ marginRight: 5 }}
            />

            <FlexRow>
              {contact.firstName}
              {contact.lastName ? ` ${contact.lastName}` : ''}
            </FlexRow>
          </FlexRow>
        </TruncatedText>
      </VirtualizedTableCell>
      {tab.includeCompany && (
        <VirtualizedTableCell
          onClick={() => handleTableRowClick(contact)}
          style={{
            width: 160,
            minWidth: 160,
          }}
        >
          <FlexRow align="center" justify="flex-start">
            <TruncatedText className="text-slate-500">
              {contact.businessName}
            </TruncatedText>
          </FlexRow>
        </VirtualizedTableCell>
      )}
      {tab.includeLocation && (
        <VirtualizedTableCell
          onClick={() => handleTableRowClick(contact)}
          className="text-slate-500"
          style={{
            width: 160,
            minWidth: 160,
          }}
        >
          <TruncatedText style={{ height: 20 }}>
            {contactUnitNameMap[contact.id]}
          </TruncatedText>
        </VirtualizedTableCell>
      )}
      <VirtualizedTableCell
        style={{
          minWidth: 160,
          width: 160,
        }}
        onClick={() => handleTableRowClick(contact)}
        className="text-slate-500"
      >
        <FlexRow align="center" justify="flex-start">
          {tab.alwaysAutocreated ? (
            <AutoCreatedLabel />
          ) : (
            <TruncatedText>{contactTitleMap[contact.id] || ' '}</TruncatedText>
          )}
        </FlexRow>
      </VirtualizedTableCell>

      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        className="text-slate-500"
        style={{
          width: 250,
          minWidth: 250,
        }}
      >
        <TruncatedText>
          <FlexRow align="center" justify="flex-start">
            {contact.email && contact.email.length > 1 ? (
              <p>{contact.email}</p>
            ) : (
              <Tooltip overlay={<span>No email on file</span>}>
                <MdErrorOutline style={{ fontSize: 16, color: '#DC6803' }} />
              </Tooltip>
            )}
          </FlexRow>
        </TruncatedText>
      </VirtualizedTableCell>
      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        style={{
          width: 200,
          minWidth: 200,
        }}
        className="text-slate-500"
      >
        <FlexRow align="center" justify="flex-start">
          {contact.phone?.number ? (
            <TruncatedText>
              {formatPhoneNumber(contact.phone?.number)}
            </TruncatedText>
          ) : (
            <Tooltip overlay={<span>No phone number on file</span>}>
              <MdErrorOutline style={{ fontSize: 16, color: '#DC6803' }} />
            </Tooltip>
          )}
        </FlexRow>
      </VirtualizedTableCell>
    </div>
  )
}

const VirtualizedContactTable = ({
  contacts,
  corpFirst = false,
  tab,
}: {
  contacts: APIContact[]
  corpFirst?: boolean
  tab: {
    groups: ContactGroup[]
    includeLocation?: boolean
    includeType?: boolean
    includeCompany?: boolean
  }
}) => {
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const nameColumnWidth = useMemo(
    () => (windowDimensions.width > 1350 ? windowDimensions.width - 1075 : 200),
    [windowDimensions.width],
  )

  const { contactUnitNameMap } = useContactUnits(
    contacts,
    primaryAssociation,
    corpFirst,
  )

  const { contactTitleMap } = useContactTitle(
    contacts,
    primaryAssociation,
    corpFirst,
  )

  type SortKeys =
    | 'name'
    | 'title'
    | 'units'
    | 'businessName'
    | 'email'
    | 'phone.number'
    | 'accessLevel'

  const [sortKey, setSortKey] = useState<SortKeys>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const handleSort = (nextSort: SortKeys) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const sortedContacts = useMemo(() => {
    // Add an empty row to the beginning of the list to act as a placeholder for the header
    const headerContact = {} as APIContact

    if (sortKey === 'name') {
      const newContacts = orderBy(
        contacts,
        ['firstName', 'lastName'],
        sortOrder,
      )
      return [headerContact, ...newContacts]
    }
    if (sortKey === 'units') {
      const newContacts = orderBy(
        contacts,
        contact => contactUnitNameMap[contact.id],
        sortOrder,
      )
      return [headerContact, ...newContacts]
    }
    if (sortKey === 'title') {
      const newContacts = orderBy(
        contacts,
        contact => contactTitleMap[contact.id],
        sortOrder,
      )
      return [headerContact, ...newContacts]
    }

    if (sortKey === 'businessName') {
      const newContacts = orderBy(contacts, 'businessName', sortOrder)
      return [headerContact, ...newContacts]
    }
    const newContacts = orderBy(contacts, sortKey, sortOrder)
    return [headerContact, ...newContacts]
  }, [contacts, sortKey, sortOrder, contactUnitNameMap, contactTitleMap])

  const handleTableRowClick = (contact: APIContact) => {
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: true,
      selectedContact: contact,
      corpFirst,
    })
  }

  return (
    <ContentContainer>
      <List
        height={windowDimensions.height - 150}
        itemCount={sortedContacts.length}
        itemSize={38}
        itemData={{
          data: sortedContacts,
          handleTableRowClick,
          corpFirst,
          contactUnitNameMap,
          contactTitleMap,
          handleSort,
          sortKey,
          sortOrder,
          tab,
          nameColumnWidth,
        }}
        width="100%"
      >
        {ItemRenderer}
      </List>
    </ContentContainer>
  )
}

export default VirtualizedContactTable

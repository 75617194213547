import React from 'react'
import VerticalNavLink from 'components/lib/Navigation/VerticalNavLink'
import { NavLink } from 'react-router-dom'

const SettingsAndSupport = () => (
  <VerticalNavLink
    icon={
      <span
        className="material-symbols-rounded"
        style={{ fontSize: 16, marginTop: 6 }}
      >
        settings
      </span>
    }
    navBaseProps={{
      to: `/settings`,
    }}
    navBaseAs={NavLink}
    label="Settings and support"
  />
)

export default SettingsAndSupport

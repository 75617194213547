import {
  DropdownTriggerButton,
  FlexRow,
  MultilevelItem,
  TruncatedText,
} from 'components/lib'
import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useFunctions } from 'reactfire'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import { FixedSizeList as List } from 'react-window'
import {
  APIContact,
  createContactReference,
  getContactDisplayName,
  PropertyInfo,
} from '@super-software-inc/foundation'
import FlexibleMultilevelDropdown from 'components/lib/MultilevelDropdown/FlexibleMultilevelDropdown'
import useContactsCache, { contactsCacheAtom } from 'hooks/useContactsCache'
import { selectedTaskAtom } from 'pages/Tasks'
import AddUncategorizedContact from '../AddUncategorizedContact'
import {
  ContactRow,
  DropdownFixedHeader,
} from '../Dropdowns/ContactSelectEmailSms'

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: { data: any[]; onChange: Function; setSearchAssignee: Function } // TODO - update type
  style: React.CSSProperties
}) => {
  const item = data.data[index]
  return (
    <div style={style}>
      <MultilevelItem key={item.id} active={item.selected}>
        <ContactRow
          option={item}
          toggleContact={() => {
            if (item.id) {
              data.onChange(createContactReference(item.data))
              data.setSearchAssignee('')
            } else {
              data.onChange(null)
            }
          }}
          allowSms={false}
        />
      </MultilevelItem>
    </div>
  )
}

const AssigneeDropdown = ({
  value,
  onChange,
  style,
  isDisabled = false,
}: {
  value: any
  onChange: Function
  style: any
  isDisabled?: boolean
}) => {
  const selectedTask = useRecoilValue(selectedTaskAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const setCachedContacts = useSetRecoilState(contactsCacheAtom)

  const associationIds = selectedTask?.associationId
    ? [selectedTask.associationId]
    : []

  const { data: associationContacts } = useContactsCache(
    authenticatedUser.selectedCompany.id,
    associationIds,
  )

  const [searchAssignee, setSearchAssignee] = useState<string>('')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [contactOptions, setContactOptions] = useState<any[]>([])

  const onNewContactSuccess = (
    newContact: APIContact,
    isExistingContact: Boolean,
  ) => {
    onChange(
      createContactReference(
        newContact as APIContact,
        selectedTask?.associationId,
      ),
    )

    if (!isExistingContact) {
      setCachedContacts([...associationContacts, newContact])
    }
  }

  const functions = useFunctions()
  functions.region = 'us-east1'

  useEffect(() => {
    const options =
      associationContacts && associationContacts.length > 0
        ? [
            {
              id: 0,
              value: null,
              label: 'Unassigned',
              selected: value === null,
            },
            ...(
              associationContacts.filter(
                contact =>
                  getContactDisplayName(contact)
                    .toLowerCase()
                    .includes(searchAssignee.toLowerCase()) ||
                  (!!contact.email &&
                    contact.email.toLowerCase().includes(searchAssignee)),
              ) as APIContact[]
            ).map(p => ({
              id: p.id,
              data: p,
              value: p.id,
              label: getContactDisplayName(p),
              title: p.propertyInfo.find(
                (info: PropertyInfo) =>
                  info.associationId === selectedTask?.associationId,
              )?.title,
              selectedType:
                value != null && p.id === value.contactId ? ['email'] : [],
              photoURL: p.photoURL,
            })),
          ]
        : []
    setContactOptions(sortSubscriberAssigneeContacts(options))
  }, [
    value,
    associationContacts,
    searchAssignee,
    authenticatedUser.selectedContact,
    selectedTask?.associationId,
  ])

  return (
    <>
      {modalIsOpen && selectedTask?.associationId && (
        <AddUncategorizedContact
          onClose={() => setModalIsOpen(false)}
          pageTitle="Assign"
          instructions="An assignee is able to view task history and will be notified of any future updates."
          value={searchAssignee}
          valueOnChange={setSearchAssignee}
          associationId={selectedTask.associationId}
          onSuccess={onNewContactSuccess}
        />
      )}

      <FlexibleMultilevelDropdown
        isDisabled={isDisabled}
        trigger={<DropdownTriggerButton style={style} hasValue={!!value} />}
        title={
          <>
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, paddingRight: 6 }}
            >
              account_circle
            </span>
            {value ? (
              <TruncatedText>{getContactDisplayName(value)}</TruncatedText>
            ) : (
              'Unassigned'
            )}
          </>
        }
        fixedHeader={
          <DropdownFixedHeader
            search={searchAssignee}
            setSearch={setSearchAssignee}
            handleReset={() => setSearchAssignee('')}
            searchPlaceHolder="Assign to"
            allowSms={false}
          />
        }
      >
        <List
          itemData={{
            data: contactOptions,
            onChange,
            setSearchAssignee,
          }}
          height={300}
          itemCount={contactOptions.length}
          itemSize={40}
          width={430}
        >
          {ItemRenderer}
        </List>
        {contactOptions.length === 1 && (
          <MultilevelItem
            key={searchAssignee}
            onClick={() => {
              setModalIsOpen(true)
            }}
          >
            <FlexRow align="center">
              <MdAdd style={{ fontSize: 16, marginRight: 5 }} />
              Assign to {searchAssignee}
            </FlexRow>
          </MultilevelItem>
        )}
      </FlexibleMultilevelDropdown>
    </>
  )
}

export default AssigneeDropdown

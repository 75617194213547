import {
  APIContact,
  ContactGroup,
  PropertyInfo,
} from '@super-software-inc/foundation'
import HOAEmployees from 'components/app/Directory/HOAEmployees'
import HOAMembers from 'components/app/Directory/HOAMembers'
import Residents from 'components/app/Directory/Residents'
import UncategorizedContacts from 'components/app/Directory/UncategorizedContacts'
import Vendors from 'components/app/Directory/Vendors'
import VirtualizedContactTable from 'components/app/Directory/VirtualizedContactTable'
import { FlexRow } from 'components/lib'
import Search from 'components/lib/Search'
import useContactsCache from 'hooks/useContactsCache'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  currentContactTabAtom,
  profileModalAtom,
} from 'state/atoms'
import LoadingIcon from 'components/lib/LoadingIcon'
import useContactCompanies from 'components/app/Directory/useContactCompanies'
import { primaryAssociationSelector } from '../../AppRoutes'

export interface ContactTabProps {
  groups: ContactGroup[]
  includeLocation?: boolean
  includeType?: boolean
  includeCompany?: boolean
  alwaysAutocreated?: boolean
}

const tabGroups = [
  {
    groups: [
      ContactGroup.Board,
      ContactGroup.Owners,
      ContactGroup.Renters,
      ContactGroup.Residents,
      ContactGroup.PastOwners,
      ContactGroup.PastRenters,
      ContactGroup.PastResidents,
      ContactGroup.Sponsors,
    ],
    includeLocation: true,
  },
  {
    groups: [ContactGroup.Board],
  },
  {
    groups: [
      ContactGroup.Management,
      ContactGroup.Sponsors,
      ContactGroup.Staff,
    ],
    includeCompany: true,
  },
  {
    groups: [ContactGroup.Vendors],
    includeCompany: true,
  },
  {
    groups: [ContactGroup.Uncategorized],
    alwaysAutocreated: true,
  },
]

const ContactsTable = ({ corpFirst }: { corpFirst?: boolean }) => {
  const params = useParams()
  const [searchText, setSearchText] = useState('')

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const currentContactTab = useRecoilValue(currentContactTabAtom)
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [initialContactHandled, setInitialContactHandled] = useState(false)

  const acl = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociation.id,
      )?.acl,

    [authenticatedUser.selectedContact, primaryAssociation],
  )

  const tableAssociationIds = corpFirst
    ? authenticatedUser.selectedCompany?.associationIds || []
    : [primaryAssociation.id]

  const { data: contacts, isLoading } = useContactsCache(
    authenticatedUser.selectedCompany.id,
    tableAssociationIds,
  )

  useEffect(() => {
    if (
      !initialContactHandled &&
      params?.contactId &&
      contacts &&
      contacts.length > 0
    ) {
      const contact = contacts.find(c => c.id === params?.contactId)

      if (contact) {
        setProfileModal({
          ...profileModal,
          selectedContact: contact,
          sidebarIsOpen: true,
        })

        setInitialContactHandled(true)
      }
    }
    // eslint-disable-next-line
  }, [params?.contactId, contacts, initialContactHandled])
  const { contactWithCompanies: employees } = useContactCompanies(contacts)
  const filteredContacts = useMemo(
    () =>
      orderBy(
        (corpFirst && tabGroups[currentContactTab].includeCompany
          ? employees
          : contacts || []
        ).filter(({ firstName, lastName, email, phone, propertyInfo }) => {
          if (corpFirst) {
            return (
              propertyInfo?.some(p =>
                p.groups?.some(group =>
                  tabGroups[currentContactTab].groups.includes(group),
                ),
              ) &&
              (`${firstName} ${lastName}`
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
                firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                email?.toLowerCase().includes(searchText.toLowerCase()) ||
                phone?.number?.toLowerCase().includes(searchText.toLowerCase()))
            )
          }
          const currentProperty = propertyInfo.find(
            p => p.associationId === primaryAssociation.id,
          )
          return tabGroups[currentContactTab].groups.some(group =>
            currentProperty?.groups?.includes(group),
          )
        }),
        ['firstName', 'lastName'],
        ['asc', 'asc'],
      ),
    [
      contacts,
      primaryAssociation.id,
      corpFirst,
      searchText,
      currentContactTab,
      employees,
    ],
  )

  return (
    <div>
      {corpFirst && (
        <FlexRow
          justify="flex-end"
          style={{ zIndex: 11, marginTop: -60, position: 'fixed', right: 10 }}
        >
          <Search
            text={searchText}
            changeText={setSearchText}
            placeholder="Search contacts"
          />
        </FlexRow>
      )}

      {corpFirst ? (
        <VirtualizedContactTable
          contacts={filteredContacts as APIContact[]}
          corpFirst={corpFirst}
          tab={tabGroups[currentContactTab]}
        />
      ) : (
        <div>
          {currentContactTab === 0 && (
            <Residents
              contacts={filteredContacts as APIContact[]}
              isLoading={isLoading}
              corpFirst={corpFirst}
            />
          )}
          {currentContactTab === 1 && (
            <HOAMembers
              contacts={filteredContacts as APIContact[]}
              isLoading={isLoading}
              corpFirst={corpFirst}
            />
          )}
          {acl?.contacts.view && currentContactTab === 2 && (
            <HOAEmployees
              contacts={filteredContacts as APIContact[]}
              isLoading={isLoading}
              corpFirst={corpFirst}
            />
          )}
          {acl?.contacts.view && currentContactTab === 3 && (
            <Vendors
              contacts={filteredContacts as APIContact[]}
              isLoading={isLoading}
              corpFirst={corpFirst}
            />
          )}
          {acl?.contacts.view && currentContactTab === 4 && (
            <UncategorizedContacts
              contacts={filteredContacts as APIContact[]}
              isLoading={isLoading}
              corpFirst={corpFirst}
            />
          )}
        </div>
      )}
      {isLoading && corpFirst && (
        <FlexRow justify="center" style={{ marginTop: -500 }}>
          <LoadingIcon />
        </FlexRow>
      )}
    </div>
  )
}

export default ContactsTable

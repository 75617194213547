import {
  AccessLevel,
  APIContact,
  Contact,
  ContactGroup,
  NoAccessACL,
} from '@super-software-inc/foundation'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'

// TODO: #corpsV2 make this take an entire contact
export const addContact = async (
  companyId: string,
  associationId: string,
  email: string | null,
  phoneNumber: string | null,
  group: ContactGroup,
  ignoreDupeError: boolean = false,
) => {
  if (!email && !phoneNumber) {
    throw new Error('Email or phone required')
  }

  const functions = getFunctions()
  functions.region = 'us-east1'

  const createContact: HttpsCallable<
    { companyId: string; contact: Partial<Contact>; ignoreDupeError?: boolean },
    APIContact
  > = httpsCallable(functions, 'createContact')

  const { data } = await createContact({
    companyId,
    contact: {
      ...(email && { email }),
      ...(phoneNumber && {
        phone: {
          number: phoneNumber,
          type: 'mobile',
        },
      }),
      propertyInfo: [
        {
          associationId,
          groups: [group],
          units: [],
          accessLevel: AccessLevel.NoAccess,
          customAccess: false,
          acl: NoAccessACL,
          propertyRelation: null,
          boardTerms: [],
          title: '',
        },
      ],
    },
    ignoreDupeError,
  })

  return data
}

export const updateContact = async (contact: APIContact) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const updateContactCallable: HttpsCallable<
    {
      companyId: string
      contactId: string
      contact: Contact
    },
    APIContact
  > = httpsCallable(functions, 'updateContact')

  const { data } = await updateContactCallable({
    companyId: contact.companyId,
    contactId: contact.id,
    contact,
  })

  return data
}

import React, { useEffect, useMemo } from 'react'
import { useFunctions } from 'reactfire'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, taskFilterAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import { httpsCallable } from 'firebase/functions'
import {
  MdRefresh,
  MdOutlineCircle,
  MdPauseCircleOutline,
  MdOutlinePending,
  MdAddCircleOutline,
  MdOutlineMonitorHeart,
  MdOutlineCheckCircle,
} from 'react-icons/md'
import {
  APIAssociation,
  APITask,
  TaskStatus,
  Workspace,
} from '@super-software-inc/foundation'
import {
  FlexRow,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow,
  TruncatedText,
  TableHead,
} from 'components/lib'
import { addDays, isAfter, isBefore } from 'date-fns'
import { toJSDate } from 'utils/date'
import { DateFilterOption, FilterType } from 'components/app/TaskViewOptions'
import { noPropertySelectedAtom } from 'components/app/SidebarNav/AssociationDropdown'
import { useNavigate } from 'react-router'
import StatItem from 'components/lib/Stats/StatItem'
import StatContainer from 'components/lib/Stats/StatContainer'
import {
  associationChoicesAtom,
  selectedAssociationChoicesAtom,
} from '../AppRoutes'

const DashboardContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--Stroke-Light, #e1e4e8);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  padding: 24px;
`

const notClosedTaskStatuses = [
  TaskStatus.OPEN,
  TaskStatus.PENDING,
  TaskStatus.ON_HOLD,
]

interface CompanyAssociation {
  id: null
  name: string
}

const adminDashboardTasksAtom = atom<{
  tasks: APITask[]
  pageLoadTime: {
    date: string
    time: string
  }
  companyId: string
}>({
  key: 'adminDashboardTasks',
  default: {
    tasks: [] as APITask[],
    pageLoadTime: {
      date: '##/##/##',
      time: '##:##',
    },
    companyId: '',
  },
})

// TODO - Add ACL to admin dashboard
const AdminDashboard = () => {
  const functions = useFunctions()
  const getTasks = httpsCallable(functions, 'getTasks')

  const { selectedContact, selectedCompany } = useRecoilValue(
    authenticatedUserAtom,
  )

  const [, setSelectedAssociations] = useRecoilState(
    selectedAssociationChoicesAtom,
  )

  const associationChoices = useRecoilValue(associationChoicesAtom)
  const [, setTaskFilter] = useRecoilState(taskFilterAtom)
  const [, setNoPropertySelected] = useRecoilState(noPropertySelectedAtom)

  const [adminDashboardTasks, setAdminDashboardTasks] = useRecoilState(
    adminDashboardTasksAtom,
  )
  const { tasks, pageLoadTime } = adminDashboardTasks

  const navigate = useNavigate()

  const associations = useMemo(() => {
    if (selectedCompany.corpFirst) {
      return [{ id: null, name: selectedCompany.name }, ...associationChoices]
    }
    return associationChoices
  }, [associationChoices, selectedCompany])

  const firstDayOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
  )

  const updateSelectedAssociation = (
    association: CompanyAssociation | APIAssociation,
  ) => {
    if (association.id === null) {
      setNoPropertySelected(true)
      setSelectedAssociations([])
    } else {
      setNoPropertySelected(false)
      setSelectedAssociations([association as APIAssociation])
    }
  }

  const selectAllAssociationsAndCompany = () => {
    setNoPropertySelected(true)
    setSelectedAssociations(associationChoices)
  }

  const resetFilters = () => {
    setTaskFilter({ type: undefined, value: '' })
  }

  const navigateToFilteredTaskView = (
    filterType: FilterType,
    value: string,
  ) => {
    setTaskFilter({ type: filterType, value })
    navigate('/tasks')
  }

  function getTasksFromApi() {
    // Don't get tasks if there are no associations:
    if (!associations || associations.length === 0) {
      return
    }

    setAdminDashboardTasks({
      tasks: [],
      pageLoadTime: {
        date: '##/##/##',
        time: '##:##',
      },
      companyId: '',
    })

    functions.region = 'us-east1'

    const allAssociationIds = [
      ...associations.map(association => association.id),
      null,
    ]

    getTasks({
      associationIds: allAssociationIds,
      companyId: selectedCompany.id,
      includeClosed: true,
    }).then(result => {
      const { data } = result
      setAdminDashboardTasks({
        tasks: data as APITask[],
        pageLoadTime: {
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
        },
        companyId: selectedCompany.id,
      })
    })
  }

  useEffect(() => {
    if (adminDashboardTasks.companyId !== selectedCompany.id) {
      getTasksFromApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  return (
    <div style={{ padding: 20 }}>
      <div>
        <h3>
          Hello{selectedContact.firstName && `, ${selectedContact.firstName}`}!
        </h3>
        <FlexRow align="center" style={{ marginBottom: 20 }}>
          <p>
            Here is your overview for {associations.length} properties, last
            updated {pageLoadTime.date} at {pageLoadTime.time}.
          </p>
          <IconButton
            onClick={() => {
              getTasksFromApi()
            }}
          >
            <MdRefresh />
          </IconButton>
        </FlexRow>
      </div>
      <StatContainer>
        <StatItem
          title="OPEN"
          number={tasks.filter(task => task.status === TaskStatus.OPEN).length}
          icon={<MdOutlineCircle style={{ color: '#0B96EC', fontSize: 25 }} />}
          backgroundColor="#D3EDFF"
          borderColor="#F5FBFF"
          onClick={() => {
            selectAllAssociationsAndCompany()
            navigateToFilteredTaskView(FilterType.Status, TaskStatus.OPEN)
          }}
        />
        <StatItem
          title="PENDING"
          number={
            tasks.filter(task => task.status === TaskStatus.PENDING).length
          }
          icon={<MdOutlinePending style={{ color: '#DC6803', fontSize: 25 }} />}
          backgroundColor="#FFF1CB"
          borderColor="#FFFDF0"
          onClick={() => {
            selectAllAssociationsAndCompany()
            navigateToFilteredTaskView(FilterType.Status, TaskStatus.PENDING)
          }}
        />
        <StatItem
          title="ON HOLD"
          number={
            tasks.filter(task => task.status === TaskStatus.ON_HOLD).length
          }
          icon={
            <MdPauseCircleOutline style={{ color: '#F03D3D', fontSize: 25 }} />
          }
          backgroundColor="#FEECEC"
          borderColor="#FFFAFA"
          onClick={() => {
            selectAllAssociationsAndCompany()
            navigateToFilteredTaskView(FilterType.Status, TaskStatus.ON_HOLD)
          }}
        />
        <StatItem
          title="CREATED (MTD)"
          number={
            tasks.filter(
              task =>
                task.createdAt !== undefined &&
                new Date(task.createdAt) >= firstDayOfMonth,
            ).length
          }
          icon={
            <MdAddCircleOutline style={{ color: '#039855', fontSize: 25 }} />
          }
          backgroundColor="#D1FADF"
          borderColor="#F6FEF9"
          onClick={() => {
            selectAllAssociationsAndCompany()
            navigateToFilteredTaskView(
              FilterType.CreatedDate,
              DateFilterOption.MonthToDate,
            )
          }}
        />
        <StatItem
          title="CLOSED (MTD)"
          number={
            tasks.filter(
              task =>
                task.status === TaskStatus.CLOSED &&
                task.closedAt &&
                isAfter(
                  // eslint-disable-next-line
                  new Date(task.closedAt._seconds * 1000),
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                ),
            ).length
          }
          icon={
            <MdOutlineCheckCircle style={{ color: '#0A1F44', fontSize: 25 }} />
          }
          backgroundColor="#F1F2F4"
          borderColor="#FAFAFB"
          onClick={() => {
            selectAllAssociationsAndCompany()
            navigateToFilteredTaskView(
              FilterType.ClosedDate,
              DateFilterOption.MonthToDate,
            )
          }}
        />
      </StatContainer>
      <DashboardContainer>
        <FlexRow align="center" style={{ marginBottom: 25 }}>
          <FlexRow
            align="center"
            justify="center"
            style={{
              backgroundColor: '#F1F2F4',
              width: 60,
              height: 60,
              borderRadius: 60,
              borderColor: '#FAFAFB',
              borderWidth: 10,
              marginRight: 20,
            }}
          >
            <MdOutlineMonitorHeart style={{ color: '#0A1F44', fontSize: 25 }} />
          </FlexRow>

          <h1>Portfolio overview</h1>
        </FlexRow>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell style={{ textAlign: 'left', width: 250 }}>
                Property
              </TableHeaderCell>
              {/* <TableHeaderCell style={{ textAlign: 'left' }}>Default Assignee</TableHeaderCell> */}
              <TableHeaderCell style={{ textAlign: 'left' }}>
                General
              </TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'left' }}>
                Compliance
              </TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'left' }}>
                Work Orders
              </TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'left' }}>
                Overdue
              </TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'left' }}>
                Due Next 30 days
              </TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'left' }}>
                Closed last 30 days
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {associations.map(association => (
              <TableRow key={association.id}>
                <TableCell
                  onClick={() => {
                    resetFilters()
                    updateSelectedAssociation(association)
                    navigate('/tasks')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <FlexRow>
                    <TruncatedText>{association.name}</TruncatedText>
                  </FlexRow>
                </TableCell>
                <TableCell
                  onClick={() => {
                    resetFilters()
                    updateSelectedAssociation(association)
                    navigate('/tasks?workspace=null')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          // !task.closedAt &&
                          notClosedTaskStatuses.includes(task.status) &&
                          task.associationId === association.id &&
                          !task.workspace,
                      ).length}
                </TableCell>
                <TableCell
                  onClick={() => {
                    resetFilters()
                    updateSelectedAssociation(association)
                    navigate('/tasks?workspace=compliance')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          notClosedTaskStatuses.includes(task.status) &&
                          task.associationId === association.id &&
                          task.workspace === Workspace.Compliance,
                      ).length}
                </TableCell>
                <TableCell
                  onClick={() => {
                    resetFilters()
                    updateSelectedAssociation(association)
                    navigate('/tasks?workspace=work-orders')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          notClosedTaskStatuses.includes(task.status) &&
                          task.associationId === association.id &&
                          task.workspace === Workspace.WorkOrders,
                      ).length}
                </TableCell>
                <TableCell
                  onClick={() => {
                    updateSelectedAssociation(association)
                    navigateToFilteredTaskView(
                      FilterType.Date,
                      DateFilterOption.Overdue,
                    )
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          notClosedTaskStatuses.includes(task.status) &&
                          task.associationId === association.id &&
                          task.dueDate &&
                          isBefore(toJSDate(task.dueDate), new Date()),
                      ).length}
                </TableCell>
                <TableCell
                  onClick={() => {
                    updateSelectedAssociation(association)
                    navigateToFilteredTaskView(
                      FilterType.Date,
                      DateFilterOption.Next30Days,
                    )
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          notClosedTaskStatuses.includes(task.status) &&
                          task.associationId === association.id &&
                          task.dueDate &&
                          isAfter(toJSDate(task.dueDate), new Date()) &&
                          isBefore(
                            toJSDate(task.dueDate),
                            addDays(new Date(), 30),
                          ),
                      ).length}
                </TableCell>
                <TableCell
                  onClick={() => {
                    updateSelectedAssociation(association)
                    navigateToFilteredTaskView(
                      FilterType.ClosedDate,
                      DateFilterOption.Last30Days,
                    )
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {tasks.length === 0
                    ? '##'
                    : tasks.filter(
                        task =>
                          task.status === TaskStatus.CLOSED &&
                          task.associationId === association.id &&
                          task.closedAt &&
                          // eslint-disable-next-line
                          new Date(task.closedAt._seconds * 1000) >=
                            new Date(
                              new Date().setDate(new Date().getDate() - 30),
                            ),
                      ).length}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DashboardContainer>
    </div>
  )
}

export default AdminDashboard

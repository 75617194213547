// transition-all duration-500
import React, { useState, useEffect } from 'react'
import { MdOutlineSearch, MdOutlineClose } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import FlexRow from './FlexRow'
import { GhostTextInput } from './Forms/TextInput'
import { windowDimensionsAtom } from '../../AppRoutes'
import IconButton from './Buttons/IconButton'

interface SearchProps {
  text: string
  changeText: Function
  placeholder?: string
}

const Search = ({ text, changeText, placeholder }: SearchProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  useEffect(() => {
    if (!expanded) {
      changeText('')
    }
    // eslint-disable-next-line
  }, [expanded])

  return (
    <FlexRow
      justify="center"
      align="center"
      className={`${expanded && 'border-b-2 border-sky-950 bg-white'}`}
    >
      <IconButton onClick={() => setExpanded(!expanded)}>
        <MdOutlineSearch />
      </IconButton>
      {expanded && (
        <FlexRow align="center">
          <GhostTextInput
            style={{
              marginBottom: 5,
              marginTop: 4,
              paddingLeft: 5,
              width: windowDimensions.isMobile ? 150 : 'initial',
            }}
            placeholder={placeholder || 'Type to search'}
            value={text}
            onChange={x => changeText(x.currentTarget.value)}
          />
          <IconButton
            onClick={() => {
              changeText('')
              setExpanded(!expanded)
            }}
          >
            <MdOutlineClose className="text-zinc-300 " />
          </IconButton>
        </FlexRow>
      )}
    </FlexRow>
  )
}

export default Search

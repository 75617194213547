import { doc, getDoc } from 'firebase/firestore'
import { Company } from '@super-software-inc/foundation'
import { firestore } from '../../firebase/setup'

/**
 * @param companyId id of company
 * @returns top level company doc, no employees subcollection
 */
const findCompanyById = async (
  companyId?: string,
): Promise<Company | undefined> => {
  if (!companyId) {
    return undefined
  }

  const companyDoc = await getDoc(doc(firestore, `/companies`, companyId))

  if (!companyDoc.exists) {
    return undefined
  }
  return companyDoc.data() as Company
}

export default findCompanyById
